import React, { Component } from 'react';
import { MIB } from '../../../utils';
import {Link} from "react-router-dom";

class Header extends Component {

    logout() {
        sessionStorage.clear();
        this.props.router.navigate('/');
    }


    render() {
        //console.log(this.props)
        const { user } = this.props;
        const mib = process.env.REACT_APP_APP_PATH;
        const url = `/${mib}`;
        return (
            <header>
                <div className="topbar d-flex align-items-center">
                    <nav className="navbar navbar-expand">
                        <div className="mobile-toggle-menu"><i className="bx bx-menu" />
                        </div>
                        <div className="top-menu ms-auto">
                            {/*<ul className="navbar-nav align-items-center">
                                <li className="nav-item dropdown dropdown-large">
                                    <a className="nav-link dropdown-toggle dropdown-toggle-nocaret position-relative" role="button" data-bs-toggle="dropdown" aria-expanded="false"> <span className="alert-count">7</span>
                                        <i className="bx bx-bell" />
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-end">
                                        <a>
                                            <div className="msg-header">
                                                <p className="msg-header-title">Notifications</p>
                                                <p className="msg-header-clear ms-auto">Marks all as read</p>
                                            </div>
                                        </a>
                                        <div className="header-notifications-list">
                                            <a className="dropdown-item">
                                                <div className="d-flex align-items-center">
                                                    <div className="notify bg-light-primary text-primary"><i className="bx bx-group" />
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h6 className="msg-name">New Customers<span className="msg-time float-end">14 Sec
                                                            ago</span></h6>
                                                        <p className="msg-info">5 new user registered</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <a>
                                            <div className="text-center msg-footer">View All Notifications</div>
                                        </a>
                                    </div>
                                </li>
                                <li className="nav-item dropdown dropdown-large">
                                    <a className="nav-link dropdown-toggle dropdown-toggle-nocaret position-relative" role="button" data-bs-toggle="dropdown" aria-expanded="false"> <span className="alert-count">8</span>
                                        <i className="bx bx-comment" />
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-end">
                                        <a>
                                            <div className="msg-header">
                                                <p className="msg-header-title">Messages</p>
                                                <p className="msg-header-clear ms-auto">Marks all as read</p>
                                            </div>
                                        </a>
                                        <div className="header-message-list">
                                            <a className="dropdown-item">
                                                <div className="d-flex align-items-center">
                                                    <div className="user-online">
                                                        <img src="/assets/images/avatars/avatar-1.png" className="msg-avatar" alt="user avatar" />
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h6 className="msg-name">Daisy Anderson <span className="msg-time float-end">5 sec
                                                            ago</span></h6>
                                                        <p className="msg-info">The standard chunk of lorem</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <a>
                                            <div className="text-center msg-footer">View All Messages</div>
                                        </a>
                                    </div>
                                </li>
                            </ul>*/}
                        </div>
                        <div className="user-box dropdown">
                            <a className="d-flex align-items-center nav-link dropdown-toggle dropdown-toggle-nocaret" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <img src="/assets/images/user.png" className="user-img" alt={user.name} />
                                <div className="user-info ps-3">
                                    <p className="user-name mb-0">{user.name}</p>
                                    <p className="designattion mb-0">{user.roleName+' - '+user.userGroupName}</p>
                                </div>
                            </a>
                            <ul className="dropdown-menu dropdown-menu-end">
                                <li><Link className="dropdown-item" to={`${url}/users/change-password`}><i className="bx bx-user" /><span>Change Password</span></Link></li>
                                <li>
                                    <div className="dropdown-divider mb-0" />
                                </li>
                                <li><a className="dropdown-item" onClick={() => this.logout()}><i className="bx bx-log-out-circle" /><span>Logout</span></a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>
        );
    }
}

export default MIB.withRouter(Header);