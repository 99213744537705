import axios from "axios";
import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { MIB } from "../../utils";

class OTP extends Component {
    constructor(props) {
        super(props);

        this.state = {
            otp: "",
            confirm: "",
            password: "",
            loading: false,
            time: new Date()
        };
    }

    submitHandler = async (e) => {
        e.preventDefault();

        const { router } = this.props;
        const { post } = router.location.state;
        const { otp, password, confirm, time } = this.state;
        let error = true;
        let msg = "";

        if (!otp) msg = 'Silahkan lengkapi otp';
        else if (password.length < 8) msg = 'Password minimal 8 karakter';
        else if (password !== confirm) msg = 'Password dan konfirmasi tidak sama';
        else error = false;

        if (error) {
            Swal.fire('Error', msg, 'error');
        } else {
            this.setState(prevState => ({
                ...prevState,
                loading: true
            }));
            let data = {
                email: post.email,
                purpose: 'micrositeKKKS-1',
                otp: otp,
                newPassword: password
            }
            //console.log(data);
            const url = process.env.REACT_APP_API_URL + "/user/forgotPassword";
            const response = await axios.post(url, data);
            this.setState(prevState => ({
                ...prevState,
                loading: false
            }));
            //console.log(response.data)
            if (response.data.status) {
                Swal.fire({
                    icon: 'success',
                    title: 'Password Berhasil Terubah',
                    text: 'Silahkan login untuk cek password terbaru',
                    showConfirmButton: false,
                    timer: 1500
                });
                setTimeout(() => {
                    router.navigate("/");
                }, 1500)
            } else {
                Swal.fire('Error', response.data.message, 'error');
            }

        }
    }

    render() {
        const { router } = this.props;
        const { post } = router.location.state;
        //console.log('post:', post);
        //console.log('state:',this.state);
        const { otp, loading, password, confirm, time } = this.state;
        let tend = post.time.getTime();
        //console.log((tend-time.getTime())/1000);
        return (
            <div className="wrapper">
                <div className="section-authentication-signin d-flex align-items-center justify-content-center my-5 my-lg-0">
                    <div className="container-fluid">
                        <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
                            <div className="col mx-auto">
                                <div className="mb-4 text-center">
                                    <img
                                        src="/assets/images/logo-skk-migas.png"
                                        width={180}
                                        alt=""
                                    />
                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="border p-4 rounded">
                                            <div className="form-body">
                                                <Form className="row g-3" onSubmit={this.submitHandler}>
                                                    <div className="col-12">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="otp"
                                                            id="otp"
                                                            value={otp}
                                                            placeholder="Masukkan kode otp"
                                                            onChange={(e) => this.setState(prev => ({ ...prev, otp: e.target.value }))}
                                                        />
                                                    </div>
                                                    <div className="col-12">
                                                        <input
                                                            type="password"
                                                            className="form-control"
                                                            name="password"
                                                            id="password"
                                                            value={password}
                                                            placeholder="New Password"
                                                            onChange={(e) => this.setState(prev => ({ ...prev, password: e.target.value }))}
                                                        />
                                                    </div>
                                                    <div className="col-12">
                                                        <input
                                                            type="password"
                                                            className="form-control"
                                                            name="confirm"
                                                            id="confirm"
                                                            value={confirm}
                                                            placeholder="Confirmation"
                                                            onChange={(e) => this.setState(prev => ({ ...prev, confirm: e.target.value }))}
                                                        />
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="d-grid">
                                                            <button type="submit" className="btn btn-primary">
                                                                Ganti Kata Sandi
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 text-center">
                                                        <Link to="/forgot-password">
                                                            <i className="bx bx-arrow-back mr-5" />
                                                            Kembali
                                                        </Link>
                                                    </div>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {loading && (
                    <>
                        <div id="locker" className="transparent_class"></div>
                        <div id="lockwindow">
                            <div className="lds-facebook">
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    }
}

export default MIB.withRouter(OTP);
