import { useLocation, useNavigate, useParams, useOutletContext } from "react-router-dom";
import axios from "axios";
//import jwt from "jsonwebtoken";
//import md5 from "md5";

import { decodeToken, isExpired } from "react-jwt";
import ReactQuill from 'react-quill';
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

import userRole from "./userRole";

//import { useNavigate } from "react-router-dom";

const MIB = {
    createChildMenu: (data, url, no) => {
        let html = data.map((rs, i) => {
            if (rs.type.toLowerCase() === 'menu')
                return (
                    <li key={`${no}-${i}`}>
                        <Link to={url + rs.url}>{rs.icon === undefined || rs.icon === null ? <i className="bx bx-right-arrow-alt" /> : <div className="parent-icon"><i className={rs.icon} /></div>}
                            {rs.icon === undefined || rs.icon === null ? rs.title : <div className="menu-title">{rs.title}</div>}
                        </Link>
                    </li>
                );
            else if (rs.type.toLowerCase() === 'label')
                return <li key={`${no}-${i}`} className="menu-label">{rs.title}</li>;
            else
                return (
                    <li key={`${no}-${i}`}>
                        <a className="has-arrow">
                            {rs.icon === undefined || rs.icon === null ? <i className="bx bx-right-arrow-alt" /> : <div className="parent-icon"><i className={rs.icon} /></div>}
                            {rs.icon === undefined || rs.icon === null ? rs.title : <div className="menu-title">{rs.title}</div>}
                        </a>
                        <ul>{MIB.createChildMenu(rs.childs, url, i)}</ul>
                    </li>
                );
        });

        return html;
    },
    createMenu: (type, url) => {
        let menu;
        if (type.toLowerCase() === 'app')
            menu = userRole.APP;
        else if (type.toLowerCase() === 'edi')
            menu = userRole.EDI;
        else
            menu = userRole.ADM;

        return MIB.createChildMenu(menu, url, 0);
    },

    withRouter: (Component) => {
        function ComponentWithRouterProp(props) {
            let location = useLocation();
            let navigate = useNavigate();
            let params = useParams();
            let context = useOutletContext();
            //let effect = useEffect();
            return (
                <Component
                    {...props}
                    router={{ location, navigate, params, context }}
                />
            );
        }

        return ComponentWithRouterProp;
    },

    getUri: path => {
        return path.replace(/^\//, "").split('/');
    },

    sessionName: () => {
        return process.env.REACT_APP_APP_SESSION_NAME;
    },
    storageName: () => {
        return 'stogle';
    },
    getStorage: () => {
        const sname = MIB.storageName();
        const stogle = localStorage.getItem(sname);
        //console.log(stogle);
        return stogle === '1' ? true : false;
    },
    changeStorage: () => {
        let stogle = !MIB.getStorage();
        localStorage.setItem(MIB.storageName(), stogle ? '1' : '0');
    },
    getSession: () => {
        const sname = MIB.sessionName();
        const strJson = sessionStorage.getItem(sname);
        //console.log(strJson);
        let json = null;
        if (strJson !== "" && strJson !== null)
            json = JSON.parse(strJson);
        //console.log(json);
        return json;
    },
    login: async (username, password) => {
        const appKey = process.env.REACT_APP_APP_KEY;
        //const url = 'http://103.41.207.33:8083/auth/login';
        const url = process.env.REACT_APP_LOGIN_URL;
        const post = {
            userName: username,
            password: password
        };
        const headers = {
            'Content-Type': 'application/json',
            'App-Key': appKey
        }
        const data = await axios.post(url, post, { headers }).catch((err) => {
            console.log("error2:", err.message);
        });

        let valid = {
            status: data.data.status,
            message: data.data.message === null ? 'User belum terdaftar' : data.data.message,
            data: data.data.datas,
            authorization: data.headers.authorization
        }

        const sname = MIB.sessionName();
        if (valid.status) {
            sessionStorage.setItem(sname, JSON.stringify(valid));
        } else {
            sessionStorage.setItem(sname, '');
        }
        //console.log(valid);
        return valid;
        /*if(!valid.status && valid.message === null) valid.message = 'User belum terdaftar';
        else if(!valid)
        console.log(data.data);
        return false;*/
        /*let valid = data.data;*/
        /*let valid = {
            status: true,
            status_code: 200,
            message: "success",
            jwt_valid: "bd5a576882866d90b28f693c4272b562",
            jwt_token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoyLCJ1c2VyX25hbWUiOiJtaXNiYSIsIm5hbWEiOiJNaXNiYSIsImVtYWlsIjoibWlzYmFAZGF0YXNjcmlwLmNvLmlkIiwibm9fdGVscCI6IjA4NTY3MjQwNDMzIiwic3RhdHVzIjoiQSIsImNyZWF0ZWQiOm51bGwsImNyZWF0ZWRieSI6ImFkbWluIiwidXBkYXRlZCI6bnVsbCwidXBkYXRlZGJ5IjoiYWRtaW4iLCJpYXQiOjE2NjUzOTAzMzQsImV4cCI6MTY5NjkyNjMzNH0.T9IwMjWACpY_Wc442ppJWcoHfTDkbs2V_rQZVfwo9hE"
        };
        const sname = MIB.sessionName();
        if (valid.status) {
            sessionStorage.setItem(sname, JSON.stringify(valid));
        } else {
            sessionStorage.setItem(sname, '');
            return valid;
        }

        return MIB.getSession();*/
    },
    getHeader: () => {
        const valid = MIB.getSession();
        //console.log("OK:",valid);
        const appKey = process.env.REACT_APP_APP_KEY;

        axios.defaults.headers.common['Content-Type'] = 'application/json';
        axios.defaults.headers.common['App-Key'] = appKey;
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + valid.authorization;
        //axios.defaults.headers.common['Jwt-Valid'] = valid.jwt_valid;
        //axios.defaults.headers.common['Jwt-Token'] = valid.jwt_token;
        //console.log(axios.defaults.headers.common);
    },
    errorToken: (message) => {
        //const navigate = useNavigate();
        if(message.toLowerCase() === 'unauthorized'){
            sessionStorage.clear();
            //navigate('/');
        }
        toast.error(message);
        
    },
    extractToken: () => {
        //const md5 = require("md5");
        //const jwt = require("jsonwebtoken");
        const valid = MIB.getSession();
        //console.log(valid);
        let json = null;
        if (valid !== null) {
            json = {
                user_name: valid.data.username,
                name: valid.data.name
            }
        }
        return {
            isExpired: false,
            json: json
        };
        //const jwtKey = process.env.REACT_APP_JWT_KEY;

        //const token_key = md5(jwtKey+';'+valid.jwt_valid);
        //const data = jwt.verify(valid.jwt_token,token_key);
        const expired = isExpired(valid.jwt_token);
        let data = null;
        if (!expired)
            data = decodeToken(valid.jwt_token);
        //console.log(expired);
        //console.log(data);
        return {
            isExpired: expired,
            json: data
        }
    },
    loadJS: (url, component) => {
        const script = document.createElement("script");
        script.src = url;
        script.async = true;
        //script.onload = () => component.scriptLoaded();

        document.body.appendChild(script);
    },
    numberFormat: (number, n, x) => {
        var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
        return number.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,');
    },
    basename: (path) => {
        return path.replace(/.*\//, '');
    },
    dirname: (path) => {
        return path.match(/.*\//);
    },
    dateTimeFormat: (tgl) => {
        let date = new Date(tgl);
        return date.toLocaleString("id-ID");
    },
    dateFormat: (tgl) => {
        let date = new Date(tgl);
        //date = date.toLocaleString("id-ID").split(" ")[0];
        //console.log(date[0])
        return date.toLocaleString("id-ID").split(" ")[0];
    },

    quillToolbar: () => {
        return {
            modules: {
                toolbar: [
                    [{ 'font': [] }, { 'header': [1, 2, false] }, { 'align': [] }],
                    ['bold', 'italic', { 'color': [] }, 'underline', 'strike', 'blockquote'],
                    //[{'color':['green','blue','red','orange','violet','#d0d1d2']}]
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                    ['link', 'image'],
                    ['clean']
                ],
            },
            formats: [
                'font', 'header', 'align',
                'bold', 'italic', 'color', 'underline', 'strike', 'blockquote',
                //'color',
                'list', 'bullet', 'indent',
                'link', 'image'
            ]
        }
    },
    HtmlText: (state, name, handleChange) => {
        const toolbar = {
            modules: {
                toolbar: [
                    [{ 'font': [] }, { 'header': [1, 2, false] }, { 'align': [] }],
                    ['bold', 'italic', { 'color': [] }, 'underline', 'strike', 'blockquote'],
                    //[{'color':['green','blue','red','orange','violet','#d0d1d2']}]
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                    ['link', 'image'],
                    ['clean']
                ],
            },
            formats: [
                'font', 'header', 'align',
                'bold', 'italic', 'color', 'underline', 'strike', 'blockquote',
                //'color',
                'list', 'bullet', 'indent',
                'link', 'image'
            ]
        };

        let value = state.post[name];
        console.log(value);
        return <ReactQuill
            theme="snow"
            modules={toolbar.modules}
            formats={toolbar.formats} value={value}
            onChange={handleChange}>
            <div style={{ height: '150px' }}></div>
        </ReactQuill>
    },




    printImage: (imagePath, name, id) => {
        if (imagePath === '' || imagePath === undefined) return '';
        else
            return (
                <div>
                    <br /><img src={imagePath} height={100} alt="" /><br />
                    <label forHtml={name}><input type="checkbox" name={name} id={id} value="1" />Delete</label>
                </div>
            )
    }
}

export default MIB;