/*-----------------------------------------------------------------
GENERATED BY MIB_PHP_GENERATOR VERSION 3.0
CREATED BY MISBA
EMAIL: m.m158a@gmail.com

DATE: 20-10-2022
TIME: 10:01:04

@copyright 2020 by MISBA
------------------------------------------------------------------*/




import React, { Component } from 'react';
import { Form, Tooltip, OverlayTrigger } from "react-bootstrap";
import { ServiceAchievementsData } from "./";
import { MIB } from "../../utils";
//import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ServiceAchievements } from "../achievements";

class InputAchievementsData extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: '',
			post: {
				id_achievement: '',
				tanggal: '',
				total: '',
				unit: '',
				created: '',
				createdby: '',
				updated: '',
				updatedby: ''
			},
			dt_achievements: []

		}

		this.setValue = this.setValue.bind(this);
		ServiceAchievements.setLock = this.props.router.context.setLock;
		ServiceAchievementsData.setLock = this.props.router.context.setLock;
	}

	setValue(e) {
		if(e.target.name !== 'id_achievement'){
			this.setState((prevState) => ({
				...prevState,
				post: {
					...prevState.post,
					[e.target.name]: e.target.value
				}
			}));
		}else{
			let unit = document.querySelector("#id_achievement option[value='"+e.target.value+"']").getAttribute('unit');
			//console.log(unit);
			this.setState((prevState) => ({
				...prevState,
				post: {
					...prevState.post,
					unit: unit,
					[e.target.name]: e.target.value
				}
			}))
		}
	}

	submitHandler = async (e) => {
		e.preventDefault();
		const { router, url } = this.props;
		let data;
		let msg = '';
		let post = this.state.post;
		let jwt = MIB.extractToken();
		let user_name = jwt.json.user_name;
		post.createdby = user_name;
		post.updatedby = user_name;
		post.created = undefined;
		post.updated = undefined;
		post.tanggal = post.tanggal.replace('T', ' ') + ':00';
		if (router.params.id) {
			data = await ServiceAchievementsData.update(post, router.params.id);
			msg = 'Data berhasil diupdate';
		}
		else {
			data = await ServiceAchievementsData.new(post);
			msg = 'Data berhasil ditambah';
		}
		let rs = data.data;
		if (rs.status) {
			//Swal.fire('Success', rs.message, 'success');
			//toast.success(rs.message);
			let state = { toast: { type: 'success', message: msg } }
			router.navigate(url, { state });
		} else {
			//Swal.fire('Error', rs.message, 'error');
			//toast.error(rs.message);
			MIB.errorToken(rs.message);
		}
	}

	async getAchievements() {
		const qstring = "page=0&limit=10000&field_sort=id&sort_by=0&keyword=";
		const respAchievements = await ServiceAchievements.get(qstring);
		if (respAchievements.data.status) {
			this.setState((prevState) => ({
				...prevState,
				dt_achievements: respAchievements.data.datas.content
			}));
		}else{
			MIB.errorToken(respAchievements.data.message);
		}
	}

	async componentDidMount() {
		const { params, navigate } = this.props.router;
		await this.getAchievements();
		if (params.id) {
			const resp = await ServiceAchievementsData.detail(params.id);
			if (resp !== undefined) {
				if (resp.data.status && resp.data.datas !== undefined) {
					const data = resp.data.datas;//{DATE_TIME_CONVERT}
					this.setState((prevState) => ({
						...prevState,
						id: data.id,
						post: data
					}));
				} else {
					MIB.errorToken(resp.data.message);
					navigate(this.props.url);
				}
			} else {
				console.log(resp.data.message);
				console.log(this.props.url);
			}
		} else {
			let id_achievement = '';
			let unit = '';
			if (this.state.dt_achievements.length > 0) {
				id_achievement = this.state.dt_achievements[0].id_achievement;
				unit = this.state.dt_achievements[0].unit;
			}
			this.setState((prevState) => ({
				...prevState,
				post: {
					...prevState.post,
					id_achievement: id_achievement,
					unit: unit
				}
			}));
		}
	}

	render() {
		const { router, url } = this.props;
		const { post, dt_achievements } = this.state;
		return (
			<Form onSubmit={this.submitHandler}>
				<div className="row">
					<div className="col-sm-12">
						<div className="card">
							<div className="card-body">
								<div className="p-4">
									<div className="card-title d-flex align-items-center">
										<div><i className="bx bxs-info-square me-1 font-22 text-info"></i></div>
										<h5 className="mb-0 text-info">{router.context.title}</h5>
									</div>
									<hr />

									<div className="row">
										<div className="col-sm-6">
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="id_achievement">Category:</label>
												<div className="col-sm-9"><select name="id_achievement" id="id_achievement" className="form-select" onChange={(event) => this.setValue(event)} value={post.id_achievement}>
													{dt_achievements.map(rs => (
														<option key={rs.id} value={rs.id} unit={rs.unit}>{rs.name}</option>
													))}
												</select></div>
											</div>
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="tanggal">Tanggal:</label>
												<div className="col-sm-9"><input type="date" name="tanggal" id="tanggal" value={post.tanggal.substring(0, 10)} className="form-control" onChange={(event) => this.setValue(event)} /></div>
											</div>
										</div>
										<div className="col-sm-6">
											<div className="row">
												<div className="col-sm-8">
													<div className="row mb-3">
														<label className="col-sm-3 col-form-label" htmlFor="total">Total:</label>
														<div className="col-sm-9"><input type="number" name="total" id="total" value={post.total} className="form-control text-right" onChange={(event) => this.setValue(event)} /></div>
													</div>
												</div>
												<div className="col-sm-4">{post.unit}</div>
											</div>

										</div>
									</div>




								</div>

							</div>
						</div>
					</div>
				</div>


				<button type="submit" className="btn btn-success radius-30 mt-2 mt-lg-0" name="btnSave" id="btnSave">
					<i className="bx bx-save" />
					Save
				</button>
				<button className="btn btn-info radius-30 mt-2 mt-lg-0 ms-1" type="button" onClick={() => router.navigate(url)}>
					<i className="bx bx-chevron-left" />
					Back
				</button>
				{this.state.id !== '' && post.created !== undefined ? <OverlayTrigger
					key="top"
					placement="top"
					overlay={
						<Tooltip id={`tooltip-top`}>
							<table>
								<tbody>
									<tr>
										<td nowrap="nowrap">Created</td>
										<td>:</td>
										<td nowrap="nowrap">{MIB.dateTimeFormat(post.created)}</td>
									</tr>
									<tr>
										<td nowrap="nowrap">Created by</td>
										<td>:</td>
										<td nowrap="nowrap">{post.createdby}</td>
									</tr>
									<tr>
										<td nowrap="nowrap">Updated</td>
										<td>:</td>
										<td nowrap="nowrap">{MIB.dateTimeFormat(post.updated)}</td>
									</tr>
									<tr>
										<td nowrap="nowrap">Updated by</td>
										<td>:</td>
										<td nowrap="nowrap">{post.updatedby}</td>
									</tr>
								</tbody>

							</table>
						</Tooltip>
					}
				>
					<button type="button" className="btn btn-warning radius-30 mt-2 mt-lg-0 ms-1"><i className="bx bx-history" /> History</button>
				</OverlayTrigger> : ''}

				<ToastContainer
					position="top-right"
					theme="colored"
					autoClose={2000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
			</Form>
		);
	}
}

export default MIB.withRouter(InputAchievementsData);