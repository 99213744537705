/*-----------------------------------------------------------------
GENERATED BY MIB_PHP_GENERATOR VERSION 3.0
CREATED BY MISBA
EMAIL: m.m158a@gmail.com

DATE: 26-08-2022
TIME: 11:00:48

@copyright 2020 by MISBA
------------------------------------------------------------------*/


const VARS = {
	jenis_file_file_type: [{key: 'pdf',label: 'PDF'},{key: 'image',label: 'Image'},{key: 'excel',label: 'Excel'}],
	get_jenis_file_file_type: function(key){
		let result = this.jenis_file_file_type.filter(obj=>{
			if(obj.key === key) return obj;
		});
		if(result.length > 0) result = result[0].label;
		else result = "";
		return result;
	},
	jenis_file_view_type: [{key: 'thumbnail',label: 'Thumbnail'},{key: 'list',label: 'List'}],
	get_jenis_file_view_type: function(key){
		let result = this.jenis_file_view_type.filter(obj=>{
			if(obj.key === key) return obj;
		});
		if(result.length > 0) result = result[0].label;
		else result = "";
		return result;
	},
	jenis_file_ebook: [{key: '1',label: 'Ya'},{key: '0',label: 'Tidak'}],
	get_jenis_file_ebook: function(key){
		let result = this.jenis_file_ebook.filter(obj=>{
			if(obj.key === key) return obj;
		});
		if(result.length > 0) result = result[0].label;
		else result = "";
		return result;
	},
	jenis_file_download: [{key: '1',label: 'Download'},{key: '0',label: 'Tidak'}],
	get_jenis_file_download: function(key){
		let result = this.jenis_file_download.filter(obj=>{
			if(obj.key === key) return obj;
		});
		if(result.length > 0) result = result[0].label;
		else result = "";
		return result;
	},
	profile_jenis: [{key: 'komwas',label: 'Komisioner'},{key: 'manajemen',label: 'Manajemen'}],
	get_profile_jenis: function(key){
		let result = this.profile_jenis.filter(obj=>{
			if(obj.key === key) return obj;
		});
		if(result.length > 0) result = result[0].label;
		else result = "";
		return result;
	},
	video_type: [{key: '1',label: 'Primer'},{key: '2',label: 'Sekunder'},{key: '3',label: 'Tertier'},{key: '4',label: 'Quard'}],
	get_video_type: function(key){
		let result = this.video_type.filter(obj=>{
			if(obj.key === key) return obj;
		});
		if(result.length > 0) result = result[0].label;
		else result = "";
		return result;
	},
	menus_type: [{key: 1,label: 'Header Menu'},{key: 2,label: 'Footer Menu'},{key: 3,label: 'Banner Menu'}],
	get_menus_type: function(key){
		let result = this.menus_type.filter(obj=>{
			if(obj.key === key) return obj;
		});
		if(result.length > 0) result = result[0].label;
		else result = "";
		return result;
	},
	users_roleCode: [{key: 'ADM',label: 'Administrator'},{key: 'EDT',label: 'Editor'},{key: 'SA',label: 'Super Administrator'}],
	get_users_roleCode: function(key){
		let result = this.users_roleCode.filter(obj=>{
			if(obj.key === key) return obj;
		});
		if(result.length > 0) result = result[0].label;
		else result = "";
		return result;
	},
	users_segmentCode: [{key: 'mainSite',label: 'Main Site'},{key: 'SKKK',label: 'SKKK'},{key: 'IOGI',label: 'IOGI'}],
	get_users_segmentCode: function(key){
		let result = this.users_segmentCode.filter(obj=>{
			if(obj.key === key) return obj;
		});
		if(result.length > 0) result = result[0].label;
		else result = "";
		return result;
	},

	MENUS: [
		{
			
		}
	]
}

export default VARS;