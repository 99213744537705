import React, { Component } from 'react';
import { Form } from "react-bootstrap";
import { MIB, Paging } from '../../utils';
import { Link } from 'react-router-dom'
import { ServiceNews } from '../news';

class Home extends Component {
    defaultState = {
        data: [],
        pagination: null,
        post: {
            keyword: '',
            page: 0,
            sort_by: '0',
            field_sort: 'id',
            limit: 10,
            /*category_id: '',
            judul: '',
            judul_en: '',
            tanggal: '',
            kota: '',
            penulis: '',
            summary: '',
            summary_en: '',
            content: '',
            content_en: ''*/
        },
        //dt_categories: [],
        //switchFilter: false,
        //ckAll: false,
        //ck: []
    }

    constructor(props) {
        super(props);
        this.state = this.defaultState
    }

    async loadData() {
        const queryString = new URLSearchParams(this.state.post).toString();
        const resp = await ServiceNews.getPending(queryString);
        if (resp !== undefined) {
            if (resp.data.status) {
                //console.log(resp.data.datas);
                const data = resp.data.datas.content;
                const pagination = {
                    num_pages: resp.data.datas.totalPages,
                    page: resp.data.datas.pageable.pageNumber,
                    limit: resp.data.datas.pageable.pageSize,
                    num_rows: resp.data.datas.totalElements
                };

                /*let ck = [];
                for (let i = 0; i < data.length; i++) {
                    ck[i] = false;
                }*/

                this.setState((prevState) => ({
                    ...prevState,
                    data: data,
                    pagination: pagination,
                    //ck: ck
                }));
            }
        } else {
            console.log(resp.data.message);
        }
    }

    async setPage(e, page) {
        e.preventDefault();
        await this.setState((prevState) => ({
            ...prevState,
            post: {
                ...prevState.post,
                page: page
            }
        }));
        await this.loadData();
    }

    async componentDidMount() {

        await this.loadData();
    }

    render() {
        const [mib, action, id] = MIB.getUri(this.props.router.location.pathname);
        let title = "";
        if (action === undefined || action === "") title = "Pending news";
        else if (id === undefined || id === "") title = "New data";
        else title = "Update data";
        const modul = {
            name: 'Dashboard',
            url: `/${mib}`,
            title: title
        };
        document.title = process.env.REACT_APP_APP_NAME + ' - ' + modul.name + ' - ' + modul.title;

        const { router } = this.props;
        //console.log(router);
        //const url = router.context.url;
        //const { data, pagination, post, dt_categories, switchFilter, ck } = this.state;
        const { data, post, pagination } = this.state;
        return (
            <div className="page-wrapper">
                <div className="page-content">
                    <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                        <div className="breadcrumb-title pe-3">{modul.name}</div>
                        <div className="ps-3">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item"><Link to={modul.url}><i className="bx bx-category" /></Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">{modul.title}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>


                    <Form onSubmit={(e) => e.preventDefault()}>
                        <input type="hidden" name="page" id="page" value={post.page} onChange={(event) => this.setValue(event)} />
                        <input type="hidden" name="sort_by" id="sort_by" value={post.sort_by} onChange={(event) => this.setValue(event)} />
                        <input type="hidden" name="field_sort" id="field_sort" value={post.field_sort} onChange={(event) => this.setValue(event)} />
                        <input type="hidden" name="keyword" id="keyword" value={post.keyword} onChange={(event) => this.setValue(event)} />

                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card m-b-30">
                                    <div className="card-body">



                                        <div className="table-responsive">
                                            <table className="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>Company</th>
                                                        <th>Category</th>
                                                        <th>Judul (ID)</th>
                                                        <th>Judul (EN) </th>
                                                        <th>Tanggal </th>
                                                        <th>Kota</th>
                                                        <th>Penulis </th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data.map((rs, no) => {

                                                        return (
                                                            <tr key={rs.news_id}>
                                                                <td>{rs.company}</td>
                                                                <td>{rs.category}</td>
                                                                <td>{rs.judul}</td>
                                                                <td>{rs.judul_en}</td>
                                                                <td>{MIB.dateTimeFormat(rs.tanggal)}</td>
                                                                <td>{rs.kota}</td>
                                                                <td>{rs.penulis}</td>
                                                                <td><Link to={`${modul.url}/news/approve/${rs.news_id}`} className="btn-success btn-sm radius-30 mt-2 mt-lg-0"><i className="bx bx-check" /></Link></td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>

                                        </div>

                                        <Paging pagination={pagination} setPage={this.setPage} />








                                    </div>
                                </div>
                            </div>
                        </div>







                    </Form>

                </div>
            </div>
        );
    }
}

export default Home;