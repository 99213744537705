/*-----------------------------------------------------------------
GENERATED BY MIB_PHP_GENERATOR VERSION 3.0
CREATED BY MISBA
EMAIL: m.m158a@gmail.com

DATE: 26-10-2022
TIME: 13:12:04

@copyright 2020 by MISBA
------------------------------------------------------------------*/




import axios from "axios";
import { MIB } from "../../utils";

const ServiceUsers = {
    setLock: null,
    getHeader: function () {
        const valid = MIB.getSession();
        const appKey = process.env.REACT_APP_APP_KEY;

        axios.defaults.headers.common['Content-Type'] = 'application/json';
        axios.defaults.headers.common['App-Key'] = appKey;
        axios.defaults.headers.common['Jwt-Valid'] = valid.jwt_valid;
        axios.defaults.headers.common['Jwt-Token'] = valid.jwt_token;
    },
    get: async function (queryString) {
        MIB.getHeader();
        const add = queryString === undefined || queryString === '' ? '' : '?' + queryString;
        const url = process.env.REACT_APP_API_URL + "/user/getPageable" + add;
        this.setLock(true);
        const data = await axios.get(url).catch((err) => {
            console.log("error2:", err.message);
        });
        this.setLock(false);
        return data;
    },
    getRole: async function () {
        MIB.getHeader();
        const url = process.env.REACT_APP_API_URL + "/role/getPageable?page=0&limit=10&field_sort=id&sort_by=0&keyword=";
        this.setLock(true);
        const data = await axios.get(url).catch((err) => {
            console.log("error2:", err.message);
        });
        this.setLock(false);
        return data;
    },
    getGroup: async function () {
        MIB.getHeader();
        const url = process.env.REACT_APP_API_URL + "/user_group/getPageable?page=0&limit=10&field_sort=id&sort_by=0&keyword=";
        this.setLock(true);
        const data = await axios.get(url).catch((err) => {
            console.log("error2:", err.message);
        });
        this.setLock(false);
        return data;
    },
    new: async function (post) {
        MIB.getHeader();
        const url = process.env.REACT_APP_API_URL + "/user/register";
        this.setLock(true);
        const data = await axios.post(url, post).catch((err) => {
            console.log("error2:", err.message);
        });
        this.setLock(false);
        return data;
    },
    update: async function (post, id) {
        MIB.getHeader();
        const url = process.env.REACT_APP_API_URL + "/user/updateDataUser";
        post.id = id;
        this.setLock(true);
        const data = await axios.post(url, post).catch((err) => {
            console.log("error2:", err.message);
        });
        this.setLock(false);
        return data;

    },
    changePassword: async function (post) {
        MIB.getHeader();
        const url = process.env.REACT_APP_API_URL + "/user/changePassword";
        //const url = "http://172.16.253.11:8083/user/changePassword";
        this.setLock(true);
        const data = await axios.post(url, post).catch((err) => {
            console.log("error2:", err.message);
        });
        this.setLock(false);
        return data;

    },
    disabledEnable: async function (id,isActive) {
        MIB.getHeader();
        const url = process.env.REACT_APP_API_URL + "/user/suspendUser";
        let post = {
            id: id,
            isActive: !isActive
        };
        this.setLock(true);
        const data = await axios.post(url, post).catch((err) => {
            console.log("error2:", err.message);
        });
        this.setLock(false);
        return data;

    },
    detail: async function (id) {
        MIB.getHeader();
        const url = process.env.REACT_APP_API_URL + "/user/getProfile?id=" + id;
        this.setLock(true);
        const data = await axios.get(url).catch((err) => {
            console.log("error2:", err.message);
        });
        this.setLock(false);
        return data;
    },
    delete: async function (id) {
        MIB.getHeader();
        const url = process.env.REACT_APP_API_URL + "/user/deleteUser";
        let post = {
            id
        }
        this.setLock(true);
        const data = await axios.post(url,post).catch((err) => {
            console.log("error2:", err.message);
        });
        return data;
    },
    deleteMultiple: async function (dels) {
        MIB.getHeader();
        const url = process.env.REACT_APP_API_URL + "/users";
        const data = await axios.delete(url, dels).catch((err) => {
            console.log("error2:", err.message);
        });
        return data;
    }
}

export default ServiceUsers;