import React, { Component, Fragment } from 'react';
import Routers from './Routers';
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";

class App extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);
    const { cookies } = props;
    if(cookies.get('ctoggled') === undefined){
      cookies.set('ctoggled','1');
    }
  }
  render() {
    const { cookies } = this.props;
    //const ctoggled = cookies.get('ctoggled');
    return (
      <Fragment>
        <Routers cookies={cookies} />
      </Fragment>
    );
  }
}

export default withCookies(App);
