/*-----------------------------------------------------------------
GENERATED BY MIB_PHP_GENERATOR VERSION 3.0
CREATED BY MISBA
EMAIL: m.m158a@gmail.com

DATE: 26-08-2022
TIME: 11:00:48

@copyright 2020 by MISBA
------------------------------------------------------------------*/




import React, { Component } from 'react';
//import { Link } from "react-router-dom";
import { MIB } from '../../../utils';

class Sidebar extends Component {

    render() {
        const { sidebarKlik, user } = this.props;
        //console.log("ctoggled:", cookies.get('ctoggled'));
        //console.log(user);
        const mib = process.env.REACT_APP_APP_PATH;
        const url = `/${mib}`;
        return (
            <div className="sidebar-wrapper" data-simplebar="true">
                <div className="sidebar-header">
                    <div>
                        <img src="/assets/images/logo-skk-migas.png" className="logo-icon" alt="logo icon" />
                    </div>
                    <div>
                        <h4 className="logo-text">SKK Migas</h4>
                    </div>
                    <div className="toggle-icon ms-auto"><i className="bx bx-arrow-to-left" onClick={() => sidebarKlik()} />
                    </div>
                </div>
                {/*navigation*/}
                <ul className="metismenu" id="menu">{MIB.createMenu(user.role,url)}
                    {/*<li>
                        <Link to={url}>
                            <div className="parent-icon"><i className="bx bx-home-circle" /></div>
                            <div className="menu-title">Dashboard</div>
                        </Link>
                    </li>
                    <li className="menu-label">Transaksi</li>
                    <li>
                        <a className="has-arrow"><div className="parent-icon"><i className="bx bx-menu" /></div><div className="menu-title">Menu</div></a>
                        <ul>
                            <li><Link to={`${url}/menus/new`}><i className="bx bx-right-arrow-alt" />New Data</Link></li>
                            <li><Link to={`${url}/menus`}><i className="bx bx-right-arrow-alt" />Data</Link></li>
                        </ul>
                    </li>
                    <li>
                        <a className="has-arrow"><div className="parent-icon"><i className="bx bx-news" /></div><div className="menu-title">News</div></a>
                        <ul>
                            <li><Link to={`${url}/news/new`}><i className="bx bx-right-arrow-alt" />New Data</Link></li>
                            <li><Link to={`${url}/news`}><i className="bx bx-right-arrow-alt" />Data</Link></li>
                            <li><Link to={`${url}/categories`}><i className="bx bx-right-arrow-alt" />Categories</Link></li>
                        </ul>
                    </li>
                    <li>
                        <a className="has-arrow"><div className="parent-icon"><i className="lni lni-wordpress" /></div><div className="menu-title">Press Releas</div></a>
                        <ul>
                            <li><Link to={`${url}/press_release/new`}><i className="bx bx-right-arrow-alt" />New Data</Link></li>
                            <li><Link to={`${url}/press_release`}><i className="bx bx-right-arrow-alt" />Data</Link></li>
                        </ul>
                    </li>
                    <li>
                        <a className="has-arrow"><div className="parent-icon"><i className="bx bx-comment" /></div><div className="menu-title">Pages</div></a>
                        <ul>
                            <li><Link to={`${url}/pages/new`}><i className="bx bx-right-arrow-alt" />New Data</Link></li>
                            <li><Link to={`${url}/pages`}><i className="bx bx-right-arrow-alt" />Data</Link></li>
                        </ul>
                    </li>
                    <li>
                        <a className="has-arrow"><div className="parent-icon"><i className="bx bx-file-blank" /></div><div className="menu-title">Publikasi</div></a>
                        <ul>
                            <li><Link to={`${url}/jenis_file`}><i className="bx bx-right-arrow-alt" />Jenis Publikasi</Link></li>
                            <li><Link to={`${url}/file_upload/new`}><i className="bx bx-right-arrow-alt" />New Data</Link></li>
                            <li><Link to={`${url}/file_upload`}><i className="bx bx-right-arrow-alt" />Data</Link></li>
                        </ul>
                    </li>
                    <li>
                        <a className="has-arrow"><div className="parent-icon"><i className="bx bx-video" /></div><div className="menu-title">Video</div></a>
                        <ul>
                            <li><Link to={`${url}/video/new`}><i className="bx bx-right-arrow-alt" />New Data</Link></li>
                            <li><Link to={`${url}/video`}><i className="bx bx-right-arrow-alt" />Data</Link></li>
                        </ul>
                    </li>
                    <li>
                        <a className="has-arrow"><div className="parent-icon"><i className="bx bx-image" /></div><div className="menu-title">Banners</div></a>
                        <ul>
                            <li><Link to={`${url}/banners/new`}><i className="bx bx-right-arrow-alt" />New Data</Link></li>
                            <li><Link to={`${url}/banners`}><i className="bx bx-right-arrow-alt" />Data</Link></li>
                        </ul>
                    </li>
                    <li>
                        <a className="has-arrow"><div className="parent-icon"><i className="bx bx-repost" /></div><div className="menu-title">Achievements</div></a>
                        <ul>
                            <li><Link to={`${url}/achievements_data`}><i className="bx bx-right-arrow-alt" />Data</Link></li>
                            <li><Link to={`${url}/units`}><i className="bx bx-right-arrow-alt" />Units</Link></li>
                            <li><Link to={`${url}/achievements`}><i className="bx bx-right-arrow-alt" />Categories</Link></li>
                        </ul>
                    </li>
                    <li>
                        <a className="has-arrow"><div className="parent-icon"><i className="bx bx-user" /></div><div className="menu-title">Profile Management</div></a>
                        <ul>
                            <li><Link to={`${url}/profile/new`}><i className="bx bx-right-arrow-alt" />New Data</Link></li>
                            <li><Link to={`${url}/profile`}><i className="bx bx-right-arrow-alt" />Data</Link></li>
                        </ul>
                    </li>
                    <li>
                        <a className="has-arrow"><div className="parent-icon"><i className="lni lni-facebook-original" /></div><div className="menu-title">Sosial Media</div></a>
                        <ul>
                            <li><Link to={`${url}/sosmed/new`}><i className="bx bx-right-arrow-alt" />New Data</Link></li>
                            <li><Link to={`${url}/sosmed`}><i className="bx bx-right-arrow-alt" />Data</Link></li>
                        </ul>
                    </li>
                    <li>
                        <a className="has-arrow"><div className="parent-icon"><i className="bx bx bx-smile" /></div><div className="menu-title">Subscribes</div></a>
                        <ul>
                            <li><Link to={`${url}/subscriptions_messages/new`}><i className="bx bx-right-arrow-alt" />Send email</Link></li>
                            <li><Link to={`${url}/subscriptions_messages`}><i className="bx bx-right-arrow-alt" />Send email data</Link></li>
                            <li><Link to={`${url}/subscriptions`}><i className="bx bx-right-arrow-alt" />Email subscribes</Link></li>
                        </ul>
                    </li>
                    <li className="menu-label">Master Data</li>
                    <li>
                        <a className="has-arrow">
                            <div className="parent-icon"><i className="bx bx-category" />
                            </div>
                            <div className="menu-title">Master</div>
                        </a>
                        <ul>
                            <li><Link to={`${url}/users`}><i className="bx bx-right-arrow-alt" />Users</Link></li>
                            <li><Link to={`${url}/offices`}><i className="bx bx-right-arrow-alt" />Offices</Link></li>
                            <li><Link to={`${url}/area_map/new`}><i className="bx bx-right-arrow-alt" />Map</Link></li>
                            <li><Link to={`${url}/common_parameters`}><i className="bx bx-right-arrow-alt" />Params Config</Link></li>
                            <li>
                                <a className="has-arrow">
                                    <i className="bx bx-right-arrow-alt" /> KKKS
                                </a>
                                <ul>
                                    <li><Link to={`${url}/kkks_categories`}><i className="bx bx-right-arrow-alt" />Categories</Link></li>
                                    <li><Link to={`${url}/kkks_companies`}><i className="bx bx-right-arrow-alt" />Companies</Link></li>
                                </ul>
                            </li>
                        </ul>
                    </li>*/}
                </ul>
                {/*end navigation*/}
            </div>
        );
    }
}

export default Sidebar;


