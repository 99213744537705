/*-----------------------------------------------------------------
GENERATED BY MIB_PHP_GENERATOR VERSION 3.0
CREATED BY MISBA
EMAIL: m.m158a@gmail.com

DATE: 10-11-2022
TIME: 10:03:38

@copyright 2020 by MISBA
------------------------------------------------------------------*/




import React, { Component } from 'react';
import { MIB, UploadFile } from "../../utils";

class PressReleaseGallery extends Component {
	constructor(props) {
		super(props);
	}

	async componentDidMount() {
	}

	render() {
		const { rows, setDetailValue, newPressReleaseGallery, delPressReleaseGallery } = this.props;
		const url_upload = process.env.REACT_APP_UPLOAD_URL;
		return (
			<div className="row">
				<div className="col-sm-12">
					<div className="card">
						<div className="card-body">
							<div className="p-4">
								<div className="card-title d-flex align-items-center">
									<div><i className="bx bxs-info-square me-1 font-22 text-info" /></div>
									<h5 className="mb-0 text-info">Gallery</h5>
								</div>
								<hr />

								<button type="button" onClick={() => newPressReleaseGallery()} className="btn btn-primary btn-sm radius-30 mt-2 mt-lg-0"><i className="bx bx-plus" /> Add new</button><br />

								<div className="table-responsive">
									<table className="table table-hover">
										<thead>
											<tr>
												<th>#</th>
												<th>Image</th>
												<th>Keterangan</th>
											</tr>
										</thead>
										<tbody id="tbody-press_release_gallery">
											{rows.map((rs, i) => (
												<tr key={i}>
													<td><button type="button" onClick={() => delPressReleaseGallery(i)} className="btn-sm btn-danger radius-30 mt-2 mt-lg-0"><i className="bx bx-trash" /></button>
														<input type="hidden" name={`pk_press_release_gallery[${i}]`} id={`pk_press_release_gallery_${i}`} value={rs.id} /></td>
													<td>
														<UploadFile
															name={`press_release_gallery[${i}][image]`}
															accept="image"
															saveTo="press_release_gallery/image"
															url={url_upload}
															changeState={setDetailValue}
															value={rs.image}
															size="1" />

														{/*<input type="text" name={`press_release_gallery[${i}][image]`} id={`press_release_gallery-${i}-image`} value={rs.image} className="form-control" onChange={(event) => setDetailValue(event)} />*/}
													</td>
													<td><input type="text" name={`press_release_gallery[${i}][filename]`} id={`press_release_gallery-${i}-filename`} value={rs.filename} className="form-control" onChange={(event) => setDetailValue(event)} /></td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default MIB.withRouter(PressReleaseGallery);