/*-----------------------------------------------------------------
GENERATED BY MIB_PHP_GENERATOR VERSION 3.0
CREATED BY MISBA
EMAIL: m.m158a@gmail.com

DATE: 26-08-2022
TIME: 11:00:48

@copyright 2020 by MISBA
------------------------------------------------------------------*/




import React, { Component } from 'react';
import { Form, Tooltip, OverlayTrigger } from "react-bootstrap";
import { ServiceFileUpload } from "./";
import { MIB, UploadFile } from "../../utils";
//import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ServiceJenisFile } from "../jenis_file";

class InputFileUpload extends Component {
	constructor(props) {
		super(props);
		this.state = {
			file_upload_id: '',
			post: {
				jenis_file_id: '',
				file_type: '',
				ebook: false,
				tanggal_ipt: false,
				no_ipt: false,
				no: '',
				url_ebook: '',
				judul: '',
				judul_en: '',
				img_photo: '',
				file_name: '',
				keterangan: '',
				keterangan_en: '',
				date: '',
				created: '',
				createdby: '',
				updated: '',
				updatedby: ''
			},
			dt_jenis_file: []

		}

		ServiceFileUpload.setLock = this.props.router.context.setLock;
		ServiceJenisFile.setLock = this.props.router.context.setLock;
		//console.log('ServiceFileUpload.setLock:',ServiceFileUpload.setLock);
		this.setValue = this.setValue.bind(this);
	}

	setValue(e) {
		let file_type = this.state.post.file_type;
		let ebook = this.state.post.ebook;
		let no_ipt = this.state.post.no_ipt;
		let tanggal_ipt = this.state.post.tanggal_ipt;
		let url_ebook = this.state.post.url_ebook;
		let no = this.state.post.no;
		let tanggal = this.state.post.tanggal;
		if (e.target.name === 'jenis_file_id') {
			const el = document.querySelector("#jenis_file_id option[value='" + e.target.value + "']");
			file_type = el.getAttribute('file_type');
			let eb = el.getAttribute('ebook');
			let no = el.getAttribute('no');
			let tanggal = el.getAttribute('tanggal');
			ebook = eb === '1' ? true : false;
			no_ipt = no === '1' ? true : false;
			tanggal_ipt = tanggal === '1' ? true : false;
			if (!ebook) url_ebook = '';
			if (!no_ipt) no = '';
			if (!tanggal_ipt) tanggal = '';
			//console.log(file_type);
		}
		this.setState((prevState) => ({
			...prevState,
			post: {
				...prevState.post,
				file_type: file_type,
				ebook: ebook,
				no_ipt: no_ipt,
				tanggal_ipt: tanggal_ipt,
				url_ebook: url_ebook,
				date: tanggal,
				no: no,
				[e.target.name]: e.target.value
			}
		}))
	}

	submitHandler = async (e) => {
		e.preventDefault();
		const { router, url } = this.props;
		let data;
		let msg = '';
		let post = this.state.post;
		post.created = undefined;
		post.updated = undefined;
		post.name = undefined;
		post.file_type = undefined;
		post.ebook = undefined;
		post.no_ipt = undefined;
		post.tanggal_ipt = undefined;

		let jwt = MIB.extractToken();
		let user_name = jwt.json.user_name;
		post.createdby = user_name;
		post.updatedby = user_name;

		let error = true;
		let err_msg = '';
		if (post.tanggal_ipt && post.date === '') err_msg = 'Tanggal harus diisi';
		else if (post.judul === '') err_msg = 'Judul bahasa indonesia harus diisi';
		else if (post.judul_en === '') err_msg = 'Judul bahasa inggris harus diisi';
		else if (post.img_photo === '') err_msg = 'Masukan foto yang akan diupload';
		else if (post.file_name === '') err_msg = 'Masukan file yang akan diupload';
		else if (post.ebook && post.url_ebook) err_msg = 'Url E-Book harus diisi';
		else if (post.no_ipt && post.no === '') err_msg = 'No. harus diisi';
		else error = false;

		//console.log(post.tanggal_ipt);
		//console.log(post.date);

		if (error) {
			toast.error(err_msg);
			return;
		}

		if (router.params.file_upload_id) {
			data = await ServiceFileUpload.update(post, router.params.file_upload_id);
			msg = 'Data berhasil diupdate';
		}
		else {
			data = await ServiceFileUpload.new(post);
			msg = 'Data berhasil ditambah';
		}
		let rs = data.data;
		if (rs.status) {
			//Swal.fire('Success', rs.message, 'success');
			//toast.success(rs.message);
			let state = { toast: { type: 'success', message: msg } }
			router.navigate(url, { state });
		} else {
			//Swal.fire('Error', rs.message, 'error');
			//toast.error(rs.message);
			MIB.errorToken(rs.message);
		}
	}

	async getJenisFile() {
		const qstring = "keyword=&page=0&sort_by=0&field_sort=id&limit=1000";
		const respJenisFile = await ServiceJenisFile.get(qstring);
		//console.log(respJenisFile);
		if (respJenisFile.data.status) {
			this.setState((prevState) => ({
				...prevState,
				dt_jenis_file: respJenisFile.data.datas.content
			}));
		}else{
			MIB.errorToken(respJenisFile.data.message);
		}
	}

	async componentDidMount() {
		const { params, navigate } = this.props.router;
		await this.getJenisFile();
		//console.log(params.file_upload_id);
		if (params.file_upload_id) {
			const resp = await ServiceFileUpload.detail(params.file_upload_id);
			if (resp !== undefined) {
				if (resp.data.status && resp.data.datas !== undefined) {
					const data = resp.data.datas;//{DATE_TIME_CONVERT}
					data.date = data.date === null ? '' : data.date;
					if (data.date !== '')
						data.date = data.date.substring(0, 10);
					this.setState((prevState) => ({
						...prevState,
						file_upload_id: data.file_upload_id,
						post: data
					}));
				} else {
					MIB.errorToken(resp.data.message);
					navigate(this.props.url);
				}
			} else {
				console.log(resp.data.message);
				console.log(this.props.url);
			}
		} else {
			let jenis_file_id = '';
			let file_type = '';
			let ebook = false;
			if (this.state.dt_jenis_file.length > 0) {
				jenis_file_id = this.state.dt_jenis_file[0].jenis_file_id;
				file_type = this.state.dt_jenis_file[0].file_type;
				ebook = this.state.dt_jenis_file[0].ebook;
			}
			this.setState((prevState) => ({
				...prevState,
				post: {
					...prevState.post,
					jenis_file_id: jenis_file_id,
					file_type: file_type,
					ebook: ebook
				}
			}));
		}
	}

	render() {
		const { router, url } = this.props;
		const { post, dt_jenis_file } = this.state;
		//console.log('context:',this.props.router.context);
		//console.log("post:",post);
		const url_upload = process.env.REACT_APP_UPLOAD_URL;
		let ext_file = "document";
		if (post.file_type === 'image') ext_file = 'image';
		else if (post.file_type === 'pdf') ext_file = '.pdf';
		else if (post.file_type === 'excel') ext_file = '.xls,.xlsx';
		let html = '';
		let html2 = '';
		let html3 = '';
		if (post.ebook) {
			html = <div className="row mb-3">
				<label className="col-sm-3 col-form-label" htmlFor="url_ebook">Ebook URL:</label>
				<div className="col-sm-9"><input type="text" name="url_ebook" id="url_ebook" value={post.url_ebook} maxLength="255" className="form-control" onChange={(event) => this.setValue(event)} /></div>
			</div>
		}
		if (post.tanggal_ipt) {
			html2 = <div className="row mb-3">
				<label className="col-sm-3 col-form-label" htmlFor="date">Date:</label>
				<div className="col-sm-9"><input type="date" name="date" id="date" value={post.date} className="form-control" onChange={(event) => this.setValue(event)} /></div>
			</div>
		}
		if (post.no_ipt) {
			html3 = <div className="row mb-3">
				<label className="col-sm-3 col-form-label" htmlFor="no">No.:</label>
				<div className="col-sm-9"><input type="text" name="no" id="no" value={post.no} maxLength="50" className="form-control" onChange={(event) => this.setValue(event)} /></div>
			</div>
		}
		return (
			<Form onSubmit={this.submitHandler}>
				<div className="row">
					<div className="col-sm-12">
						<div className="card">
							<div className="card-body">
								<div className="p-4">
									<div className="card-title d-flex align-items-center">
										<div><i className="bx bxs-info-square me-1 font-22 text-info"></i></div>
										<h5 className="mb-0 text-info">{router.context.title}</h5>
									</div>
									<hr />

									<div className="row">
										<div className="col-sm-6">
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="jenis_file_id">Jenis File:</label>
												<div className="col-sm-9"><select name="jenis_file_id" id="jenis_file_id" className="form-select" onChange={(event) => { this.setValue(event) }} value={post.jenis_file_id}>
													{dt_jenis_file.map(rs => (
														<option key={rs.jenis_file_id} value={rs.jenis_file_id} file_type={rs.file_type} ebook={rs.ebook ? '1' : '0'} no={rs.no ? '1' : '0'} tanggal={rs.tanggal ? '1' : '0'}>{rs.name}</option>
													))}
												</select></div>
											</div>
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="judul">Judul (ID):</label>
												<div className="col-sm-9"><input type="text" name="judul" id="judul" value={post.judul} maxLength="255" className="form-control" onChange={(event) => this.setValue(event)} /></div>
											</div>
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="keterangan">Keterangan (ID):</label>
												<div className="col-sm-9"><input type="text" name="keterangan" id="keterangan" value={post.keterangan} maxLength="500" className="form-control" onChange={(event) => this.setValue(event)} /></div>
											</div>
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="img_photo">Photo:</label>
												<div className="col-sm-9">
													<UploadFile
														name="img_photo"
														accept="image"
														saveTo="files/image"
														url={url_upload}
														changeState={this.setValue}
														value={post.img_photo} />
												</div>
											</div>
										</div>

										<div className="col-sm-6">
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="judul_en">Judul (EN):</label>
												<div className="col-sm-9"><input type="text" name="judul_en" id="judul_en" value={post.judul_en} maxLength="255" className="form-control" onChange={(event) => this.setValue(event)} /></div>
											</div>
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="keterangan_en">Keterangan (EN):</label>
												<div className="col-sm-9"><input type="text" name="keterangan_en" id="keterangan_en" value={post.keterangan_en} maxLength="500" className="form-control" onChange={(event) => this.setValue(event)} /></div>
											</div>
											{html}
											{html2}
											{html3}
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="file_name">File:</label>
												<div className="col-sm-9">
													<UploadFile
														name="file_name"
														accept={ext_file}
														saveTo="files/document"
														url={url_upload}
														changeState={this.setValue}
														value={post.file_name} />
												</div>
											</div>
										</div>
									</div>

								</div>

							</div>
						</div>
					</div>
				</div>


				<button type="submit" className="btn btn-success radius-30 mt-2 mt-lg-0" name="btnSave" id="btnSave">
					<i className="bx bx-save" />
					Save
				</button>
				<button className="btn btn-info radius-30 mt-2 mt-lg-0 ms-1" type="button" onClick={() => router.navigate(url)}>
					<i className="bx bx-chevron-left" />
					Back
				</button>
				{this.state.file_upload_id !== '' && post.created !== undefined ? <OverlayTrigger
					key="top"
					placement="top"
					overlay={
						<Tooltip id={`tooltip-top`}>
							<table>
								<tbody>
									<tr>
										<td nowrap="nowrap">Created</td>
										<td>:</td>
										<td nowrap="nowrap">{MIB.dateTimeFormat(post.created)}</td>
									</tr>
									<tr>
										<td nowrap="nowrap">Created by</td>
										<td>:</td>
										<td nowrap="nowrap">{post.createdby}</td>
									</tr>
									<tr>
										<td nowrap="nowrap">Updated</td>
										<td>:</td>
										<td nowrap="nowrap">{MIB.dateTimeFormat(post.updated)}</td>
									</tr>
									<tr>
										<td nowrap="nowrap">Updated by</td>
										<td>:</td>
										<td nowrap="nowrap">{post.updatedby}</td>
									</tr>
								</tbody>

							</table>
						</Tooltip>
					}
				>
					<button type="button" className="btn btn-warning radius-30 mt-2 mt-lg-0 ms-1"><i className="bx bx-history" /> History</button>
				</OverlayTrigger> : ''}

				<ToastContainer
					position="top-right"
					theme="colored"
					autoClose={2000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
			</Form>
		);
	}
}

export default MIB.withRouter(InputFileUpload);