/*-----------------------------------------------------------------
GENERATED BY MIB_PHP_GENERATOR VERSION 3.0
CREATED BY MISBA
EMAIL: m.m158a@gmail.com

DATE: 26-08-2022
TIME: 11:00:48

@copyright 2020 by MISBA
------------------------------------------------------------------*/




import React, { Component } from 'react';
import { Form, Tooltip, OverlayTrigger } from "react-bootstrap";
import { ServiceVideo } from "./";
import { MIB,VARS, UploadFile } from "../../utils";
//import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class InputVideo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			video_id: '',
			post: {
				judul: '',
				judul_en: '',
				keterangan: '',
				keterangan_en: '',
				img_video: '',
				file_video: '',
				is_live: false,
				target_time: '',
				type: '',
				date: '',
				created: '',
				createdby: '',
				updated: '',
				updatedby: ''
			}

		}

		this.setValue = this.setValue.bind(this);
		ServiceVideo.setLock = this.props.router.context.setLock;
	}

	setValue(e) {
		let val = e.target.value;
		if(e.target.name === 'is_live')
			val = !this.state.post.is_live;
		this.setState((prevState) => ({
			...prevState,
			post: {
				...prevState.post,
				[e.target.name]: val
			}
		}))
	}

	submitHandler = async (e) => {
		e.preventDefault();
		const { router, url } = this.props;
		let data;
		let msg = '';
		let post = this.state.post;
		let jwt = MIB.extractToken();
		let user_name = jwt.json.user_name;
		post.createdby = user_name;
		post.updatedby = user_name;
		post.created = undefined;
		post.updated = undefined;
		if(post.target_time === '') post.target_time = null;
		
		let error = true;
		let err_msg = '';
		if(post.judul === '') err_msg = 'Judul bahasa indonesia harus diisi';
		else if(post.judul_en === '') err_msg = 'Judul bahasa inggris harus diisi';
		else if(post.img_video === '') err_msg = 'Masukan gambar yang akan diupload';
		else if(post.file_video === '') err_msg = 'URL video harus diisi';
		else if(post.date === '') err_msg = 'Tanggal harus diisi';
		else error = false;

		if(error){
			toast.error(err_msg);
			return;
		}
		post.date = post.date.replace("T", " ")+':00';
		post.date = post.date.substring(0,19);

		if (router.params.video_id) {
			data = await ServiceVideo.update(post, router.params.video_id);
			msg = 'Data berhasil diupdate';
		}
		else {
			data = await ServiceVideo.new(post);
			msg = 'Data berhasil ditambah';
		}
		let rs = data.data;
		if (rs.status) {
			//Swal.fire('Success', rs.message, 'success');
			//toast.success(rs.message);
			let state = { toast: { type: 'success', message: msg } }
			router.navigate(url, { state });
		} else {
			//Swal.fire('Error', rs.message, 'error');
			//toast.error(rs.message);
			MIB.errorToken(rs.message);
		}
	}

	async componentDidMount() {
		const { params, navigate } = this.props.router;
		if (params.video_id) {
			const resp = await ServiceVideo.detail(params.video_id);
			if (resp !== undefined) {
				if (resp.data.status && resp.data.datas !== undefined) {
					const data = resp.data.datas;//{DATE_TIME_CONVERT}
					//data.date = new Date(data.date);
					//console.log(data.date);
					//data.date = data.date.toISOString().substring(0,10);
					//console.log(data.date);
					//data.date = data.date.toLocaleString("id-ID").substring(0,10);
					this.setState((prevState) => ({
						...prevState,
						video_id: data.video_id,
						post: data
					}));
				} else {
					MIB.errorToken(resp.data.message);
					navigate(this.props.url);
				}
			} else {
				console.log(resp.data.message);
				console.log(this.props.url);
			}
		} else {
			let type = '';
			if (VARS.video_type.length > 0) type = VARS.video_type[0].key;
			this.setState((prevState) => ({
				...prevState,
				post: {
					...prevState.post,
					type: type
				}
			}));
		}
	}

	render() {
		const { router, url } = this.props;
		const { post } = this.state;
		const url_upload = process.env.REACT_APP_UPLOAD_URL;
		post.date = post.date.replace("T", " ");
		//console.log(post.date);
		post.is_live = post.is_live?true:false;
		return (
			<Form onSubmit={this.submitHandler}>
				<div className="row">
					<div className="col-sm-12">
						<div className="card">
							<div className="card-body">
								<div className="p-4">
									<div className="card-title d-flex align-items-center">
										<div><i className="bx bxs-info-square me-1 font-22 text-info"></i></div>
										<h5 className="mb-0 text-info">{router.context.title}</h5>
									</div>
									<hr />
									<div className="row">
										<div className="col-sm-6">
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="judul">Judul (ID):</label>
												<div className="col-sm-9"><input type="text" name="judul" id="judul" value={post.judul} maxLength="255" className="form-control" onChange={(event) => this.setValue(event)} /></div>
											</div>
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="keterangan">Keterangan (ID):</label>
												<div className="col-sm-9"><input type="text" name="keterangan" id="keterangan" value={post.keterangan} maxLength="500" className="form-control" onChange={(event) => this.setValue(event)} /></div>
											</div>
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="date">Date:</label>
												<div className="col-sm-9"><input type="datetime-local" name="date" id="date" value={post.date} className="form-control" onChange={(event) => this.setValue(event)} /></div>
											</div>
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="type">Type:</label>
												<div className="col-sm-9"><select name="type" id="type" className="form-select" onChange={(event) => this.setValue(event)} value={post.type}>
											{VARS.video_type.map(rs => (
												<option key={rs.key} value={rs.key}>{rs.label}</option>
											))}
										</select></div>
											</div>
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="img_video">Gambar:</label>
												<div className="col-sm-9">
													
												<UploadFile 
														name="img_video" 
														accept="image" 
														saveTo="video/image" 
														url={url_upload}
														changeState={this.setValue} 
														value={post.img_video} />

													{/*<ImageInput name="img_video" id="img_video" value={post.img_video} onChange={(event) => this.setValue(event)} />*/}
												</div>
											</div>

										</div>


										<div className="col-sm-6">
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="judul_en">Judul (EN):</label>
												<div className="col-sm-9"><input type="text" name="judul_en" id="judul_en" value={post.judul_en} maxLength="255" className="form-control" onChange={(event) => this.setValue(event)} /></div>
											</div>
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="keterangan_en">Keterangan (EN):</label>
												<div className="col-sm-9"><input type="text" name="keterangan_en" id="keterangan_en" value={post.keterangan_en} maxLength="500" className="form-control" onChange={(event) => this.setValue(event)} /></div>
											</div>
											{/*<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="file_video">Video:</label>
												<div className="col-sm-9"><FileInput name="file_video" id="file_video" value={post.file_video} onChange={(event) => this.setValue(event)} /></div>
											</div>*/}
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="is_live">Live:</label>
												<div className="col-sm-9"><input type="checkbox" name="is_live" id="is_live" checked={post.is_live} className="form-check-input" onChange={(event) => this.setValue(event)} /></div>
											</div>
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="target_time">Duration:</label>
												<div className="col-sm-9"><input type="number" name="target_time" id="target_time" value={post.target_time} className="form-control" onChange={(event) => this.setValue(event)} /></div>
											</div>
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="file_video">URL Video:</label>
												<div className="col-sm-9"><input type="text" name="file_video" id="file_video" value={post.file_video} maxLength="255" className="form-control" onChange={(event) => this.setValue(event)} /></div>
											</div>

										</div>
									</div>


								</div>

							</div>
						</div>
					</div>
				</div>


				<button type="submit" className="btn btn-success radius-30 mt-2 mt-lg-0" name="btnSave" id="btnSave">
					<i className="bx bx-save" />
					Save
				</button>
				<button className="btn btn-info radius-30 mt-2 mt-lg-0 ms-1" type="button" onClick={() => router.navigate(url)}>
					<i className="bx bx-chevron-left" />
					Back
				</button>
				{this.state.video_id !== '' && post.created !== undefined ? <OverlayTrigger
					key="top"
					placement="top"
					overlay={
						<Tooltip id={`tooltip-top`}>
							<table>
								<tbody>
									<tr>
										<td nowrap="nowrap">Created</td>
										<td>:</td>
										<td nowrap="nowrap">{MIB.dateTimeFormat(post.created)}</td>
									</tr>
									<tr>
										<td nowrap="nowrap">Created by</td>
										<td>:</td>
										<td nowrap="nowrap">{post.createdby}</td>
									</tr>
									<tr>
										<td nowrap="nowrap">Updated</td>
										<td>:</td>
										<td nowrap="nowrap">{MIB.dateTimeFormat(post.updated)}</td>
									</tr>
									<tr>
										<td nowrap="nowrap">Updated by</td>
										<td>:</td>
										<td nowrap="nowrap">{post.updatedby}</td>
									</tr>
								</tbody>

							</table>
						</Tooltip>
					}
				>
					<button type="button" className="btn btn-warning radius-30 mt-2 mt-lg-0 ms-1"><i className="bx bx-history" /> History</button>
				</OverlayTrigger> : ''}

				<ToastContainer
					position="top-right"
					theme="colored"
					autoClose={2000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
			</Form>
		);
	}
}

export default MIB.withRouter(InputVideo);