/*-----------------------------------------------------------------
GENERATED BY MIB_PHP_GENERATOR VERSION 3.0
CREATED BY MISBA
EMAIL: m.m158a@gmail.com

DATE: 26-10-2022
TIME: 13:12:04

@copyright 2020 by MISBA
------------------------------------------------------------------*/




import React, { Component } from 'react';
import { Form, Tooltip, OverlayTrigger } from "react-bootstrap";
import { ServiceUsers } from "./";
import { MIB, VARS } from "../../utils";
//import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class InputUsers extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: '',
			post: {
				password: '',
				fullName: '',
				phoneNumber: '',
				emailAddress: '',
				address: '',
				roleCode: '',
				segmentCode: '',
				nip: '',
				//status: true,
				isActive: true
			},
			role:[],
			segment:[]

		}

		this.setValue = this.setValue.bind(this);
		ServiceUsers.setLock = this.props.router.context.setLock;
	}

	setValue(e) {
		let value = e.target.value;
		/*if(e.target.name === 'isActive')
			value = !this.state.post.isActive;*/
		this.setState((prevState) => ({
			...prevState,
			post: {
				...prevState.post,
				[e.target.name]: value
			}
		}))
	}

	submitHandler = async (e) => {
		e.preventDefault();
		const { router, url } = this.props;
		let data;
		let msg = '';
		let post = this.state.post;
		//let jwt = MIB.extractToken();
		//let user_name = jwt.json.user_name;
		//post.createdby = user_name;
		//post.updatedby = user_name;

		let validEMail = /^.+@.+\..+/i;
		let stsEMail = validEMail.test(post.emailAddress);
		let validNumber = /^[0-9]+$/
		let stsNumber = validNumber.test(post.phoneNumber);
		//alert(stsEMail);
		//alert(stsNumber);
		let error = true;
		let err_msg = '';
		if (post.fullName === '') err_msg = 'Name harus diisi';
		//else if (post.password === '') err_msg = 'Password harus diisi';
		else if (post.emailAddress === '') err_msg = 'Email harus diisi';
		else if(!stsEMail) err_msg = 'Email tidak valid';
		else if (post.phoneNumber !== '' && !stsNumber) err_msg = 'No. telepon harus angka semua';
		else if (post.roleCode === '') err_msg = 'Role harus diisi';
		else if (post.segmentCode === '') err_msg = 'Segmen harus diisi';
		else error = false;

		if (error) {
			toast.error(err_msg);
			return;
		}

		//console.log(post);

		if (router.params.id) {
			data = await ServiceUsers.update(post, router.params.id);
			msg = 'Data berhasil diupdate';
		}
		else {
			data = await ServiceUsers.new(post);
			msg = 'Data berhasil ditambah';
		}
		let rs = data.data;
		if (rs.status) {
			//Swal.fire('Success', rs.message, 'success');
			//toast.success(rs.message);
			let state = { toast: { type: 'success', message: msg } }
			router.navigate(url, { state });
		} else {
			//Swal.fire('Error', rs.message, 'error');
			//toast.error(rs.message);
			MIB.errorToken(rs.message);
		}
	}

	async componentDidMount() {
		const { params, navigate } = this.props.router;

		let resp = await ServiceUsers.getRole();
		let role = resp.data.datas.content;
		let resp2 = await ServiceUsers.getGroup();
		let segment = resp2.data.datas.content;
		//console.log(segment);

		if (params.id) {
			const resp = await ServiceUsers.detail(params.id);
			if (resp !== undefined) {
				if (resp.data.status && resp.data.datas !== undefined) {
					const data = resp.data.datas;//{DATE_TIME_CONVERT}
					this.setState((prevState) => ({
						...prevState,
						id: data.id,
						post: data,
						role: role,
						segment: segment
					}));
				} else {
					MIB.errorToken(resp.data.message);
					navigate(this.props.url);
				}
			} else {
				console.log(resp.data.message);
				console.log(this.props.url);
			}
		} else {
			let roleCode = '';
			if (VARS.users_roleCode.length > 0) roleCode = VARS.users_roleCode[0].key;
			let segmentCode = '';
			if (VARS.users_segmentCode.length > 0) segmentCode = VARS.users_segmentCode[0].key;
			this.setState((prevState) => ({
				...prevState,
				post: {
					...prevState.post,
					roleCode: roleCode,
					segmentCode: segmentCode
				},
				role: role,
				segment: segment
			}));
		}
	}

	render() {
		const { router, url } = this.props;
		const { post,role,segment } = this.state;
		//console.log('role:',role);
		//console.log('segment:',segment);
		if(post.password === null) post.password = '';
		return (
			<Form onSubmit={this.submitHandler}>
				<div className="row">
					<div className="col-sm-12">
						<div className="card">
							<div className="card-body">
								<div className="p-4">
									<div className="card-title d-flex align-items-center">
										<div><i className="bx bxs-info-square me-1 font-22 text-info"></i></div>
										<h5 className="mb-0 text-info">{router.context.title}</h5>
									</div>
									<hr />

									<div className="row">
										<div className="col-sm-6">
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="nip">NIP:</label>
												<div className="col-sm-9"><input type="text" name="nip" id="nip" value={post.nip} maxLength="20" className="form-control" onChange={(event) => this.setValue(event)} /></div>
											</div>
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="fullName">Full Name:</label>
												<div className="col-sm-9"><input type="text" name="fullName" id="fullName" value={post.fullName} maxLength="255" className="form-control" onChange={(event) => this.setValue(event)} /></div>
											</div>
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="phoneNumber">Phone Number:</label>
												<div className="col-sm-9"><input type="text" name="phoneNumber" id="phoneNumber" value={post.phoneNumber} maxLength="11" className="form-control text-right" onChange={(event) => this.setValue(event)} /></div>
											</div>
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="address">Address:</label>
												<div className="col-sm-9"><input type="text" name="address" id="address" value={post.address} maxLength="255" className="form-control" onChange={(event) => this.setValue(event)} /></div>
											</div>
										</div>
										<div className="col-sm-6">
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="emailAddress">Email:</label>
												<div className="col-sm-9"><input type="text" name="emailAddress" id="emailAddress" value={post.emailAddress} maxLength="255" className="form-control" onChange={(event) => this.setValue(event)} /></div>
											</div>
											{/*<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="password">Password:</label>
												<div className="col-sm-9"><input type="text" name="password" id="password" value={post.password} maxLength="255" className="form-control" onChange={(event) => this.setValue(event)} /></div>
											</div>*/}
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="roleCode">Role:</label>
												<div className="col-sm-9"><select name="roleCode" id="roleCode" className="form-select" onChange={(event) => this.setValue(event)} value={post.roleCode}>
													{role.map((rs,i) => (
														<option key={i} value={rs.roleCode}>{rs.roleName}</option>
													))}
												</select></div>
											</div>
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="segmentCode">Segment:</label>
												<div className="col-sm-9"><select name="segmentCode" id="segmentCode" className="form-select" onChange={(event) => this.setValue(event)} value={post.segmentCode}>
													{segment.map((rs,i) => (
														<option key={i} value={rs.segmentCode}>{rs.segmentName}</option>
													))}
												</select></div>
											</div>
											{/*<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="status">Status:</label>
												<div className="col-sm-9"><input type="checkbox" name="status" id="status" checked={post.status} className="form-check-input" onChange={(event) => this.setValue(event)} /></div>
													</div>*/}
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="isActive">Active:</label>
												<div className="col-sm-9"><input type="checkbox" name="isActive" id="isActive" disabled checked={post.isActive} className="form-check-input" onChange={(event) => this.setValue(event)} /></div>
											</div>
										</div>
									</div>



								</div>

							</div>
						</div>
					</div>
				</div>


				<button type="submit" className="btn btn-success radius-30 mt-2 mt-lg-0" name="btnSave" id="btnSave">
					<i className="bx bx-save" />
					Save
				</button>
				<button className="btn btn-info radius-30 mt-2 mt-lg-0 ms-1" type="button" onClick={() => router.navigate(url)}>
					<i className="bx bx-chevron-left" />
					Back
				</button>
				{this.state.id !== '' && post.created !== undefined ? <OverlayTrigger
					key="top"
					placement="top"
					overlay={
						<Tooltip id={`tooltip-top`}>
							<table>
								<tbody>
									<tr>
										<td nowrap="nowrap">Created</td>
										<td>:</td>
										<td nowrap="nowrap">{MIB.dateTimeFormat(post.created)}</td>
									</tr>
									<tr>
										<td nowrap="nowrap">Created by</td>
										<td>:</td>
										<td nowrap="nowrap">{post.createdby}</td>
									</tr>
									<tr>
										<td nowrap="nowrap">Updated</td>
										<td>:</td>
										<td nowrap="nowrap">{MIB.dateTimeFormat(post.updated)}</td>
									</tr>
									<tr>
										<td nowrap="nowrap">Updated by</td>
										<td>:</td>
										<td nowrap="nowrap">{post.updatedby}</td>
									</tr>
								</tbody>

							</table>
						</Tooltip>
					}
				>
					<button type="button" className="btn btn-warning radius-30 mt-2 mt-lg-0 ms-1"><i className="bx bx-history" /> History</button>
				</OverlayTrigger> : ''}

				<ToastContainer
					position="top-right"
					theme="colored"
					autoClose={2000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
			</Form>
		);
	}
}

export default MIB.withRouter(InputUsers);