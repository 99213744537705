/*-----------------------------------------------------------------
GENERATED BY MIB_PHP_GENERATOR VERSION 3.0
CREATED BY MISBA
EMAIL: m.m158a@gmail.com

DATE: 24-10-2022
TIME: 09:37:19

@copyright 2020 by MISBA
------------------------------------------------------------------*/




import React, { Component } from 'react';
import { Link, Outlet } from "react-router-dom";
import { MIB } from "../../utils";

class IndexSubscriptionsMessages extends Component {
    render() {
        const [mib,modulName,action,id] = MIB.getUri(this.props.router.location.pathname);
        let title = "";
        if(action===undefined||action==="") title = "Data";
        else if(id===undefined||id==="") title = "New data";
        else title = "Update data";
        const modul = {
            name: 'Subscriptions Messages',
            url: `/${mib}/${modulName}`,
            title: title,
            setLock: this.props.router.context.setLock
        };
        document.title = process.env.REACT_APP_APP_NAME + ' - ' + modul.name + ' - ' + modul.title;

        return (
            <div className="page-wrapper">
                <div className="page-content">
                    <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                        <div className="breadcrumb-title pe-3">{modul.name}</div>
                        <div className="ps-3">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item"><Link to={modul.url}><i className="bx bx-category" /></Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">{modul.title}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                    <Outlet context={modul} />

                </div>
            </div>
        );
    }
}

export default MIB.withRouter(IndexSubscriptionsMessages);