/*-----------------------------------------------------------------
GENERATED BY MIB_PHP_GENERATOR VERSION 3.0
CREATED BY MISBA
EMAIL: m.m158a@gmail.com

DATE: 31-08-2022
TIME: 03:05:00

@copyright 2020 by MISBA
------------------------------------------------------------------*/




import React, { Component } from 'react';
import { Form, Tooltip, OverlayTrigger } from "react-bootstrap";
import { ServiceProfile } from "./";
import { MIB, VARS, UploadFile } from "../../utils";
//import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

class InputProfile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			profile_id: '',
			post: {
				nama: '',
				jenis: '',
				jabatan: '',
				jabatan_en: '',
				jabatan_lain: '',
				jabatan_lain_en: '',
				keterangan: '',
				keterangan_en: '',
				img_photo: '',
				level: '',
				created: '',
				createdby: '',
				updated: '',
				updatedby: ''
			}

		}

		this.setValue = this.setValue.bind(this);
		this.setHTML = this.setHTML.bind(this);
		ServiceProfile.setLock = this.props.router.context.setLock;
	}

	setHTML(html, name) {
		const e = {
			target: {
				name: name,
				value: html
			}
		};
		this.setValue(e);
	}

	setValue(e) {
		this.setState((prevState) => ({
			...prevState,
			post: {
				...prevState.post,
				[e.target.name]: e.target.value
			}
		}))
	}

	submitHandler = async (e) => {
		e.preventDefault();
		const { router, url } = this.props;
		let data;
		let msg = '';
		let post = this.state.post;
		let jwt = MIB.extractToken();
		let user_name = jwt.json.user_name;
		post.createdby = user_name;
		post.updatedby = user_name;
		post.created = undefined;
		post.updated = undefined;

		let error = true;
		let err_msg = '';
		if (post.nama === '') err_msg = 'Nama harus diisi';
		else if (post.jabatan === '') err_msg = 'Jabatan bahasa indonesia harus diisi';
		else if (post.jabatan_en === '') err_msg = 'Jabatan bahasa inggris harus diisi';
		else if (post.jabatan_lain === '') err_msg = 'Jabatan lain bahasa indonesia harus diisi';
		else if (post.jabatan_lain_en === '') err_msg = 'Jabatan lain bahasa inggris harus diisi';
		else error = false;

		if (error) {
			toast.error(err_msg);
			return;
		}

		if (router.params.profile_id) {
			data = await ServiceProfile.update(post, router.params.profile_id);
			msg = 'Data berhasil diupdate';
		}
		else {
			data = await ServiceProfile.new(post);
			msg = 'Data berhasil ditambah';
		}
		let rs = data.data;
		if (rs.status) {
			//Swal.fire('Success', rs.message, 'success');
			//toast.success(rs.message);
			let state = { toast: { type: 'success', message: msg } }
			router.navigate(url, { state });
		} else {
			//Swal.fire('Error', rs.message, 'error');
			//toast.error(rs.message);
			MIB.errorToken(rs.message);
		}
	}

	async componentDidMount() {
		const { params, navigate } = this.props.router;
		if (params.profile_id) {
			const resp = await ServiceProfile.detail(params.profile_id);
			if (resp !== undefined) {
				if (resp.data.status && resp.data.datas !== undefined) {
					const data = resp.data.datas;//{DATE_TIME_CONVERT}
					this.setState((prevState) => ({
						...prevState,
						profile_id: data.profile_id,
						post: data
					}));
				} else {
					MIB.errorToken(resp.data.message);
					navigate(this.props.url);
				}
			} else {
				console.log(resp.data.message);
				console.log(this.props.url);
			}
		} else {
			let jenis = '';
			if (VARS.profile_jenis.length > 0) jenis = VARS.profile_jenis[0].key;
			this.setState((prevState) => ({
				...prevState,
				post: {
					...prevState.post,
					jenis: jenis
				}
			}));
		}
	}

	render() {
		const { router, url } = this.props;
		const { post } = this.state;
		const url_upload = process.env.REACT_APP_UPLOAD_URL;
		const toolbar = MIB.quillToolbar();
		return (
			<Form onSubmit={this.submitHandler}>
				<div className="row">
					<div className="col-sm-12">
						<div className="card">
							<div className="card-body">
								<div className="p-4">
									<div className="card-title d-flex align-items-center">
										<div><i className="bx bxs-info-square me-1 font-22 text-info"></i></div>
										<h5 className="mb-0 text-info">{router.context.title}</h5>
									</div>
									<hr />

									<div className="row">
										<div className="col-sm-6">
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="jenis">Jenis:</label>
												<div className="col-sm-9"><select name="jenis" id="jenis" className="form-select" onChange={(event) => this.setValue(event)} value={post.jenis}>
													{VARS.profile_jenis.map(rs => (
														<option key={rs.key} value={rs.key}>{rs.label}</option>
													))}
												</select></div>
											</div>
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="jabatan">Jabatan (ID):</label>
												<div className="col-sm-9"><input type="text" name="jabatan" id="jabatan" value={post.jabatan} maxLength="255" className="form-control" onChange={(event) => this.setValue(event)} /></div>
											</div>
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="jabatan_lain">Jabatan Lain (ID):</label>
												<div className="col-sm-9"><input type="text" name="jabatan_lain" id="jabatan_lain" value={post.jabatan_lain} maxLength="255" className="form-control" onChange={(event) => this.setValue(event)} /></div>
											</div>
											<div className="mb-3">
												<label className="col-form-label" htmlFor="keterangan">Keterangan (ID):</label>
												<ReactQuill
													theme="snow"
													modules={toolbar.modules}
													formats={toolbar.formats}
													value={post.keterangan}
													onChange={(html) => this.setHTML(html, 'keterangan')}
												/>

												{/*<input type="text" name="keterangan" id="keterangan" value={post.keterangan} maxLength="500" className="form-control" onChange={(event) => this.setValue(event)} />*/}
											</div>
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="img_photo">Photo:</label>
												<div className="col-sm-9">
													<UploadFile
														name="img_photo"
														accept="image"
														saveTo="profile/image"
														url={url_upload}
														changeState={this.setValue}
														value={post.img_photo} />
												</div>
											</div>
										</div>

										<div className="col-sm-6">
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="nama">Nama:</label>
												<div className="col-sm-9"><input type="text" name="nama" id="nama" value={post.nama} maxLength="255" className="form-control" onChange={(event) => this.setValue(event)} /></div>
											</div>
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="jabatan_en">Jabatan (EN):</label>
												<div className="col-sm-9"><input type="text" name="jabatan_en" id="jabatan_en" value={post.jabatan_en} maxLength="255" className="form-control" onChange={(event) => this.setValue(event)} /></div>
											</div>
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="jabatan_lain_en">Jabatan Lain (EN):</label>
												<div className="col-sm-9"><input type="text" name="jabatan_lain_en" id="jabatan_lain_en" value={post.jabatan_lain_en} maxLength="255" className="form-control" onChange={(event) => this.setValue(event)} /></div>
											</div>
											<div className="mb-3">
												<label className="col-form-label" htmlFor="keterangan_en">Keterangan (EN):</label>
												<ReactQuill
													theme="snow"
													modules={toolbar.modules}
													formats={toolbar.formats}
													value={post.keterangan_en}
													onChange={(html) => this.setHTML(html, 'keterangan_en')}
												/>
												{/*<input type="text" name="keterangan_en" id="keterangan_en" value={post.keterangan_en} maxLength="500" className="form-control" onChange={(event) => this.setValue(event)} />*/}
											</div>
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="level">Level:</label>
												<div className="col-sm-9"><input type="number" name="level" id="level" value={post.level} className="form-control" onChange={(event) => this.setValue(event)} /></div>
											</div>

										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>


				<button type="submit" className="btn btn-success radius-30 mt-2 mt-lg-0" name="btnSave" id="btnSave">
					<i className="bx bx-save" />
					Save
				</button>
				<button className="btn btn-info radius-30 mt-2 mt-lg-0 ms-1" type="button" onClick={() => router.navigate(url)}>
					<i className="bx bx-chevron-left" />
					Back
				</button>
				{this.state.profile_id !== '' && post.created !== undefined ? <OverlayTrigger
					key="top"
					placement="top"
					overlay={
						<Tooltip id={`tooltip-top`}>
							<table>
								<tbody>
									<tr>
										<td nowrap="nowrap">Created</td>
										<td>:</td>
										<td nowrap="nowrap">{MIB.dateTimeFormat(post.created)}</td>
									</tr>
									<tr>
										<td nowrap="nowrap">Created by</td>
										<td>:</td>
										<td nowrap="nowrap">{post.createdby}</td>
									</tr>
									<tr>
										<td nowrap="nowrap">Updated</td>
										<td>:</td>
										<td nowrap="nowrap">{MIB.dateTimeFormat(post.updated)}</td>
									</tr>
									<tr>
										<td nowrap="nowrap">Updated by</td>
										<td>:</td>
										<td nowrap="nowrap">{post.updatedby}</td>
									</tr>
								</tbody>

							</table>
						</Tooltip>
					}
				>
					<button type="button" className="btn btn-warning radius-30 mt-2 mt-lg-0 ms-1"><i className="bx bx-history" /> History</button>
				</OverlayTrigger> : ''}

				<ToastContainer
					position="top-right"
					theme="colored"
					autoClose={2000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
			</Form>
		);
	}
}

export default MIB.withRouter(InputProfile);