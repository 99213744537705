/*-----------------------------------------------------------------
GENERATED BY MIB_PHP_GENERATOR VERSION 3.0
CREATED BY MISBA
EMAIL: m.m158a@gmail.com

DATE: 10-11-2022
TIME: 10:03:38

@copyright 2020 by MISBA
------------------------------------------------------------------*/




import React, { Component } from 'react';
import { Form, Tooltip, OverlayTrigger } from "react-bootstrap";
import { ServicePressRelease } from "./";
import { MIB } from "../../utils";
import Swal from "sweetalert2";
//import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PressReleaseGallery } from "./"
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

class InputPressRelease extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: '',
			post: {
				title: '',
				title_en: '',
				tanggal: '',
				summary: '',
				summary_en: '',
				content: '',
				content_en: '',
				created: '',
				createdby: '',
				updated: '',
				updatedby: '',
				galleryReleases: [],
				del_press_release_gallery: []
			}

		}

		this.setValue = this.setValue.bind(this);
		this.setDetailValue = this.setDetailValue.bind(this);
		this.newPressReleaseGallery = this.newPressReleaseGallery.bind(this);
		this.delPressReleaseGallery = this.delPressReleaseGallery.bind(this);
		this.setHTML = this.setHTML.bind(this);
		ServicePressRelease.setLock = this.props.router.context.setLock;
	}

	setHTML(html, name) {
		const e = {
			target: {
				name: name,
				value: html
			}
		};
		this.setValue(e);
	}

	setDetailValue(e) {
		//alert(e.target.name);
		let arr = e.target.name.split("[");
		let obj_name = 'galleryReleases';//'dt_' + arr[0];
		let pos = parseInt(arr[1].replace(/\]/, ''));
		let field_name = arr[2].replace(/\]/, '');
		let value = e.target.value;
		let results = this.state.post[obj_name].map((rs, i) => {
			if (i === pos) {
				return {
					...rs,
					[field_name]: value
				}
			} else {
				return rs;
			}
		});
		this.setState((prevState) => ({
			...prevState,
			post: {
				...prevState.post,
				[obj_name]: results
			}
		}));
	}

	/* start press_release_gallery */
	press_release_gallery = {
		id: '',
		id_press_release: '',
		filename: '',
		image: ''
	}

	newPressReleaseGallery() {
		this.setState((prevState) => ({
			...prevState,
			post: {
				...prevState.post,
				galleryReleases: [
					...prevState.post.galleryReleases,
					this.press_release_gallery
				]
			}
		}));
	}

	async delPressReleaseGallery(pos) {
		Swal.fire({
			title: 'Anda yakin?',
			text: "Hapus data ini!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Ya, hapus!'
		}).then(async (result) => {
			if (result.isConfirmed) {
				let rs = this.state.post.galleryReleases[pos];
				//console.log(rs);
				let del_press_release_gallery = this.state.post.galleryReleases;
				if (rs.id != ''){
					await ServicePressRelease.deleteGalery(rs.id);
					del_press_release_gallery = [...this.state.post.del_press_release_gallery, rs.id];
				}
				let results = this.state.post.galleryReleases.filter((rs, i) => {
					if (i !== pos) {
						return rs;
					}
				});

				this.setState((prevState) => ({
					...prevState,
					post: {
						...prevState.post,
						galleryReleases: results,
						del_press_release_gallery
					}
				}));

				toast.success("Data berhasil dihapus");
			}
		});
	}
	/* end press_release_gallery */

	setValue(e) {
		this.setState((prevState) => ({
			...prevState,
			post: {
				...prevState.post,
				[e.target.name]: e.target.value
			}
		}))
	}

	submitHandler = async (e) => {
		e.preventDefault();
		const { router, url } = this.props;
		let data;
		let msg = '';
		let post = this.state.post;
		let jwt = MIB.extractToken();
		let user_name = jwt.json.user_name;
		post.createdby = user_name;
		post.updatedby = user_name;
		
		let error = true;
		let err_msg = '';
		if(post.title === '') err_msg = 'Title bahasa indonesia harus diisi';
		//else if(post.title_en === '') err_msg = 'Title bahasa inggris harus diisi';
		else if(post.content === '') err_msg = 'Content bahasa indonesia harus diisi';
		//else if(post.content_en === '') err_msg = 'Content bahasa inggris harus diisi';
		else if(post.tanggal === '') err_msg = 'Tanggal harus diisi';
		else error = false;

		if(error){
			toast.error(err_msg);
			return;
		}

		//console.log(post);

		if (router.params.id) {
			data = await ServicePressRelease.update(post, router.params.id);
			msg = 'Data berhasil diupdate';
		}
		else {
			data = await ServicePressRelease.new(post);
			msg = 'Data berhasil ditambah';
		}
		let rs = data.data;
		if (rs.status) {
			//Swal.fire('Success', rs.message, 'success');
			//toast.success(rs.message);
			let state = { toast: { type: 'success', message: msg } }
			router.navigate(url, { state });
		} else {
			//Swal.fire('Error', rs.message, 'error');
			//toast.error(rs.message);
			MIB.errorToken(rs.message);
		}
	}

	async componentDidMount() {
		const { params, navigate } = this.props.router;
		if (params.id) {
			const resp = await ServicePressRelease.detail(params.id);
			if (resp !== undefined) {
				if (resp.data.status && resp.data.datas !== undefined) {
					const data = resp.data.datas;//{DATE_TIME_CONVERT}
					if (data.galleryReleases === undefined || data.galleryReleases === null || data.galleryReleases === '') data.galleryReleases = [];
					if (data.del_press_release_gallery === undefined || data.del_press_release_gallery === null || data.del_press_release_gallery === '') data.del_press_release_gallery = [];//{DATE_TIME_CONVERT}
					this.setState((prevState) => ({
						...prevState,
						id: data.id,
						post: data
					}));
				} else {
					MIB.errorToken(resp.data.message);
					navigate(this.props.url);
				}
			} else {
				console.log(resp.data.message);
				console.log(this.props.url);
			}
		}
	}

	render() {
		const { router, url } = this.props;
		const { post } = this.state;
		const toolbar = MIB.quillToolbar();
		return (
			<Form onSubmit={this.submitHandler}>
				<div className="row">
					<div className="col-sm-12">
						<div className="card">
							<div className="card-body">
								<div className="p-4">
									<div className="card-title d-flex align-items-center">
										<div><i className="bx bxs-info-square me-1 font-22 text-info"></i></div>
										<h5 className="mb-0 text-info">{router.context.title}</h5>
									</div>
									<hr />

									<div className="row">
										<div className="col-sm-6">
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="title">Title (ID):</label>
												<div className="col-sm-9"><input type="text" name="title" id="title" value={post.title} maxLength="255" className="form-control" onChange={(event) => this.setValue(event)} /></div>
											</div>
											<div className="mb-3">
												<label className="form-label" htmlFor="summary">Summary (ID):</label>
												<ReactQuill
													theme="snow"
													modules={toolbar.modules}
													formats={toolbar.formats}
													value={post.summary}
													onChange={(html) => this.setHTML(html, 'summary')}
												/>
												{/*<input type="text" name="summary" id="summary" value={post.summary} maxLength="255" className="form-control" onChange={(event) => this.setValue(event)} />*/}
											</div>

											<div className="mb-3">
												<label className="form-label" htmlFor="content">Content (ID):</label>
												<ReactQuill
													theme="snow"
													modules={toolbar.modules}
													formats={toolbar.formats}
													value={post.content}
													onChange={(html) => this.setHTML(html, 'content')}
												/>

												{/*<input type="text" name="content" id="content" value={post.content} maxLength="7000" className="form-control" onChange={(event) => this.setValue(event)} />*/}
											</div>
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="tanggal">Tanggal:</label>
												<div className="col-sm-9"><input type="date" name="tanggal" id="tanggal" value={post.tanggal.substring(0, 16)} className="form-control" onChange={(event) => this.setValue(event)} /></div>
											</div>

										</div>
										<div className="col-sm-6">
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="title_en">Title (EN):</label>
												<div className="col-sm-9"><input type="text" name="title_en" id="title_en" value={post.title_en} maxLength="255" className="form-control" onChange={(event) => this.setValue(event)} /></div>
											</div>
											<div className="mb-3">
												<label className="form-label" htmlFor="summary_en">Summary (EN):</label>
												<ReactQuill
													theme="snow"
													modules={toolbar.modules}
													formats={toolbar.formats}
													value={post.summary_en}
													onChange={(html) => this.setHTML(html, 'summary_en')}
												/>
												{/*<input type="text" name="summary_en" id="summary_en" value={post.summary_en} maxLength="255" className="form-control" onChange={(event) => this.setValue(event)} />*/}
											</div>
											<div className="mb-3">
												<label className="form-label" htmlFor="content_en">Content (EN):</label>
												<ReactQuill
													theme="snow"
													modules={toolbar.modules}
													formats={toolbar.formats}
													value={post.content_en}
													onChange={(html) => this.setHTML(html, 'content_en')}
												/>
												{/*<input type="text" name="content_en" id="content_en" value={post.content_en} maxLength="7000" className="form-control" onChange={(event) => this.setValue(event)} />*/}
											</div>

										</div>
									</div>


								</div>

							</div>
						</div>
					</div>
				</div>
				<PressReleaseGallery rows={post.galleryReleases} setDetailValue={this.setDetailValue} newPressReleaseGallery={this.newPressReleaseGallery} delPressReleaseGallery={this.delPressReleaseGallery} />


				<button type="submit" className="btn btn-success radius-30 mt-2 mt-lg-0" name="btnSave" id="btnSave">
					<i className="bx bx-save" />
					Save
				</button>
				<button className="btn btn-info radius-30 mt-2 mt-lg-0 ms-1" type="button" onClick={() => router.navigate(url)}>
					<i className="bx bx-chevron-left" />
					Back
				</button>
				{this.state.id !== '' && post.created !== undefined ? <OverlayTrigger
					key="top"
					placement="top"
					overlay={
						<Tooltip id={`tooltip-top`}>
							<table>
								<tbody>
									<tr>
										<td nowrap="nowrap">Created</td>
										<td>:</td>
										<td nowrap="nowrap">{MIB.dateTimeFormat(post.created)}</td>
									</tr>
									<tr>
										<td nowrap="nowrap">Created by</td>
										<td>:</td>
										<td nowrap="nowrap">{post.createdby}</td>
									</tr>
									<tr>
										<td nowrap="nowrap">Updated</td>
										<td>:</td>
										<td nowrap="nowrap">{MIB.dateTimeFormat(post.updated)}</td>
									</tr>
									<tr>
										<td nowrap="nowrap">Updated by</td>
										<td>:</td>
										<td nowrap="nowrap">{post.updatedby}</td>
									</tr>
								</tbody>

							</table>
						</Tooltip>
					}
				>
					<button type="button" className="btn btn-warning radius-30 mt-2 mt-lg-0 ms-1"><i className="bx bx-history" /> History</button>
				</OverlayTrigger> : ''}

				<ToastContainer
					position="top-right"
					theme="colored"
					autoClose={2000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
			</Form>
		);
	}
}

export default MIB.withRouter(InputPressRelease);