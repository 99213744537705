import React, { Component, Fragment } from 'react';
import Header from './Header';
import Sidebar from './Sidebar';

import { Outlet, Navigate } from "react-router-dom";
import { MIB } from '../../../utils';

class Amdash extends Component {

    constructor(props) {
        super(props);
        this.state = {
            stogle: MIB.getStorage(),
            lock: false
        }

        this.sidebarKlik = this.sidebarKlik.bind(this);
        this.setLock = this.setLock.bind(this);
    }

    setLock(sts) {
        this.setState(prevState => ({
            ...prevState,
            lock: sts
        }));
    }

    sidebarKlik() {
        //const stogle = MIB.getStorage();
        MIB.changeStorage();
        this.setState(prevState => ({
            stogle: MIB.getStorage()
        }));
        //console.log(MIB.getStorage());
    }

    componentDidMount() {
        MIB.loadJS('/assets/js/app.js');
    }

    render() {
        const { stogle,lock } = this.state;

        //console.log(this.state.stogle);

        const valid = MIB.getSession();
        let url = '/';
        if (valid === null || valid === undefined || !valid.status) {
            sessionStorage.clear();
            return (
                <Navigate to={url} replace={true} />
            );
        }
        let jwt = valid.data;
        //console.log(jwt);

        /*let url = '/';
        if (valid == null || !valid.status) {
            sessionStorage.clear();
            return (
                <Navigate to={url} replace={true} />
            );
        }
        const jwt = MIB.extractToken();
        //console.log(jwt);
        if (jwt.isExpired) {
            sessionStorage.clear();
            return (<Navigate to={url} replace={true} />);
        }*/

        let lockApp = {
            setLock: this.setLock
        }

        return (
            <Fragment>
                {/*wrapper*/}
                <div className={stogle ? 'wrapper toggled' : 'wrapper'}>
                    {/*sidebar wrapper */}
                    <Sidebar sidebarKlik={this.sidebarKlik} user={jwt} /> {/* cookies={cookies}*/}
                    {/*end sidebar wrapper */}

                    {/*start header */}
                    <Header user={jwt} />
                    {/*end header */}

                    {/*start page wrapper */}
                    <Outlet context={lockApp} />
                    {/*end page wrapper */}

                    {/*start overlay*/}
                    <div className="overlay toggle-icon" />
                    {/*end overlay*/}
                    {/*Start Back To Top Button*/} <button className="back-to-top"><i className="bx bxs-up-arrow-alt" /></button>
                    {/*End Back To Top Button*/}

                    <footer className="page-footer">
                        <p className="mb-0">Copyright © 2022. All right reserved.</p>
                    </footer>
                </div>
                {/*end wrapper*/}
                
                {lock ?
                    <><div id="locker" className="transparent_class"></div><div id="lockwindow"><div className="lds-facebook"><div></div><div></div><div></div></div></div></> :
                    ''
                }

            </Fragment>
        );
    }
}

export default Amdash;