/*-----------------------------------------------------------------
GENERATED BY MIB_PHP_GENERATOR VERSION 3.0
CREATED BY MISBA
EMAIL: m.m158a@gmail.com

DATE: 26-08-2022
TIME: 11:00:48

@copyright 2020 by MISBA
------------------------------------------------------------------*/




import React, { Component } from 'react';
import { Form, Tooltip, OverlayTrigger } from "react-bootstrap";
import { ServiceJenisFile } from "./";
import { MIB, VARS, FileInput } from "../../utils";
//import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class InputJenisFile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			jenis_file_id: '',
			post: {
				name: '',
				name_en: '',
				file_type: '',
				view_type: '',
				ebook: true,
				download: true,
				no: false,
				tanggal: true,
				created: '',
				createdby: '',
				updated: '',
				updatedby: ''
			}

		}

		this.setValue = this.setValue.bind(this);
		ServiceJenisFile.setLock = this.props.router.context.setLock;
	}

	setValue(e) {
		//console.log("name:",e.target.name);
		let value = e.target.value;
		if (e.target.name === 'ebook')
			value = !this.state.post.ebook;
		else if (e.target.name === 'download')
			value = !this.state.post.download;
		else if (e.target.name === 'no')
			value = !this.state.post.no;
		else if (e.target.name === 'tanggal')
			value = !this.state.post.tanggal;
		//console.log("value:",value);
		this.setState((prevState) => ({
			...prevState,
			post: {
				...prevState.post,
				[e.target.name]: value
			}
		}))
	}

	submitHandler = async (e) => {
		e.preventDefault();
		const { router, url } = this.props;
		let data;
		let msg = '';
		let post = this.state.post;
		let jwt = MIB.extractToken();
		let user_name = jwt.json.user_name;
		post.createdby = user_name;
		post.updatedby = user_name;

		let error = true;
		let err_msg = '';
		if (post.name === '') err_msg = 'Nama bahasa indonesia harus diisi';
		else if (post.name_en === '') err_msg = 'Nama bahasa inggris harus diisi';
		else error = false;

		if (error) {
			toast.error(err_msg);
			return;
		}

		if (router.params.jenis_file_id) {
			data = await ServiceJenisFile.update(post, router.params.jenis_file_id);
			msg = 'Data berhasil diupdate';
		}
		else {
			data = await ServiceJenisFile.new(post);
			msg = 'Data berhasil ditambah';
		}
		let rs = data.data;
		if (rs.status) {
			//Swal.fire('Success', rs.message, 'success');
			//toast.success(rs.message);
			let state = { toast: { type: 'success', message: msg } }
			router.navigate(url, { state });
		} else {
			//Swal.fire('Error', rs.message, 'error');
			//toast.error(rs.message);
			MIB.errorToken(rs.message);
		}
	}

	async componentDidMount() {
		const { params, navigate } = this.props.router;
		if (params.jenis_file_id) {
			const resp = await ServiceJenisFile.detail(params.jenis_file_id);
			//console.log(resp);
			if (resp !== undefined) {
				if (resp.data.status && resp.data.data !== null) {
					const data = resp.data.datas;//{DATE_TIME_CONVERT}
					this.setState((prevState) => ({
						...prevState,
						jenis_file_id: data.jenis_file_id,
						post: data
					}));
				} else {
					MIB.errorToken(resp.data.message);
					navigate(this.props.url);
				}
			} else {
				console.log(resp.data.message);
				console.log(this.props.url);
			}
		} else {
			let file_type = '';
			if (VARS.jenis_file_file_type.length > 0) file_type = VARS.jenis_file_file_type[0].key;
			let view_type = '';
			if (VARS.jenis_file_view_type.length > 0) view_type = VARS.jenis_file_view_type[0].key;
			/*let ebook = '';
			if (VARS.jenis_file_ebook.length > 0) ebook = VARS.jenis_file_ebook[0].key;
			let download = '';
			if (VARS.jenis_file_download.length > 0) download = VARS.jenis_file_download[0].key;*/
			this.setState((prevState) => ({
				...prevState,
				post: {
					...prevState.post,
					file_type: file_type,
					view_type: view_type,
					ebook: true,
					download: true
				}
			}));
		}
	}

	render() {
		const { router, url } = this.props;
		const { post } = this.state;
		//console.log("ebook:",post)
		return (
			<Form onSubmit={this.submitHandler}>
				<div className="row">
					<div className="col-sm-12">
						<div className="card">
							<div className="card-body">

								<div className="p-4">
									<div className="card-title d-flex align-items-center">
										<div><i className="bx bxs-info-square me-1 font-22 text-info"></i></div>
										<h5 className="mb-0 text-info">{router.context.title}</h5>
									</div>
									<hr />


									<div className="row">
										<div className="col-sm-6">
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="name">Name (ID):</label>
												<div className="col-sm-9"><input type="text" name="name" id="name" value={post.name} maxLength="255" className="form-control" onChange={(event) => this.setValue(event)} /></div>
											</div>

										</div>
										<div className="col-sm-6">
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="name_en">Name (EN):</label>
												<div className="col-sm-9"><input type="text" name="name_en" id="name_en" value={post.name_en} maxLength="255" className="form-control" onChange={(event) => this.setValue(event)} /></div>
											</div>

										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>


				<div className="row">
					<div className="col-sm-12">
						<div className="card">
							<div className="card-body">

								<div className="row">
									<div className="col-sm-6"><div className="row mb-3">
										<label className="col-sm-3 col-form-label" htmlFor="file_type">File Type:</label>
										<div className="col-sm-9"><select name="file_type" id="file_type" className="form-select" onChange={(event) => this.setValue(event)} value={post.file_type}>
											{VARS.jenis_file_file_type.map(rs => (
												<option key={rs.key} value={rs.key}>{rs.label}</option>
											))}
										</select></div>
									</div>
										<div className="row mb-3">
											<label className="col-sm-3 col-form-label" htmlFor="view_type">View Type:</label>
											<div className="col-sm-9"><select name="view_type" id="view_type" className="form-select" onChange={(event) => this.setValue(event)} value={post.view_type}>
												{VARS.jenis_file_view_type.map(rs => (
													<option key={rs.key} value={rs.key}>{rs.label}</option>
												))}
											</select></div>
										</div>
									</div>
									<div className="col-sm-6">
										<div className="row">
											<div className="col-sm-6">
												<div className="row mb-3">
													<label className="col-sm-3 col-form-label" htmlFor="ebook">Ebook:</label>
													<div className="col-sm-9">
														<input type="checkbox" name="ebook" id="ebook" className="form-check-input" value="1" checked={post.ebook ? true : false} onChange={(event) => this.setValue(event)} />
													</div>
												</div>
												<div className="row mb-3">
													<label className="col-sm-3 col-form-label" htmlFor="download">Download:</label>
													<div className="col-sm-9">
														<input type="checkbox" name="download" id="download" className="form-check-input" value="1" checked={post.download ? true : false} onChange={(event) => this.setValue(event)} />
													</div>
												</div>

											</div>
											<div className="col-sm-6">
												<div className="row mb-3">
													<label className="col-sm-3 col-form-label" htmlFor="no">No.:</label>
													<div className="col-sm-9">
														<input type="checkbox" name="no" id="no" className="form-check-input" value="1" checked={post.no ? true : false} onChange={(event) => this.setValue(event)} />
													</div>
												</div>
												<div className="row mb-3">
													<label className="col-sm-3 col-form-label" htmlFor="tanggal">Tanggal:</label>
													<div className="col-sm-9">
														<input type="checkbox" name="tanggal" id="tanggal" className="form-check-input" value="1" checked={post.tanggal ? true : false} onChange={(event) => this.setValue(event)} />
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>


							</div>
						</div>
					</div>
				</div>


				<button type="submit" className="btn btn-success radius-30 mt-2 mt-lg-0" name="btnSave" id="btnSave">
					<i className="bx bx-save" />
					Save
				</button>
				<button className="btn btn-info radius-30 mt-2 mt-lg-0 ms-1" type="button" onClick={() => router.navigate(url)}>
					<i className="bx bx-chevron-left" />
					Back
				</button>
				{this.state.jenis_file_id !== '' && post.created !== undefined ? <OverlayTrigger
					key="top"
					placement="top"
					overlay={
						<Tooltip id={`tooltip-top`}>
							<table>
								<tbody>
									<tr>
										<td nowrap="nowrap">Created</td>
										<td>:</td>
										<td nowrap="nowrap">{MIB.dateTimeFormat(post.created)}</td>
									</tr>
									<tr>
										<td nowrap="nowrap">Created by</td>
										<td>:</td>
										<td nowrap="nowrap">{post.createdby}</td>
									</tr>
									<tr>
										<td nowrap="nowrap">Updated</td>
										<td>:</td>
										<td nowrap="nowrap">{MIB.dateTimeFormat(post.updated)}</td>
									</tr>
									<tr>
										<td nowrap="nowrap">Updated by</td>
										<td>:</td>
										<td nowrap="nowrap">{post.updatedby}</td>
									</tr>
								</tbody>

							</table>
						</Tooltip>
					}
				>
					<button type="button" className="btn btn-warning radius-30 mt-2 mt-lg-0 ms-1"><i className="bx bx-history" /> History</button>
				</OverlayTrigger> : ''}

				<ToastContainer
					position="top-right"
					theme="colored"
					autoClose={2000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
			</Form>
		);
	}
}

export default MIB.withRouter(InputJenisFile);