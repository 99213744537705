/*-----------------------------------------------------------------
GENERATED BY MIB_PHP_GENERATOR VERSION 3.0
CREATED BY MISBA
EMAIL: m.m158a@gmail.com

DATE: 26-10-2022
TIME: 13:12:04

@copyright 2020 by MISBA
------------------------------------------------------------------*/




import React, { Component } from 'react';
import { Form } from "react-bootstrap";
import { ServiceUsers } from "./";
import { MIB } from "../../utils";
//import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class ChangePassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: '',
			post: {
				password: '',
				newpassword: '',
				confirmpassword: '',
                email: ''
			}

		}

		this.setValue = this.setValue.bind(this);
		ServiceUsers.setLock = this.props.router.context.setLock;
	}

	setValue(e) {
		this.setState((prevState) => ({
			...prevState,
			post: {
				...prevState.post,
				[e.target.name]: e.target.value
			}
		}))
	}

	submitHandler = async (e) => {
		e.preventDefault();
		const { router } = this.props;
		let url = router.context.url_dashboard;
		let data;
		let msg = '';
		let post = this.state.post;
		let jwt = MIB.extractToken();
		//console.log(jwt);
		post.email = jwt.json.user_name;

		let error = true;
		let err_msg = '';
		if (post.password === '') err_msg = 'Password harus diisi';
		else if (post.newpassword === '') err_msg = 'Password baru harus diisi';
		else if (post.confirmpassword === '') err_msg = 'Konfirmasi password harus diisi';
		else if (post.newpassword !== post.confirmpassword) err_msg = 'Password baru dan konfirmasi password tidak sama';
		else error = false;

		if (error) {
			toast.error(err_msg);
			return;
		}


		let send = {
			email: post.email,
			password: post.password,
			newPassword: post.newpassword
		};
		data = await ServiceUsers.changePassword(send);
		let rs = data.data;
		if (rs.status) {
			//Swal.fire('Success', rs.message, 'success');
			//toast.success(rs.message);
			let state = { toast: { type: 'success', message: msg } }
			router.navigate(url, { state });
		} else {
			//Swal.fire('Error', rs.message, 'error');
			//toast.error(rs.message);
			MIB.errorToken(rs.message);
		}
	}

	async componentDidMount() {
		
	}

	render() {
		const { router } = this.props;
		let url = router.context.url_dashboard;
		//console.log("url:",this.props.router.context.url_dashboard)
		const { post } = this.state;
		//let jwt = MIB.extractToken();
        //console.log(jwt);

		return (
			<Form onSubmit={this.submitHandler}>
				<div className="row">
					<div className="col-sm-12">
						<div className="card">
							<div className="card-body">
								<div className="p-4">
									<div className="card-title d-flex align-items-center">
										<div><i className="bx bxs-info-square me-1 font-22 text-info"></i></div>
										<h5 className="mb-0 text-info">{router.context.title}</h5>
									</div>
									<hr />

											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="password">Password lama:</label>
												<div className="col-sm-9"><input type="password" name="password" id="password" value={post.password} className="form-control" onChange={(event) => this.setValue(event)} /></div>
											</div>
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="newpassword">Password Baru:</label>
												<div className="col-sm-9"><input type="password" name="newpassword" id="newpassword" value={post.newpassword} className="form-control" onChange={(event) => this.setValue(event)} /></div>
											</div>
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="confirmpassword">Konfirmasi Password:</label>
												<div className="col-sm-9"><input type="password" name="confirmpassword" id="confirmpassword" value={post.confirmpassword} className="form-control" onChange={(event) => this.setValue(event)} /></div>
											</div>



								</div>

							</div>
						</div>
					</div>
				</div>


				<button type="submit" className="btn btn-success radius-30 mt-2 mt-lg-0" name="btnSave" id="btnSave">
					<i className="bx bx-save" />
					Save
				</button>
				<button className="btn btn-info radius-30 mt-2 mt-lg-0 ms-1" type="button" onClick={() => router.navigate(url)}>
					<i className="bx bx-x" />
					Cancel
				</button>

				<ToastContainer
					position="top-right"
					theme="colored"
					autoClose={2000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
			</Form>
		);
	}
}

export default MIB.withRouter(ChangePassword);