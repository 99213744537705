import { Fragment } from "react";
import { MIB } from "./";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
//import axios from "axios";
import { toast } from "react-toastify";
import { useState } from "react";
import Swal from 'sweetalert2';

function FileInput(props) {
    const { name, id, value, onChange } = props;
    //console.log("value:",value)
    if (value === "")
        return (
            <Fragment>
                <input type="hidden" name={name} id={id} value={value} onChange={(event) => onChange(event)} />
                <input type="file" filetype="doc" name={`f_${name}`} id={`f_${id}`} className="form-control" onChange={(event) => onChange(event, name)} />
            </Fragment>
        )
    else
        return (
            <Fragment>
                <input type="hidden" name={name} id={id} value={value} onChange={(event) => onChange(event)} />
                <input type="file" filetype="doc" name={`f_${name}`} id={`f_${id}`} className="form-control" onChange={(event) => onChange(event, name)} />
                <br />
                <a href={value} target="_blank">{MIB.basename(value)}</a>
            </Fragment>
        )
}

function ImageInput(props) {
    const { name, id, value, content, onChange } = props;
    if (value === "")
        return (
            <Fragment>
                <input type="hidden" name={name} id={id} value={value} onChange={(event) => onChange(event)} />
                <input type="file" filetype="img" accept=".jpg,.png,.gif,.jpeg" name={`f_${name}`} id={`f_${id}`} className="form-control" onChange={(event) => onChange(event)} />
            </Fragment>
        )
    else {
        let src = content;
        return (
            <Fragment>
                <input type="hidden" name={name} id={id} value={value} onChange={(event) => onChange(event)} />
                <input type="file" filetype="doc" name={`f_${name}`} id={`f_${id}`} className="form-control" onChange={(event) => onChange(event)} />
                <br />
                <img src={src} width={100} />
            </Fragment>
        )
    }


}

function Paging(props) {
    const { pagination, setPage } = props;
    if (pagination === null) return '';
    else if (pagination.num_pages <= 1) return '';
    let pageInRow = 10;
    let pengali = Math.floor(pagination.page / pageInRow);
    let start = pengali * pageInRow
    let sisa = pagination.page % pageInRow;
    start = sisa < pageInRow / 2 ? start : start + sisa - 2
    let end = start + pageInRow;
    end = end > pagination.num_pages ? pagination.num_pages : end;
    //console.log("page:",pagination.page,"sisa:",sisa,"start:",start,"end:",end,"num_pages:",pagination.num_pages);
    return (
        <nav>
            <br />
            <ul className="pagination">
                <li className={'page-item' + (pagination.page === 0 ? ' disabled' : '')}><a className="page-link" aria-label="Previous" onClick={e => setPage(e, 0)}><i className="lni lni-angle-double-left" /></a></li>
                <li className={'page-item' + (pagination.page === 0 ? ' disabled' : '')}><a className="page-link" aria-label="Previous" onClick={e => setPage(e, pagination.page - 1)}><i className="lni lni-chevron-left" /></a></li>
                {(() => {
                    const li = [];
                    for (let i = start; i < end; i++) {
                        if (i < 0 || i >= pagination.num_pages)
                            break;
                        li.push(<li className={'page-item' + (i === pagination.page ? ' active' : '')} key={'page' + i}><a className="page-link" onClick={e => setPage(e, i)}>{i + 1}</a></li>);
                        start++;
                    }
                    return li;
                })()}
                <li className={'page-item' + (pagination.page === pagination.num_pages - 1 ? ' disabled' : '')}><a className="page-link" aria-label="Previous" onClick={e => setPage(e, pagination.page + 1)}><i className="lni lni-chevron-right" /></a></li>
                <li className={'page-item' + (pagination.page === pagination.num_pages - 1 ? ' disabled' : '')}><a className="page-link" aria-label="Previous" onClick={e => setPage(e, pagination.num_pages - 1)}><i className="lni lni-angle-double-right" /></a></li>
            </ul>
        </nav>
    );
}

function TdImg(props) {
    let url_path = process.env.REACT_APP_UPLOAD_PATH;
    const { src, width } = props;
    if (width === undefined) width = 50;
    if (src === undefined || src === "") return '';
    else return <a href={`${url_path}/${src}`} target="_blank"><img src={`${url_path}/${src}`} width={width} /></a>;
}

function TdFile(props) {
    let url_path = process.env.REACT_APP_UPLOAD_PATH;
    const { href } = props;
    if (href === undefined || href === "") return '';
    else return <a href={`${url_path}/${href}`} target="_blank">{MIB.basename(href)}</a>
}

function HTMLEditor(props) {
    const { value, parent, name } = props;
    //const quillRef = parent.objQuill[name];
    //console.log("name:",name);
    //console.log("quillRef:",quillRef);

    const modules = {
        toolbar: [
            [{ 'font': [] }, { 'header': [1, 2, false] }, { 'align': [] }],
            ['bold', 'italic', { 'color': [] }, 'underline', 'strike', 'blockquote'],
            //[{'color':['green','blue','red','orange','violet','#d0d1d2']}]
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image'],
            ['clean']
        ]
    };

    const formats = [
        'font', 'header', 'align',
        'bold', 'italic', 'color', 'underline', 'strike', 'blockquote',
        //'color',
        'list', 'bullet', 'indent',
        'link', 'image'
    ];

    return <ReactQuill
        ref={(el) => { parent.setRef(el, name) }}
        theme="snow"
        modules={modules}
        formats={formats}
        value={value}
        onChange={(html) => parent.setContent(html, name)}>
    </ReactQuill>
}




function UploadFile(props) {
    const { name, accept, url, saveTo, changeState, value, size } = props;
    const [show, setShow] = useState(false);
    //console.log('show:',show);
    //value = value===null?'':value;
    //console.log(size);
    //if(size === undefined)
    //size = 0;
    //console.log(size);
    let max_size = 0;
    if (size !== undefined) {
        max_size = size;
    }
    //console.log(max_size);

    const fileType = {
        image: ".jpg,.jpeg,.png,.gif",
        document: ".pdf,.doc,.docx,.ppt,.pptx,.xls,.xlsx"
    };

    let title = "Choose File";
    let icon = "bx bx-file";
    if (accept === "image") {
        title = "Choose Image";
        icon = "bx bx-image";
    } else if (accept === "document") {
        title = "Choose Document";
        icon = "bx bx-envelope-open";
    }

    let pAccept = accept;
    if (fileType[accept] !== undefined) {
        pAccept = fileType[accept];
    } else if (accept == "") {
        pAccept = "";
    }

    function showForm() {
        const selectedFile = document.getElementById(`file_${name}`);
        selectedFile.click();
    }

    function getBase64(file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            //console.log(file.size);
            //console.log(size);
            let mx_size = max_size * 1024 * 1024;
            if (mx_size > 0 && mx_size < file.size) {
                toast.error("file maximal " + max_size + " mb");
                document.getElementById(`file_${name}`).value = "";
                return;
            }
            let cbase64 = reader.result.split(",");
            cbase64 = cbase64[1];
            const e = {
                target: {
                    name: name,
                    value: cbase64
                }
            };
            changeState(e);
            //document.getElementById(name).value = cbase64;
            //if (accept === 'image'){
            //    document.getElementById(`img_${name}`).src = reader.result;
            //}

        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    function deleteFile() {
        Swal.fire({
            title: 'Anda yakin?',
            text: "Hapus file ini!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya, hapus!'
        }).then(async (result) => {
            if (result.isConfirmed) {

                const e = {
                    target: {
                        name: name,
                        value: ''
                    }
                };
                changeState(e);
            }
        });

    }

    function showImage() {
        setShow(true);
    }

    async function onChangeHandle(e) {
        const selectedFile = e.target.files;
        if (selectedFile.length > 0) {
            const file = selectedFile[0];
            //console.log(file);
            const fname = file.name;
            if (pAccept != "") {
                let exts = pAccept.replaceAll(".", "").split(",");
                let extchoose = fname.substring(fname.lastIndexOf('.') + 1).toLowerCase();
                let sts = false;
                for (let i in exts) {
                    //console.log(exts[i]+' === '+extchoose)
                    if (exts[i] === extchoose) {
                        sts = true;
                    }
                }
                if (!sts) {
                    toast.error("File tidak valid");
                    return;
                }
            }

            getBase64(file);

            /*const formData = new FormData();
            formData.append("file", file);
            formData.append("saveTo", saveTo);
            MIB.getHeader();
            const data = await axios.post(url, formData).catch(err => { console.log("error2:", err.message) });
            //console.log("data:",data);
            const rs = data.data;
            if (rs.status) {
                const e = {
                    target: {
                        name: name,
                        value: rs.file_name
                    }
                };
                changeState(e);
            }*/
            //console.log(data);
        }
    }

    let url_path = process.env.REACT_APP_UPLOAD_PATH;
    let src = '';
    if (accept === 'image') {
        src = 'data:image/png;base64,' + value;
    } else {
        src = `${url_path}/${value}`;
    }
    //console.log(src);
    let val = value === null ? '' : value;
    let valDisplay = !show ? 'none' : 'block';
    let valClassName = !show ? 'modal fade' : 'modal fade show';

    let htmlImgShow = <><a onClick={() => setShow(true)}><img id={`img_${name}`} className="mt-3" style={{ display: 'block' }} src={src} width="100" /></a>
        <div className={valClassName} id={`div_img_${name}`} tabIndex={-1} aria-hidden="true" style={{ display: valDisplay }}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="btn-close" onClick={() => setShow(false)} aria-label="Close" />
                    </div>
                    <div className="modal-body"><img id={`img_lg_${name}`} className="mt-3" style={{ display: 'block' }} src={src} width="100%" /></div>
                </div>
            </div>
        </div></>;

    return <>
        <input type="hidden" name={name} id={name} value={val} />
        <input type="file" name={`file_${name}`} id={`file_${name}`} onChange={(e) => onChangeHandle(e)} style={{ display: 'none' }} accept={pAccept} />
        <button type="button" className="btn btn-outline-primary" onClick={showForm} title={title}><i className={icon} /></button>
        {val !== '' ? <br /> : ''}
        {val !== '' ? (
            accept === "image" ? htmlImgShow : <p><br /><button type="button" className="btn btn-outline-success"><i className="bx bx-check-circle"></i></button></p>
        ) : ""}
        {val !== '' ? <><br /><button type="button" className="btn btn-danger btn-sm" onClick={deleteFile}><i className="bx bx-trash" /> Delete</button></> : ''}
        {/*<a className="mt-3" style={{ display: 'block' }} href={`${url_path}/${value}`} target="_blank">{MIB.basename(value)}</a>*/}
    </>
}

export { FileInput, ImageInput, Paging, TdImg, TdFile, HTMLEditor, UploadFile }