/*-----------------------------------------------------------------
GENERATED BY MIB_PHP_GENERATOR VERSION 3.0
CREATED BY MISBA
EMAIL: m.m158a@gmail.com

DATE: 01-09-2022
TIME: 09:23:41

@copyright 2020 by MISBA
------------------------------------------------------------------*/




import axios from "axios";
import { MIB } from "../../utils";

const ServiceMenus = {
    setLock: null,
    getHeader: function () {
        const valid = MIB.getSession();
        const appKey = process.env.REACT_APP_APP_KEY;

        axios.defaults.headers.common['Content-Type'] = 'application/json';
        axios.defaults.headers.common['App-Key'] = appKey;
        axios.defaults.headers.common['Jwt-Valid'] = valid.jwt_valid;
        axios.defaults.headers.common['Jwt-Token'] = valid.jwt_token;
    },
    getParent: async function (type) {
        MIB.getHeader();
        const url = process.env.REACT_APP_API_URL + "/menu/getListParentsByType/"+type;//?page=0&limit=10000&field_sort=id&sort_by=0&keyword=";
        //console.log(url);
        this.setLock(true);
        const data = await axios.get(url).catch((err) => {
            console.log("error2:", err.message);
        });
        this.setLock(false);
        //console.log(data.data);
        return data;
    },
    get: async function (queryString) {
        MIB.getHeader();
        const add = queryString === undefined || queryString === '' ? '' : '?' + queryString;
        const url = process.env.REACT_APP_API_URL + "/menu/getPageable" + add;
        this.setLock(true);
        const data = await axios.get(url).catch((err) => {
            console.log("error2:", err.message);
        });
        this.setLock(false);
        return data;
    },
    getUrl: async function (queryString) {
        MIB.getHeader();
        const url = process.env.REACT_APP_API_URL + "/menu_url/getPageable?page=0&limit=10&field_sort=id&sort_by=0&keyword=" + queryString;
        //alert(url);
        this.setLock(true);
        const data = await axios.get(url).catch((err) => {
            console.log("error2:", err.message);
        });
        this.setLock(false);
        return data;
    },
    new: async function (post) {
        MIB.getHeader();
        const url = process.env.REACT_APP_API_URL + "/menu/save";
        this.setLock(true);
        const data = await axios.post(url, post).catch((err) => {
            console.log("error2:", err.message);
        });
        this.setLock(false);
        return data;
    },
    update: async function (post, menu_id) {
        MIB.getHeader();
        const url = process.env.REACT_APP_API_URL + "/menu/update";
        post.menu_id = menu_id;
        this.setLock(true);
        const data = await axios.post(url, post).catch((err) => {
            console.log("error2:", err.message);
        });
        this.setLock(false);
        return data;

    },
    detail: async function (menu_id) {
        MIB.getHeader();
        const url = process.env.REACT_APP_API_URL + "/menu/getById/" + menu_id;
        this.setLock(true);
        const data = await axios.get(url).catch((err) => {
            console.log("error2:", err.message);
        });
        this.setLock(false);
        return data;
    },
    delete: async function (menu_id) {
        MIB.getHeader();
        const url = process.env.REACT_APP_API_URL + "/menu/delete/" + menu_id;
        this.setLock(true);
        const data = await axios.get(url).catch((err) => {
            console.log("error2:", err.message);
        });
        this.setLock(false);
        return data;
    },
    deleteMultiple: async function (dels) {
        MIB.getHeader();
        const url = process.env.REACT_APP_API_URL + "/menus";
        const data = await axios.delete(url, dels).catch((err) => {
            console.log("error2:", err.message);
        });
        return data;
    }
}

export default ServiceMenus;