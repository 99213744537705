/*-----------------------------------------------------------------
GENERATED BY MIB_PHP_GENERATOR VERSION 3.0
CREATED BY MISBA
EMAIL: m.m158a@gmail.com

DATE: 26-08-2022
TIME: 11:00:48

@copyright 2020 by MISBA
------------------------------------------------------------------*/




import React, { Component } from 'react';
import { MIB } from "./utils";

import { Routes, Route } from "react-router-dom";
import Home from './views/home/Home';
import Amdash from './views/layouts/amdash/Amdash';
import Login from './views/login/Login';
import ForgotPassword from './views/forgot_password';
import Otp from './views/otp';
import { IndexCategories, ShowCategories, InputCategories } from "./views/categories";
import { IndexMenus, ShowMenus, InputMenus } from "./views/menus";
import { IndexFileUpload, ShowFileUpload, InputFileUpload } from "./views/file_upload";
import { IndexVideo, ShowVideo, InputVideo } from "./views/video";
import { IndexNews, ShowNews, InputNews, ShowUnPublishNews } from "./views/news";
import { IndexPages, ShowPages, InputPages } from "./views/pages";
import { IndexJenisFile, ShowJenisFile, InputJenisFile } from "./views/jenis_file";
import { IndexProfile, ShowProfile, InputProfile } from "./views/profile";

import { IndexBanners, ShowBanners, InputBanners } from "./views/banners";
import { IndexAchievements, ShowAchievements, InputAchievements } from "./views/achievements";
import { IndexKkksCategories, ShowKkksCategories, InputKkksCategories } from "./views/kkks_categories";
import { IndexKkksCompanies, ShowKkksCompanies, InputKkksCompanies } from "./views/kkks_companies";
import { IndexAchievementsData, ShowAchievementsData, InputAchievementsData } from "./views/achievements_data";
import { IndexCommonParameters, ShowCommonParameters, InputCommonParameters } from "./views/common_parameters";
import { IndexSubscriptions, ShowSubscriptions, InputSubscriptions } from "./views/subscriptions";
import { IndexSubscriptionsMessages, ShowSubscriptionsMessages, InputSubscriptionsMessages } from "./views/subscriptions_messages";
import { IndexOffices, ShowOffices, InputOffices } from "./views/offices";
import { IndexAreaMap, ShowAreaMap, InputAreaMap } from "./views/area_map";
import { IndexUnits, ShowUnits, InputUnits } from "./views/units";
import { IndexUsers, ShowUsers, InputUsers, ChangePassword } from "./views/users";
import { IndexSosmed, ShowSosmed, InputSosmed } from "./views/sosmed";
import { IndexPressRelease, ShowPressRelease, InputPressRelease } from "./views/press_release";
import { IndexGalleries, ShowGalleries, InputGalleries } from "./views/galleries";

import Quill from './views/Quill';


class Routers extends Component {
	render() {
		const { cookies } = this.props;
		const mib = process.env.REACT_APP_APP_PATH;
		const url = `/${mib}`;
		return (
			<Routes>
				<Route path="/" element={<Login />} />
				<Route path="/forgot-password" element={<ForgotPassword />} />
				<Route path="/otp" element={<Otp />} />
				<Route path='/quill' element={<Quill />} />
				<Route path={mib} element={<Amdash cookies={cookies} />}>
					<Route index element={<Home {...this.props} />} />
					<Route path="categories" element={<IndexCategories {...this.props} url={`${url}/categories`} />}>
						<Route index element={<ShowCategories {...this.props} url={`${url}/categories`} />} />
						<Route path="new" element={<InputCategories {...this.props} url={`${url}/categories`} />} />
						<Route path="edit/:category_id" element={<InputCategories {...this.props} url={`${url}/categories`} />} />
					</Route>
					<Route path="menus" element={<IndexMenus {...this.props} url={`${url}/menus`} />}>
						<Route index element={<ShowMenus {...this.props} url={`${url}/menus`} />} />
						<Route path="new" element={<InputMenus {...this.props} url={`${url}/menus`} />} />
						<Route path="edit/:menu_id" element={<InputMenus {...this.props} url={`${url}/menus`} />} />
					</Route>
					<Route path="file_upload" element={<IndexFileUpload {...this.props} url={`${url}/file_upload`} />}>
						<Route index element={<ShowFileUpload {...this.props} url={`${url}/file_upload`} />} />
						<Route path="new" element={<InputFileUpload {...this.props} url={`${url}/file_upload`} />} />
						<Route path="edit/:file_upload_id" element={<InputFileUpload {...this.props} url={`${url}/file_upload`} />} />
					</Route>
					<Route path="video" element={<IndexVideo {...this.props} url={`${url}/video`} />}>
						<Route index element={<ShowVideo {...this.props} url={`${url}/video`} />} />
						<Route path="new" element={<InputVideo {...this.props} url={`${url}/video`} />} />
						<Route path="edit/:video_id" element={<InputVideo {...this.props} url={`${url}/video`} />} />
					</Route>
					<Route path="news" element={<IndexNews {...this.props} url={`${url}/news`} />}>
						<Route index element={<ShowNews {...this.props} url={`${url}/news`} />} />
						<Route path="unpublish" element={<ShowUnPublishNews {...this.props} url={`${url}/news`} />} />
						<Route path="new" element={<InputNews {...this.props} url={`${url}/news`} />} />
						<Route path="edit/:news_id" element={<InputNews {...this.props} url={`${url}/news`} />} />
						<Route path="approve/:news_id" element={<InputNews {...this.props} url={`${url}/news`} />} />
					</Route>
					<Route path="pages" element={<IndexPages {...this.props} url={`${url}/pages`} />}>
						<Route index element={<ShowPages {...this.props} url={`${url}/pages`} />} />
						<Route path="new" element={<InputPages {...this.props} url={`${url}/pages`} />} />
						<Route path="edit/:page_id" element={<InputPages {...this.props} url={`${url}/pages`} />} />
					</Route>
					<Route path="jenis_file" element={<IndexJenisFile {...this.props} url={`${url}/jenis_file`} />}>
						<Route index element={<ShowJenisFile {...this.props} url={`${url}/jenis_file`} />} />
						<Route path="new" element={<InputJenisFile {...this.props} url={`${url}/jenis_file`} />} />
						<Route path="edit/:jenis_file_id" element={<InputJenisFile {...this.props} url={`${url}/jenis_file`} />} />
					</Route>
					<Route path="profile" element={<IndexProfile {...this.props} url={`${url}/profile`} />}>
						<Route index element={<ShowProfile {...this.props} url={`${url}/profile`} />} />
						<Route path="new" element={<InputProfile {...this.props} url={`${url}/profile`} />} />
						<Route path="edit/:profile_id" element={<InputProfile {...this.props} url={`${url}/profile`} />} />
					</Route>

					<Route path="banners" element={<IndexBanners {...this.props} url={`${url}/banners`} />}>
						<Route index element={<ShowBanners {...this.props} url={`${url}/banners`} />} />
						<Route path="new" element={<InputBanners {...this.props} url={`${url}/banners`} />} />
						<Route path="edit/:id" element={<InputBanners {...this.props} url={`${url}/banners`} />} />
					</Route>
					<Route path="achievements" element={<IndexAchievements {...this.props} url={`${url}/achievements`} />}>
						<Route index element={<ShowAchievements {...this.props} url={`${url}/achievements`} />} />
						<Route path="new" element={<InputAchievements {...this.props} url={`${url}/achievements`} />} />
						<Route path="edit/:id" element={<InputAchievements {...this.props} url={`${url}/achievements`} />} />
					</Route>
					<Route path="kkks_categories" element={<IndexKkksCategories {...this.props} url={`${url}/kkks_categories`} />}>
						<Route index element={<ShowKkksCategories {...this.props} url={`${url}/kkks_categories`} />} />
						<Route path="new" element={<InputKkksCategories {...this.props} url={`${url}/kkks_categories`} />} />
						<Route path="edit/:id" element={<InputKkksCategories {...this.props} url={`${url}/kkks_categories`} />} />
					</Route>
					<Route path="kkks_companies" element={<IndexKkksCompanies {...this.props} url={`${url}/kkks_companies`} />}>
						<Route index element={<ShowKkksCompanies {...this.props} url={`${url}/kkks_companies`} />} />
						<Route path="new" element={<InputKkksCompanies {...this.props} url={`${url}/kkks_companies`} />} />
						<Route path="edit/:id" element={<InputKkksCompanies {...this.props} url={`${url}/kkks_companies`} />} />
					</Route>
					<Route path="achievements_data" element={<IndexAchievementsData {...this.props} url={`${url}/achievements_data`} />}>
						<Route index element={<ShowAchievementsData {...this.props} url={`${url}/achievements_data`} />} />
						<Route path="new" element={<InputAchievementsData {...this.props} url={`${url}/achievements_data`} />} />
						<Route path="edit/:id" element={<InputAchievementsData {...this.props} url={`${url}/achievements_data`} />} />
					</Route>
					<Route path="common_parameters" element={<IndexCommonParameters {...this.props} url={`${url}/common_parameters`} />}>
						<Route index element={<ShowCommonParameters {...this.props} url={`${url}/common_parameters`} />} />
						<Route path="new" element={<InputCommonParameters {...this.props} url={`${url}/common_parameters`} />} />
						<Route path="edit/:id" element={<InputCommonParameters {...this.props} url={`${url}/common_parameters`} />} />
					</Route>


					<Route path="subscriptions" element={<IndexSubscriptions {...this.props} url={`${url}/subscriptions`} />}>
						<Route index element={<ShowSubscriptions {...this.props} url={`${url}/subscriptions`} />} />
						<Route path="new" element={<InputSubscriptions {...this.props} url={`${url}/subscriptions`} />} />
						<Route path="edit/:id" element={<InputSubscriptions {...this.props} url={`${url}/subscriptions`} />} />
					</Route>
					<Route path="subscriptions_messages" element={<IndexSubscriptionsMessages {...this.props} url={`${url}/subscriptions_messages`} />}>
						<Route index element={<ShowSubscriptionsMessages {...this.props} url={`${url}/subscriptions_messages`} />} />
						<Route path="new" element={<InputSubscriptionsMessages {...this.props} url={`${url}/subscriptions_messages`} />} />
						<Route path="edit/:id" element={<InputSubscriptionsMessages {...this.props} url={`${url}/subscriptions_messages`} />} />
					</Route>
					<Route path="offices" element={<IndexOffices {...this.props} url={`${url}/offices`} />}>
						<Route index element={<ShowOffices {...this.props} url={`${url}/offices`} />} />
						<Route path="new" element={<InputOffices {...this.props} url={`${url}/offices`} />} />
						<Route path="edit/:id" element={<InputOffices {...this.props} url={`${url}/offices`} />} />
					</Route>
					<Route path="area_map" element={<IndexAreaMap {...this.props} url={`${url}/area_map`} />}>
						<Route index element={<ShowAreaMap {...this.props} url={`${url}/area_map`} />} />
						<Route path="new" element={<InputAreaMap {...this.props} url={`${url}/area_map`} />} />
						<Route path="edit/:id" element={<InputAreaMap {...this.props} url={`${url}/area_map`} />} />
					</Route>
					<Route path="units" element={<IndexUnits {...this.props} url={`${url}/units`} />}>
						<Route index element={<ShowUnits {...this.props} url={`${url}/units`} />} />
						<Route path="new" element={<InputUnits {...this.props} url={`${url}/units`} />} />
						<Route path="edit/:id" element={<InputUnits {...this.props} url={`${url}/units`} />} />
					</Route>
					<Route path="users" element={<IndexUsers {...this.props} url={`${url}/users`} />}>
						<Route index element={<ShowUsers {...this.props} url={`${url}/users`} />} />
						<Route path="new" element={<InputUsers {...this.props} url={`${url}/users`} />} />
						<Route path="edit/:id" element={<InputUsers {...this.props} url={`${url}/users`} />} />
						<Route path="change-password" element={<ChangePassword {...this.props} url={`${url}/users`} />} />
					</Route>
					<Route path="sosmed" element={<IndexSosmed {...this.props} url={`${url}/sosmed`} />}>
						<Route index element={<ShowSosmed {...this.props} url={`${url}/sosmed`} />} />
						<Route path="new" element={<InputSosmed {...this.props} url={`${url}/sosmed`} />} />
						<Route path="edit/:id" element={<InputSosmed {...this.props} url={`${url}/sosmed`} />} />
					</Route>
					<Route path="press_release" element={<IndexPressRelease {...this.props} url={`${url}/press_release`} />}>
						<Route index element={<ShowPressRelease {...this.props} url={`${url}/press_release`} />} />
						<Route path="new" element={<InputPressRelease {...this.props} url={`${url}/press_release`} />} />
						<Route path="edit/:id" element={<InputPressRelease {...this.props} url={`${url}/press_release`} />} />
					</Route>
					<Route path="galleries" element={<IndexGalleries {...this.props} url={`${url}/galleries`} />}>
						<Route index element={<ShowGalleries {...this.props} url={`${url}/galleries`} />} />
						<Route path="new" element={<InputGalleries {...this.props} url={`${url}/galleries`} />} />
						<Route path="edit/:id" element={<InputGalleries {...this.props} url={`${url}/galleries`} />} />
					</Route>

				</Route>
			</Routes>
		);
	}
}

export default MIB.withRouter(Routers);