import axios from "axios";
import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { MIB } from "../../utils";

class ForgotPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            loading: false,
        };
    }

    submitHandler = async (e) => {
        e.preventDefault();

        const { router } = this.props;
        const { email } = this.state;
        let error = true;
        let msg = "";

        if (!email) msg = 'Silahkan lengkapi email';
        else error = false;

        if (error) {
            Swal.fire('Error', msg, 'error');
        } else {
            this.setState(prevState => ({
                ...prevState,
                loading: true
            }));
            const url = process.env.REACT_APP_API_URL + "/user/generateOtp";
            let post = {
                email: email,
                purpose: "microsite1",
                time: new Date()
            }
            const response = await axios.post(url,post);
            console.log(response);
            this.setState(prevState => ({
                ...prevState,
                loading: false
            }));
            if (response.data.status) {
                Swal.fire({
                    icon: 'success',
                    title: 'Pengajuan Perubahan Password Terkirim',
                    text: 'Silahkan cek email untuk langkah lebih lanjut',
                    showConfirmButton: false,
                    timer: 1500
                });
                let date = new Date(post.time.getTime()+(5*60000));
                post.time = date;
                const state = {
                    post
                };
                setTimeout(() => {
                    router.navigate("/otp",{state});
                }, 1500);
            } else {
                Swal.fire('Error', response.data.message, 'error');
            }

        }
    }

    render() {
        const { email, loading } = this.state;
        return (
            <div className="wrapper">
                <div className="section-authentication-signin d-flex align-items-center justify-content-center my-5 my-lg-0">
                    <div className="container-fluid">
                        <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
                            <div className="col mx-auto">
                                <div className="mb-4 text-center">
                                    <img
                                        src="/assets/images/logo-skk-migas.png"
                                        width={180}
                                        alt=""
                                    />
                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="border p-4 rounded">
                                            <div className="text-center">
                                                <h3>Lupa Kata Sandi?</h3>
                                            </div>

                                            <div className="form-body">
                                                <Form className="row g-3" onSubmit={this.submitHandler}>
                                                    <div className="col-12">
                                                        <label htmlFor="email" className="form-label">
                                                            Email
                                                        </label>
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            name="email"
                                                            id="email"
                                                            value={email}
                                                            placeholder="Masukkan email"
                                                            onChange={(e) => this.setState(prev => ({ ...prev, email: e.target.value }))}
                                                        />
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="d-grid">
                                                            <button type="submit" className="btn btn-primary">
                                                                Kirim OTP
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 text-center">
                                                        <Link to="/">
                                                            <i className="bx bx-arrow-back mr-5" />
                                                            Kembali
                                                        </Link>
                                                    </div>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {loading && (
                    <>
                        <div id="locker" className="transparent_class"></div>
                        <div id="lockwindow">
                            <div className="lds-facebook">
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    }
}

export default MIB.withRouter(ForgotPassword);
