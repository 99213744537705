/*-----------------------------------------------------------------
GENERATED BY MIB_PHP_GENERATOR VERSION 3.0
CREATED BY MISBA
EMAIL: m.m158a@gmail.com

DATE: 26-08-2022
TIME: 11:00:48

@copyright 2020 by MISBA
------------------------------------------------------------------*/




import React, { Component } from 'react';
import { Button, Form } from "react-bootstrap";
import { ServiceNews } from "./";
import { MIB, Paging, TdImg } from "../../utils";
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ServiceCategories } from "../categories";

class ShowUnPublishNews extends Component {

    defaultState = {
        data: [],
        pagination: null,
        is_published:true,
        post: {
            keyword: '',
            page: 0,
            sort_by: '0',
            field_sort: 'id',
            limit: 10,
            /*category_id: '',
            judul: '',
            judul_en: '',
            tanggal: '',
            kota: '',
            penulis: '',
            summary: '',
            summary_en: '',
            content: '',
            content_en: ''*/
        },
        //dt_categories: [],
        //switchFilter: false,
        //ckAll: false,
        //ck: []
    }

    constructor(props) {
        super(props);
        this.state = this.defaultState

        this.publish = this.publish.bind(this);
        this.setValue = this.setValue.bind(this);
        this.setPage = this.setPage.bind(this);
        ServiceCategories.setLock = this.props.router.context.setLock;
        ServiceNews.setLock = this.props.router.context.setLock;
    }

    async publish(news_id) {
        Swal.fire({
            title: 'Anda yakin?',
            text: "Publish kembali data ini!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya, Publish!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let data = await ServiceNews.publish(news_id);
                let title = "Deleted!";
                let message = "Data berhasil Publish kembali";
                let icon = "success";
                console.log(data);
                if (!data.status || data === undefined) {
                    icon = "error";
                    message = data === undefined ? "Error rest api" : data.message;
                    title = "Failed!"
                } else {
                    await this.loadData();
                }
                /*Swal.fire(
                    title,
                    message,
                    icon
                )*/
                if (icon === "error")
                    MIB.errorToken(message);
                else
                    toast.success(message);
            }
        });
    }

    /*async deleteMultiple() {
        let jml = 0;
        let del = [];
        this.state.ck.map((val, i) => {
            if (val) {
                del[jml] = this.state.data[i].news_id;
                jml++;
            }
        });

        if (jml > 0) {
            Swal.fire({
                title: 'Anda yakin?',
                text: "Hapus data yang dicentang!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ya, hapus!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const dels = { data: del };
                    console.log(dels)
                    let data = await ServiceNews.deleteMultiple(dels);
                    let title = "Deleted!";
                    let message = "Data berhasil dihapus";
                    let icon = "success";
                    if (data === undefined || !data.status) {
                        icon = "error";
                        message = data === undefined ? "Error rest api" : data.message;
                        title = "Failed!";
                    } else {
                        await this.loadData();
                    }
                    if(icon === "error")
                        toast.error(message);
                    else
                        toast.success(message);
                }
            })
        } else {
            toast.error("Ceklist minimal 1 data");
        }

    }*/

    async loadData() {
        //const { router } = this.props;
        //console.log(router);
        //const { router } = this.props;
        //const { is_published } = this.state;
        //let is_published = router.context.action==='unpublish'?false:true;
        //console.log('is_published:',is_published);
        //let [pathname] = this.props.router.location;
        //const { is_published } = this.state;
        //let is_published = router.context.action==='unpublish'?false:true;
        //console.log('pathname:',this.props.router);

        const queryString = new URLSearchParams(this.state.post).toString();
        const resp = await ServiceNews.get(queryString,false);
        if (resp !== undefined) {
            if (resp.data.status) {
                const data = resp.data.datas.content;
                const pagination = {
                    num_pages: resp.data.datas.totalPages,
                    page: resp.data.datas.pageable.pageNumber,
                    limit: resp.data.datas.pageable.pageSize,
                    num_rows: resp.data.datas.totalElements
                };
                /*const data = resp.data.data;
                const pagination = resp.data.pagination;

                let ck = [];
                for (let i = 0; i < data.length; i++) {
                    ck[i] = false;
                }*/

                this.setState((prevState) => ({
                    ...prevState,
                    data: data,
                    pagination: pagination,
                    //ck: ck
                }));
            } else {
                MIB.errorToken(resp.data.message);
            }
        } else {
            console.log(resp.data.message);
        }
    }

    async getCategories() {
        const qstring = "num_rows=1000&page=0";
        const respCategories = await ServiceCategories.get(qstring);
        if (respCategories.data.status) {
            this.setState((prevState) => ({
                ...prevState,
                dt_categories: respCategories.data.data
            }));
        } else {
            MIB.errorToken(respCategories.data.message);
        }
    }

    async componentDidMount() {
        const { router } = this.props;

        if (router.location.state !== null && router.location.state.toast !== undefined) {
            if (router.location.state.toast.type === 'error')
                toast.error(router.location.state.toast.message, { onClose: () => router.location.state = null, toastId: 'toastId' });
            else
                toast.success(router.location.state.toast.message, { onClose: () => router.location.state = null, toastId: 'toastId' });
        }
        await this.loadData();
        //await this.getCategories();
    }

    setValue(e) {
        this.setState((prevState) => ({
            ...prevState,
            post: {
                ...prevState.post,
                [e.target.name]: e.target.value
            }
        }))
    }

    /*setValueCk(e) {
        let no = parseInt(e.target.name.replace('ck_', ''));
        no = no === undefined ? 0 : no;
        let ck = this.state.ck;
        ck[no] = ck[no] ? false : true;
        this.setState((prevState) => ({
            ...prevState,
            ck: ck
        }));
    }

    checkAll(e) {
        this.setState((prevState) => ({
            ...prevState,
            ckAll: !prevState.ckAll
        }));

        let ck = [];
        for (let i = 0; i < this.state.ck.length; i++) {
            ck[i] = !this.state.ckAll;
        }

        this.setState((prevState) => ({
            ...prevState,
            ck: ck
        }));
    }

    switchFilterToggle() {
        this.setState((prevState) => ({
            ...prevState,
            switchFilter: !prevState.switchFilter
        }));
    }*/

    async refresh() {
        await this.setState(this.defaultState);
        await this.loadData();
        //await this.getCategories();
    }

    async setPage(e, page) {
        e.preventDefault();
        await this.setState((prevState) => ({
            ...prevState,
            post: {
                ...prevState.post,
                page: page
            }
        }));
        await this.loadData();
    }

    async sortBy(field_sort) {
        let sort_by = '0';
        if (field_sort === this.state.post.field_sort) {
            if (this.state.post.sort_by === '1')
                sort_by = '0';
            else
                sort_by = '1';
        }
        await this.setState((prevState) => ({
            ...prevState,
            post: {
                ...prevState.post,
                sort_by: sort_by,
                field_sort: field_sort
            }
        }));
        await this.loadData();
    }

    async goOnEnter(e) {
        //console.log(e.key)
        if (e.key === 'Enter') {
            await this.loadData();
        }
    }


    render() {
        const { router } = this.props;
        const url = router.context.url;
        //const { data, pagination, post, dt_categories, switchFilter, ck } = this.state;
        const { data, pagination, post, dt_categories } = this.state;

        return (
            <Form onSubmit={(e) => e.preventDefault()}>
                <input type="hidden" name="page" id="page" value={post.page} onChange={(event) => this.setValue(event)} />
                <input type="hidden" name="sort_by" id="sort_by" value={post.sort_by} onChange={(event) => this.setValue(event)} />
                <input type="hidden" name="field_sort" id="field_sort" value={post.field_sort} onChange={(event) => this.setValue(event)} />

                <div className="row">
                    <div className="col-sm-12">
                        <div className="card m-b-30">
                            <div className="card-body">

                                <div className="d-lg-flex align-items-center mb-4 gap-3">
                                    <div className="position-relative">
                                        <input
                                            type="text"
                                            id="keyword"
                                            name="keyword"
                                            className="form-control ps-5 radius-30"
                                            placeholder="Type keyword, then enter"
                                            value={post.keyword}
                                            onChange={(event) => this.setValue(event)}
                                            onKeyUpCapture={e => this.goOnEnter(e)} />
                                        <span className="position-absolute top-50 product-show translate-middle-y"><i className="bx bx-search"></i></span>
                                    </div>
                                    <div className="ms-auto">
                                        <div className="d-flex">
                                            {/*<Button variant="success" size="sm" type="button" className="radius-30 mt-2 mt-lg-0" onClick={() => this.switchFilterToggle()} ><i className="bx bx-search" />Filter</Button>
									<Button variant="danger" size="sm" type="button" className="radius-30 mt-2 mt-lg-0 ms-2" onClick={() => this.deleteMultiple()}><i className="bx bx-trash" />Delete</Button>*/}
                                            <Button variant="info" size="sm" type="button" className="radius-30 mt-2 mt-lg-0 ms-2" onClick={() => this.refresh()}><i className="bx bx-refresh" />Refresh</Button>

                                        </div>
                                    </div>
                                </div>

                                <div className="table-responsive">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                {/*<th><input className="form-check-input me-3" type="checkbox" aria-label="..." onClick={(e) => this.checkAll(e)} /></th>*/}
                                                <th>Company <a onClick={() => this.sortBy('company')} className={`radius-30 mt-2 mt-lg-0 ms-2` + (post.field_sort === 'company' ? ' btn-danger' : ' btn-primary')}> <i className={`bx ` + (post.field_sort === 'company' && post.sort_by === '1' ? 'bx-down-arrow' : 'bx-up-arrow')} /></a></th>
                                                <th>Category <a onClick={() => this.sortBy('categories.category')} className={`radius-30 mt-2 mt-lg-0 ms-2` + (post.field_sort === 'categories.category' ? ' btn-danger' : ' btn-primary')}> <i className={`bx ` + (post.field_sort === 'categories.category' && post.sort_by === '1' ? 'bx-down-arrow' : 'bx-up-arrow')} /></a></th>
                                                <th>Code <a onClick={() => this.sortBy('news.code')} className={`radius-30 mt-2 mt-lg-0 ms-2` + (post.field_sort === 'news.code' ? ' btn-danger' : ' btn-primary')}> <i className={`bx ` + (post.field_sort === 'news.code' && post.sort_by === '1' ? 'bx-down-arrow' : 'bx-up-arrow')} /></a></th>
                                                <th>Judul (ID)<a onClick={() => this.sortBy('news.judul')} className={`radius-30 mt-2 mt-lg-0 ms-2` + (post.field_sort === 'news.judul' ? ' btn-danger' : ' btn-primary')}> <i className={`bx ` + (post.field_sort === 'news.judul' && post.sort_by === '1' ? 'bx-down-arrow' : 'bx-up-arrow')} /></a></th>
                                                <th>Judul (EN) <a onClick={() => this.sortBy('news.judul_en')} className={`radius-30 mt-2 mt-lg-0 ms-2` + (post.field_sort === 'news.judul_en' ? ' btn-danger' : ' btn-primary')}> <i className={`bx ` + (post.field_sort === 'news.judul_en' && post.sort_by === '1' ? 'bx-down-arrow' : 'bx-up-arrow')} /></a></th>
                                                <th>Tanggal <a onClick={() => this.sortBy('news.tanggal')} className={`radius-30 mt-2 mt-lg-0 ms-2` + (post.field_sort === 'news.tanggal' ? ' btn-danger' : ' btn-primary')}> <i className={`bx ` + (post.field_sort === 'news.tanggal' && post.sort_by === '1' ? 'bx-down-arrow' : 'bx-up-arrow')} /></a></th>
                                                {/*<th>Gambar <a onClick={()=>this.sortBy('news.img_gambar')} className={`radius-30 mt-2 mt-lg-0 ms-2`+(post.field_sort==='news.img_gambar'?' btn-danger':' btn-primary')}> <i className={`bx `+(post.field_sort==='news.img_gambar'&&post.sort_by==='1'?'bx-down-arrow':'bx-up-arrow')} /></a></th>*/}
                                                <th>Kota <a onClick={() => this.sortBy('news.kota')} className={`radius-30 mt-2 mt-lg-0 ms-2` + (post.field_sort === 'news.kota' ? ' btn-danger' : ' btn-primary')}> <i className={`bx ` + (post.field_sort === 'news.kota' && post.sort_by === '1' ? 'bx-down-arrow' : 'bx-up-arrow')} /></a></th>
                                                <th>Penulis <a onClick={() => this.sortBy('news.penulis')} className={`radius-30 mt-2 mt-lg-0 ms-2` + (post.field_sort === 'news.penulis' ? ' btn-danger' : ' btn-primary')}> <i className={`bx ` + (post.field_sort === 'news.penulis' && post.sort_by === '1' ? 'bx-down-arrow' : 'bx-up-arrow')} /></a></th>
                                                {/*<th>Summary <a onClick={()=>this.sortBy('news.summary')} className={`radius-30 mt-2 mt-lg-0 ms-2`+(post.field_sort==='news.summary'?' btn-danger':' btn-primary')}> <i className={`bx `+(post.field_sort==='news.summary'&&post.sort_by==='1'?'bx-down-arrow':'bx-up-arrow')} /></a></th>
		<th>Summary En <a onClick={()=>this.sortBy('news.summary_en')} className={`radius-30 mt-2 mt-lg-0 ms-2`+(post.field_sort==='news.summary_en'?' btn-danger':' btn-primary')}> <i className={`bx `+(post.field_sort==='news.summary_en'&&post.sort_by==='1'?'bx-down-arrow':'bx-up-arrow')} /></a></th>
		<th>Content <a onClick={()=>this.sortBy('news.content')} className={`radius-30 mt-2 mt-lg-0 ms-2`+(post.field_sort==='news.content'?' btn-danger':' btn-primary')}> <i className={`bx `+(post.field_sort==='news.content'&&post.sort_by==='1'?'bx-down-arrow':'bx-up-arrow')} /></a></th>
		<th>Content En <a onClick={()=>this.sortBy('news.content_en')} className={`radius-30 mt-2 mt-lg-0 ms-2`+(post.field_sort==='news.content_en'?' btn-danger':' btn-primary')}> <i className={`bx `+(post.field_sort==='news.content_en'&&post.sort_by==='1'?'bx-down-arrow':'bx-up-arrow')} /></a></th>*/}
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((rs, no) => {
                                                return (
                                                    <tr key={rs.news_id}>
                                                        {/*<td><input className="form-check-input me-3" type="checkbox" name={`ck_${no}`} id={`ck_${no}`} checked={ck[no] ? true : false} onChange={(event) => this.setValueCk(event)} value={rs.news_id} /></td>*/}
                                                        <td>{rs.company}</td>
                                                        <td>{rs.category}</td>
                                                        <td>{rs.code}</td>
                                                        <td>{rs.judul}</td>
                                                        <td>{rs.judul_en}</td>
                                                        <td>{MIB.dateTimeFormat(rs.tanggal)}</td>
                                                        {/*<td>
		<TdImg src={rs.img_gambar} width={50} /></td>*/}
                                                        <td>{rs.kota}</td>
                                                        <td>{rs.penulis}</td>
                                                        {/*<td>{rs.summary}</td>
		<td>{rs.summary_en}</td>
		<td>{rs.content}</td>
    <td>{rs.content_en}</td>*/}
                                                        <td>
                                                            <a className="btn-warning btn-sm radius-30 mt-2 mt-lg-0" onClick={() => this.publish(rs.news_id)}><i className="bx bx-check" /> Publish</a>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>

                                </div>

                                <Paging pagination={pagination} setPage={this.setPage} />







                            </div>
                        </div>
                    </div>
                </div>


                {/*<div className={'switcher-wrapper' + (switchFilter ? ' switcher-toggled' : '')}>
				<div className="switcher-header">
					<div className="d-flex align-items-center">
						<h5 className="mb-0 text-uppercase"><i className="bx bx-search"></i> Filters</h5>
						<button type="button" className="btn-close ms-auto close-switcher" aria-label="Close" onClick={() => this.switchFilterToggle()} />
					</div>
				</div>
				<div className="switcher-body">

	<div className="mb-3">
		<label className="form-label" htmlFor="category_id">Category:</label>
		<select name="category_id" id="category_id" className="form-select" onChange={(event) => this.setValue(event)} value={post.category_id}>
		<option value=""></option>
		{dt_categories.map(rs=>(
			<option key={rs.category_id} value={rs.category_id}>{rs.category}</option>
		))}
		</select>
	</div>
	<div className="mb-3">
		<label className="form-label" htmlFor="judul">Judul (ID):</label>
		<input type="text" name="judul" id="judul" value={post.judul} maxLength="255" className="form-control" onChange={(event) => this.setValue(event)} />
	</div>
	<div className="mb-3">
		<label className="form-label" htmlFor="judul_en">Judul (EN):</label>
		<input type="text" name="judul_en" id="judul_en" value={post.judul_en} maxLength="255" className="form-control" onChange={(event) => this.setValue(event)} />
	</div>
	<div className="mb-3">
		<label className="form-label" htmlFor="tanggal">Tanggal:</label>
		<input type="datetime-local" name="tanggal" id="tanggal" value={post.tanggal.substring(0,16)}  className="form-control" onChange={(event) => this.setValue(event)} />
	</div>
	<div className="mb-3">
		<label className="form-label" htmlFor="kota">Kota:</label>
		<input type="text" name="kota" id="kota" value={post.kota} maxLength="255" className="form-control" onChange={(event) => this.setValue(event)} />
	</div>
	<div className="mb-3">
		<label className="form-label" htmlFor="penulis">Penulis:</label>
		<input type="text" name="penulis" id="penulis" value={post.penulis} maxLength="255" className="form-control" onChange={(event) => this.setValue(event)} />
	</div>
	<div className="mb-3">
		<label className="form-label" htmlFor="summary">Summary (ID):</label>
		<input type="text" name="summary" id="summary" value={post.summary} maxLength="200" className="form-control" onChange={(event) => this.setValue(event)} />
	</div>
	<div className="mb-3">
		<label className="form-label" htmlFor="summary_en">Summary (EN):</label>
		<input type="text" name="summary_en" id="summary_en" value={post.summary_en} maxLength="200" className="form-control" onChange={(event) => this.setValue(event)} />
	</div>
	<div className="mb-3">
		<label className="form-label" htmlFor="content">Content (ID):</label>
		<input type="text" name="content" id="content" value={post.content} maxLength="7000" className="form-control" onChange={(event) => this.setValue(event)} />
	</div>
	<div className="mb-3">
		<label className="form-label" htmlFor="content_en">Content (EN):</label>
		<input type="text" name="content_en" id="content_en" value={post.content_en} maxLength="7000" className="form-control" onChange={(event) => this.setValue(event)} />
	</div>
    		

				</div>
				<div className="switcher-footer">
					<Button variant="danger" size="sm" type="button" className="radius-30 mt-2 mt-lg-0" onClick={() => this.loadData()} ><i className="bx bx-search" />Apply</Button>
				</div>
        </div>		*/}


                <ToastContainer
                    position="top-right"
                    theme="colored"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />

            </Form>
        );
    }
}


export default MIB.withRouter(ShowUnPublishNews);


