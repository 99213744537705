/*-----------------------------------------------------------------
GENERATED BY MIB_PHP_GENERATOR VERSION 3.0
CREATED BY MISBA
EMAIL: m.m158a@gmail.com

DATE: 10-10-2022
TIME: 01:32:29

@copyright 2020 by MISBA
------------------------------------------------------------------*/




import axios from "axios";
import { MIB } from "../../utils";

const ServiceBanners = {
    setLock: null,
    getHeader: function () {
        const valid = MIB.getSession();
        const appKey = process.env.REACT_APP_APP_KEY;

        axios.defaults.headers.common['Content-Type'] = 'application/json';
        axios.defaults.headers.common['App-Key'] = appKey;
        axios.defaults.headers.common['Jwt-Valid'] = valid.jwt_valid;
        axios.defaults.headers.common['Jwt-Token'] = valid.jwt_token;
    },
    get: async function (queryString) {
        MIB.getHeader();
        const add = queryString === undefined || queryString === '' ? '' : '?' + queryString;
        const url = process.env.REACT_APP_API_URL + "/banner/getPageable" + add;
        this.setLock(true);
        const data = await axios.get(url).catch((err) => {
            console.log("error2:", err.message);
        });
        this.setLock(false);
        return data;
    },
    new: async function (post) {
        MIB.getHeader();
        const url = process.env.REACT_APP_API_URL + "/banner/save";
        this.setLock(true);
        const data = await axios.post(url, post).catch((err) => {
            console.log("error2:", err.message);
        });
        this.setLock(false);
        return data;
    },
    update: async function (post, id) {
        MIB.getHeader();
        const url = process.env.REACT_APP_API_URL + "/banner/update";
        post.id = id;
        this.setLock(true);
        const data = await axios.post(url, post).catch((err) => {
            console.log("error2:", err.message);
        });
        this.setLock(false);
        return data;

    },
    detail: async function (id) {
        MIB.getHeader();
        const url = process.env.REACT_APP_API_URL + "/banner/getById/" + id;
        this.setLock(true);
        const data = await axios.get(url).catch((err) => {
            console.log("error2:", err.message);
        });
        this.setLock(false);
        return data;
    },
    delete: async function (id) {
        MIB.getHeader();
        const url = process.env.REACT_APP_API_URL + "/banner/delete/" + id;
        this.setLock(true);
        const data = await axios.get(url).catch((err) => {
            console.log("error2:", err.message);
        });
        this.setLock(false);
        return data;
    },
    deleteMultiple: async function (dels) {
        MIB.getHeader();
        const url = process.env.REACT_APP_API_URL + "/banners";
        const data = await axios.delete(url, dels).catch((err) => {
            console.log("error2:", err.message);
        });
        return data;
    }
}

export default ServiceBanners;