import React, { Component } from 'react';
import { Form } from "react-bootstrap";
import { MIB } from '../../utils';
import Swal from 'sweetalert2';

import { Link, Navigate } from "react-router-dom";

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            hidePassword: true,
            results: {},
            loading: false
        }

        this.setValue = this.setValue.bind(this);
        //this.handleSubmit = this.handleSubmit.bind(this);

        /*const { router } = props;
        const valid = MIB.getSession();
        console.log(valid);
        if(valid != null && valid.status){
            let url = `/${process.env.REACT_APP_APP_PATH}`;
            router.navigate(url);
            return;
        }*/
    }

    setValue(e) {
        this.setState(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));
    }

    showHide() {
        this.setState(prevState => ({
            ...prevState,
            hidePassword: !prevState.hidePassword
        }));
    }

    submitHandler = async (e) => {
        e.preventDefault();

        const { router } = this.props;
        const { username, password } = this.state;
        let error = true;
        let msg = "";
        if (username === "") msg = "User name harus diisi";
        else if (password === "") msg = "Password harus diisi";
        else error = false;

        if (error) {
            Swal.fire('Error', msg, 'error');
        } else {
            this.setState(prevState => ({
                ...prevState,
                loading: true
            }));
            const data = await MIB.login(username, password);
            //console.log(data);
            this.setState(prevState => ({
                ...prevState,
                loading: false
            }));
            if (data.status) {
                let url = `/${process.env.REACT_APP_APP_PATH}`;
                router.navigate(url);
            } else {
                Swal.fire('Error', data.message, 'error');
            }
        }

    }



    render() {
        //console.log(this.props);
        const { username, password, hidePassword, loading } = this.state;

        const valid = MIB.getSession();
        if (valid != null && valid.status) {
            let url = `/${process.env.REACT_APP_APP_PATH}`;
            return (
                <Navigate to={url} replace={true} />
            );
        }

        return (
            <div className="wrapper">
                <div className="section-authentication-signin d-flex align-items-center justify-content-center my-5 my-lg-0">
                    <div className="container-fluid">
                        <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
                            <div className="col mx-auto">
                                <div className="mb-4 text-center">
                                    <img src="/assets/images/logo-skk-migas.png" width={180} alt="" />
                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="border p-4 rounded">
                                            <div className="text-center">
                                                <h3>Sign in</h3>
                                                <p>Type user name and password
                                                </p>
                                            </div>

                                            <div className="form-body">
                                                <Form className="row g-3" onSubmit={this.submitHandler}>
                                                    <div className="col-12">
                                                        <label htmlFor="username" className="form-label">User name</label>
                                                        <input type="text" className="form-control" name="username" id="username" value={username} placeholder="User name"
                                                            onChange={e => this.setValue(e)} />
                                                    </div>
                                                    <div className="col-12">
                                                        <label htmlFor="password" className="form-label">Password</label>
                                                        <div className="input-group" id="show_hide_password">
                                                            <input type={hidePassword ? 'password' : 'text'} className="form-control border-end-0" name="password" id="password" value={password} placeholder="Password"
                                                                onChange={e => this.setValue(e)} /> <button type="button" onClick={() => this.showHide()} className="input-group-text bg-transparent"><i className={hidePassword ? 'bx bx-hide' : 'bx bx-show'} /></button>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="d-grid">
                                                            <button type="submit" className="btn btn-primary"><i className="bx bxs-lock-open" />Sign in</button>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 text-center">
                                                        <Link to="forgot-password">
                                                            Forgot Password</Link>
                                                    </div>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*end row*/}
                    </div>
                </div>

                {loading ?
                    <><div id="locker" className="transparent_class"></div><div id="lockwindow"><div className="lds-facebook"><div></div><div></div><div></div></div></div></> :
                    ''
                }

            </div>
        );
    }
}

export default MIB.withRouter(Login);