const userRole = {
    APP : [
        {
            title: 'Dashboard',
            icon: 'bx bx-home-circle',
            url: '/',
            type: 'menu'
        },
        {
            title: 'Transaksi',
            icon: null,
            url: null,
            type: 'label'
        },
        {
            title: 'Menu',
            icon: 'bx bx-menu',
            url: null,
            type: 'parent',
            childs:[
                {
                    title: 'New Data',
                    url: '/menus/new',
                    type: 'menu'
                },
                {
                    title: 'Data',
                    url: '/menus',
                    type: 'menu'
                }
            ]
        },
        {
            title: 'News',
            icon: 'bx bx-news',
            url: null,
            type: 'parent',
            childs:[
                {
                    title: 'New Data',
                    url: '/news/new',
                    type: 'menu'
                },
                {
                    title: 'Data',
                    url: '/news',
                    type: 'menu'
                },
                {
                    title: 'Unpublished Data',
                    url: '/news/unpublish',
                    type: 'menu'
                },
                {
                    title: 'Categories',
                    url: '/categories',
                    type: 'menu'
                }
            ]
        },
        {
            title: 'Press Release',
            icon: 'bx bx-mail-send',
            url: null,
            type: 'parent',
            childs:[
                {
                    title: 'New Data',
                    url: '/press_release/new',
                    type: 'menu'
                },
                {
                    title: 'Data',
                    url: '/press_release',
                    type: 'menu'
                }
            ]
        },
        {
            title: 'Pages',
            icon: 'bx bx-comment',
            url: null,
            type: 'parent',
            childs:[
                {
                    title: 'New Data',
                    url: '/pages/new',
                    type: 'menu'
                },
                {
                    title: 'Data',
                    url: '/pages',
                    type: 'menu'
                }
            ]
        },
        {
            title: 'Publikasi',
            icon: 'bx bx-file-blank',
            url: null,
            type: 'parent',
            childs:[
                {
                    title: 'Jenis Publikasi',
                    url: '/jenis_file',
                    type: 'menu'
                },
                {
                    title: 'New Data',
                    url: '/file_upload/new',
                    type: 'menu'
                },
                {
                    title: 'Data',
                    url: '/file_upload',
                    type: 'menu'
                }
            ]
        },
        {
            title: 'Video',
            icon: 'bx bx-video',
            url: null,
            type: 'parent',
            childs:[
                {
                    title: 'New Data',
                    url: '/video/new',
                    type: 'menu'
                },
                {
                    title: 'Data',
                    url: '/video',
                    type: 'menu'
                }
            ]
        },
        {
            title: 'Banners',
            icon: 'bx bx-image-alt',
            url: null,
            type: 'parent',
            childs:[
                {
                    title: 'New Data',
                    url: '/banners/new',
                    type: 'menu'
                },
                {
                    title: 'Data',
                    url: '/banners',
                    type: 'menu'
                }
            ]
        },
        {
            title: 'Galleries',
            icon: 'bx bx-image',
            url: null,
            type: 'parent',
            childs:[
                {
                    title: 'New Data',
                    url: '/galleries/new',
                    type: 'menu'
                },
                {
                    title: 'Data',
                    url: '/galleries',
                    type: 'menu'
                }
            ]
        },
        {
            title: 'Achievements',
            icon: 'bx bx-repost',
            url: null,
            type: 'parent',
            childs:[
                {
                    title: 'Data',
                    url: '/achievements_data',
                    type: 'menu'
                },
                {
                    title: 'Units',
                    url: '/units',
                    type: 'menu'
                },
                {
                    title: 'Categories',
                    url: '/achievements',
                    type: 'menu'
                }
            ]
        },
        {
            title: 'Profile Management',
            icon: 'bx bx-user',
            url: null,
            type: 'parent',
            childs:[
                {
                    title: 'New Data',
                    url: '/profile/new',
                    type: 'menu'
                },
                {
                    title: 'Data',
                    url: '/profile',
                    type: 'menu'
                }
            ]
        },
        {
            title: 'Sosial Media',
            icon: 'lni lni-facebook-original',
            url: null,
            type: 'parent',
            childs:[
                {
                    title: 'New Data',
                    url: '/sosmed/new',
                    type: 'menu'
                },
                {
                    title: 'Data',
                    url: '/sosmed',
                    type: 'menu'
                }
            ]
        },
        {
            title: 'Subscribes',
            icon: 'bx bx bx-smile',
            url: null,
            type: 'parent',
            childs:[
                {
                    title: 'Send email',
                    url: '/subscriptions_messages/new',
                    type: 'menu'
                },
                {
                    title: 'Send email Data',
                    url: '/subscriptions_messages',
                    type: 'menu'
                },
                {
                    title: 'Email subscribes',
                    url: '/subscriptions',
                    type: 'menu'
                }
            ]
        },
        {
            title: 'Master Data',
            icon: null,
            url: null,
            type: 'label'
        },
        {
            title: 'Master',
            icon: 'bx bx-category',
            url: null,
            type: 'parent',
            childs:[
                {
                    title: 'Users',
                    url: '/users',
                    type: 'menu'
                },
                {
                    title: 'Offices',
                    url: '/offices',
                    type: 'menu'
                },
                {
                    title: 'Map',
                    url: '/area_map',
                    type: 'menu'
                },
                {
                    title: 'Params Config',
                    url: '/common_parameters',
                    type: 'menu'
                },
                {
                    title: 'KKKS',
                    url: null,
                    type: 'parent',
                    childs:[
                        {
                            title: 'Categories',
                            url: '/kkks_categories',
                            type: 'menu'
                        },
                        {
                            title: 'Companies',
                            url: '/kkks_companies',
                            type: 'menu'
                        }
                    ]
                }
            ]
        }
    ],

    EDI : [
        {
            title: 'Dashboard',
            icon: 'bx bx-home-circle',
            url: '/',
            type: 'menu'
        },
        {
            title: 'Transaksi',
            icon: null,
            url: null,
            type: 'label'
        },
        {
            title: 'News',
            icon: 'bx bx-news',
            url: null,
            type: 'parent',
            childs:[
                {
                    title: 'New Data',
                    url: '/news/new',
                    type: 'menu'
                },
                {
                    title: 'Data',
                    url: '/news',
                    type: 'menu'
                },
                {
                    title: 'Unpublished Data',
                    url: '/news/unpublish',
                    type: 'menu'
                },
                {
                    title: 'Categories',
                    url: '/categories',
                    type: 'menu'
                }
            ]
        },
        {
            title: 'Press Release',
            icon: 'bx bx-mail-send',
            url: null,
            type: 'parent',
            childs:[
                {
                    title: 'New Data',
                    url: '/press_release/new',
                    type: 'menu'
                },
                {
                    title: 'Data',
                    url: '/press_release',
                    type: 'menu'
                }
            ]
        },
        {
            title: 'Pages',
            icon: 'bx bx-comment',
            url: null,
            type: 'parent',
            childs:[
                {
                    title: 'New Data',
                    url: '/pages/new',
                    type: 'menu'
                },
                {
                    title: 'Data',
                    url: '/pages',
                    type: 'menu'
                }
            ]
        },
        {
            title: 'Publikasi',
            icon: 'bx bx-file-blank',
            url: null,
            type: 'parent',
            childs:[
                {
                    title: 'Jenis Publikasi',
                    url: '/jenis_file',
                    type: 'menu'
                },
                {
                    title: 'New Data',
                    url: '/file_upload/new',
                    type: 'menu'
                },
                {
                    title: 'Data',
                    url: '/file_upload',
                    type: 'menu'
                }
            ]
        },
        {
            title: 'Video',
            icon: 'bx bx-video',
            url: null,
            type: 'parent',
            childs:[
                {
                    title: 'New Data',
                    url: '/video/new',
                    type: 'menu'
                },
                {
                    title: 'Data',
                    url: '/video',
                    type: 'menu'
                }
            ]
        },
        {
            title: 'Achievements',
            icon: 'bx bx-repost',
            url: null,
            type: 'parent',
            childs:[
                {
                    title: 'Data',
                    url: '/achievements_data',
                    type: 'menu'
                },
                {
                    title: 'Units',
                    url: '/units',
                    type: 'menu'
                },
                {
                    title: 'Categories',
                    url: '/achievements',
                    type: 'menu'
                }
            ]
        },
        {
            title: 'Subscribes',
            icon: 'bx bx bx-smile',
            url: null,
            type: 'parent',
            childs:[
                {
                    title: 'Send email',
                    url: '/subscriptions_messages/new',
                    type: 'menu'
                },
                {
                    title: 'Send email Data',
                    url: '/subscriptions_messages',
                    type: 'menu'
                },
                {
                    title: 'Email subscribes',
                    url: '/subscriptions',
                    type: 'menu'
                }
            ]
        }
    ],

    ADM: [
        {
            title: 'Dashboard',
            icon: 'bx bx-home-circle',
            url: '/',
            type: 'menu'
        },
        {
            title: 'Transaksi',
            icon: null,
            url: null,
            type: 'label'
        },
        {
            title: 'News',
            icon: 'bx bx-news',
            url: null,
            type: 'parent',
            childs:[
                {
                    title: 'New Data',
                    url: '/news/new',
                    type: 'menu'
                },
                {
                    title: 'Data',
                    url: '/news',
                    type: 'menu'
                },
                {
                    title: 'Unpublished Data',
                    url: '/news/unpublish',
                    type: 'menu'
                },
                {
                    title: 'Categories',
                    url: '/categories',
                    type: 'menu'
                }
            ]
        },
        {
            title: 'Press Release',
            icon: 'bx bx-mail-send',
            url: null,
            type: 'parent',
            childs:[
                {
                    title: 'New Data',
                    url: '/press_release/new',
                    type: 'menu'
                },
                {
                    title: 'Data',
                    url: '/press_release',
                    type: 'menu'
                }
            ]
        },
        {
            title: 'Pages',
            icon: 'bx bx-comment',
            url: null,
            type: 'parent',
            childs:[
                {
                    title: 'New Data',
                    url: '/pages/new',
                    type: 'menu'
                },
                {
                    title: 'Data',
                    url: '/pages',
                    type: 'menu'
                }
            ]
        },
        {
            title: 'Publikasi',
            icon: 'bx bx-file-blank',
            url: null,
            type: 'parent',
            childs:[
                {
                    title: 'Jenis Publikasi',
                    url: '/jenis_file',
                    type: 'menu'
                },
                {
                    title: 'New Data',
                    url: '/file_upload/new',
                    type: 'menu'
                },
                {
                    title: 'Data',
                    url: '/file_upload',
                    type: 'menu'
                }
            ]
        },
        {
            title: 'Video',
            icon: 'bx bx-video',
            url: null,
            type: 'parent',
            childs:[
                {
                    title: 'New Data',
                    url: '/video/new',
                    type: 'menu'
                },
                {
                    title: 'Data',
                    url: '/video',
                    type: 'menu'
                }
            ]
        },
        {
            title: 'Achievements',
            icon: 'bx bx-repost',
            url: null,
            type: 'parent',
            childs:[
                {
                    title: 'Data',
                    url: '/achievements_data',
                    type: 'menu'
                },
                {
                    title: 'Units',
                    url: '/units',
                    type: 'menu'
                },
                {
                    title: 'Categories',
                    url: '/achievements',
                    type: 'menu'
                }
            ]
        },
        {
            title: 'Profile Management',
            icon: 'bx bx-user',
            url: null,
            type: 'parent',
            childs:[
                {
                    title: 'New Data',
                    url: '/profile/new',
                    type: 'menu'
                },
                {
                    title: 'Data',
                    url: '/profile',
                    type: 'menu'
                }
            ]
        }
    ]
}

export default userRole;