/*-----------------------------------------------------------------
GENERATED BY MIB_PHP_GENERATOR VERSION 3.0
CREATED BY MISBA
EMAIL: m.m158a@gmail.com

DATE: 26-08-2022
TIME: 11:00:48

@copyright 2020 by MISBA
------------------------------------------------------------------*/




import React, { Component } from 'react';
import { Form, Tooltip, OverlayTrigger } from "react-bootstrap";
import { ServiceNews } from "./";
import { MIB, UploadFile } from "../../utils";
//import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ServiceCategories } from "../categories";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

class InputNews extends Component {
	constructor(props) {
		super(props);
		this.state = {
			news_id: '',
			post: {
				category_id: '',
				judul: '',
				judul_en: '',
				tanggal: '',
				img_gambar: '',
				kota: '',
				penulis: '',
				summary: '',
				summary_en: '',
				content: '',
				content_en: '',
				is_skk: false,
				is_iogi: false,
				is_kkks: false,
				is_published: false,
				editor: '',
				created: '',
				createdby: '',
				updated: '',
				updatedby: ''
			},
			dt_categories: []

		}

		this.setValue = this.setValue.bind(this);
		this.setHTML = this.setHTML.bind(this);


		this.approve = this.props.router.location.pathname.indexOf('approve') !== -1 ? true : false;
		//console.log(this.approve);
		ServiceCategories.setLock = this.props.router.context.setLock;
		ServiceNews.setLock = this.props.router.context.setLock;
	}

	setHTML(html, name) {
		const e = {
			target: {
				name: name,
				value: html
			}
		};
		this.setValue(e);
	}



	setValue(e) {
		let value = e.target.value;
		if (e.target.name === 'is_skk') value = !this.state.post.is_skk;
		else if (e.target.name === 'is_iogi') value = !this.state.post.is_iogi;
		else if (e.target.name === 'is_kkks') value = !this.state.post.is_kkks;

		//console.log(e.target.name);
		//console.log(value);

		this.setState((prevState) => ({
			...prevState,
			post: {
				...prevState.post,
				[e.target.name]: value
			}
		}))
	}

	submitHandler = async (e) => {
		e.preventDefault();
		const { router, url } = this.props;
		let data;
		let msg = '';
		let post = this.state.post;
		let jwt = MIB.extractToken();
		let user_name = jwt.json.user_name;
		post.createdby = user_name;
		post.updatedby = user_name;
		post.created = undefined;
		post.updated = undefined;
		post.category = undefined;
		if (this.approve) {
			post.editor = user_name;
			post.is_published = true;
		}

		let error = true;
		let err_msg = '';
		if(post.category_id === '') err_msg = 'Category harus diisi';
		else if(post.tanggal === '') err_msg = 'Tanggal harus diisi';
		else if(post.kota === '') err_msg = 'Kota harus diisi';
		else if(post.penulis === '') err_msg = 'Penulis harus diisi';
		else if(post.judul === '') err_msg = 'Judul bahasa indonesia harus diisi';
		//else if(post.judul_en === '') err_msg = 'Judul bahasa inggris harus diisi';
		//else if(post.summary === '') err_msg = 'Summarty bahasa indonesia harus diisi';
		//else if(post.summary_en === '') err_msg = 'Summarty bahasa inggris harus diisi';
		else if(post.content === '') err_msg = 'Content bahasa indonesia harus diisi';
		//else if(post.content_en === '') err_msg = 'Content bahasa inggris harus diisi';
		else error = false;

		if(error){
			toast.error(err_msg);
			return;
		}
		post.tanggal = post.tanggal.replace("T", " ")+':00';
		post.tanggal = post.tanggal.substring(0,19);
		//console.log(post.tanggal);

		if(post.img_gambar === '') post.img_gambar = undefined;

		//console.log(post);
		if (router.params.news_id) {
			data = await ServiceNews.update(post, router.params.news_id);
			msg = 'Data berhasil diupdate';
		}
		else {
			data = await ServiceNews.new(post);
			msg = 'Data berhasil ditambah';
		}
		let rs = data.data;
		if (rs.status) {
			//Swal.fire('Success', rs.message, 'success');
			//toast.success(rs.message);
			let state = { toast: { type: 'success', message: msg } }
			router.navigate(url, { state });
		} else {
			//Swal.fire('Error', rs.message, 'error');
			//toast.error(rs.message);
			MIB.errorToken(rs.message);
		}
	}

	async getCategories() {
		const qstring = "keyword=&page=0&sort_by=0&field_sort=id&limit=1000";
		const respCategories = await ServiceCategories.get(qstring);
		if (respCategories.data.status) {
			this.setState((prevState) => ({
				...prevState,
				dt_categories: respCategories.data.datas.content
			}));
		}else{
			MIB.errorToken(respCategories.data.message);
		}
	}

	async componentDidMount() {
		const { params, navigate } = this.props.router;
		await this.getCategories();
		if (params.news_id) {
			const resp = await ServiceNews.detail(params.news_id);
			if (resp !== undefined) {
				if (resp.data.status && resp.data.datas !== undefined) {
					const data = resp.data.datas;//{DATE_TIME_CONVERT}
					this.setState((prevState) => ({
						...prevState,
						news_id: data.news_id,
						post: data
					}));
				} else {
					MIB.errorToken(resp.data.message);
					navigate(this.props.url);
				}
			} else {
				console.log(resp.data.message);
				console.log(this.props.url);
			}
		} else {
			let category_id = '';
			if (this.state.dt_categories.length > 0) category_id = this.state.dt_categories[0].category_id;
			this.setState((prevState) => ({
				...prevState,
				post: {
					...prevState.post,
					category_id: category_id
				}
			}));
		}
	}

	render() {
		const { router, url } = this.props;
		const { post, dt_categories } = this.state;
		const toolbar = MIB.quillToolbar();
		const url_upload = process.env.REACT_APP_UPLOAD_URL;
		//console.log(router);

		let btnAction = router.context.action === 'approve'?<button type="submit" className="btn btn-success radius-30 mt-2 mt-lg-0" name="btnSave" id="btnSave"><i className="bx bx-check-circle" /> Approve</button>:
			<button type="submit" className="btn btn-success radius-30 mt-2 mt-lg-0" name="btnSave" id="btnSave"><i className="bx bx-save" /> Save</button>;

		//console.log(MIB.getUri(router.location.pathname));
		/*let uri = MIB.getUri(router.location.pathname);
		if(uri[2] === 'new'){
			this.setState((prevState) => ({
				...prevState,
				post: {
					...this.default
				}
			}));
		}*/
		//console.log(post.category_id);
		post.tanggal = post.tanggal.replace("T", " ");
		//console.log(post.tanggal);

		post.is_skk = post.is_skk ? true : false;
		post.is_iogi = post.is_iogi ? true : false;
		post.is_kkks = post.is_kkks ? true : false;
		post.is_published = post.is_published ? true : false;

		let news_part_html = '';
		if (this.approve) {
			news_part_html = <div className="mb-3">
				<div className="row">
					<div className="col-sm-4"><label htmlFor="is_skk"><input type="checkbox" name="is_skk" id="is_skk" value="1" checked={post.is_skk} onChange={(event) => this.setValue(event)} /> SKK</label></div>
					<div className="col-sm-4"><label htmlFor="is_iogi"><input type="checkbox" name="is_iogi" id="is_iogi" value="1" checked={post.is_iogi} onChange={(event) => this.setValue(event)} /> IOGI</label></div>
					<div className="col-sm-4"><label htmlFor="is_kkks"><input type="checkbox" name="is_kkks" id="is_kkks" value="1" checked={post.is_kkks} onChange={(event) => this.setValue(event)} /> KKKS</label></div>
				</div>
			</div>
		}


		let jwt = MIB.extractToken();
		let user_name = jwt.json.user_name;
		post.editor = post.news_id === '' ? '' : user_name;
		return (
			<Form onSubmit={this.submitHandler}>
				<div className="row">
					<div className="col-sm-12">
						<div className="card">
							<div className="card-body">

								<div className="p-4">
									<div className="card-title d-flex align-items-center">
										<div><i className="bx bxs-info-square me-1 font-22 text-info"></i></div>
										<h5 className="mb-0 text-info">{router.context.title}</h5>
									</div>
									<hr />

									<div className="row">
										<div className="col-sm-6">
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="category_id">Category:</label>
												<div className="col-sm-9"><select name="category_id" id="category_id" className="form-select" onChange={(event) => this.setValue(event)} value={post.category_id}>
												<option value=""></option>
													{dt_categories.map(rs => (
														<option key={rs.category_id} value={rs.category_id}>{rs.category}</option>
													))}
												</select></div>
											</div>

											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="kota">Kota:</label>
												<div className="col-sm-9"><input type="text" name="kota" id="kota" value={post.kota} maxLength="255" className="form-control" onChange={(event) => this.setValue(event)} /></div>
											</div>
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="judul">Judul (ID):</label>
												<div className="col-sm-9"><input type="text" name="judul" id="judul" value={post.judul} maxLength="255" className="form-control" onChange={(event) => this.setValue(event)} /></div>
											</div>

											<div className="mb-3">
												<label className="form-label" htmlFor="summary">Summary (ID):</label>

												<ReactQuill
													theme="snow"
													modules={toolbar.modules}
													formats={toolbar.formats}
													value={post.summary}
													onChange={(html) => this.setHTML(html, 'summary')}
												/>
												{/*<input type="text" name="summary" id="summary" value={post.summary} maxLength="200" className="form-control" onChange={(event) => this.setValue(event)} />*/}

											</div>

											<div className="mb-3">
												<label className="form-label" htmlFor="content">Content (ID):</label>
												<ReactQuill
													theme="snow"
													modules={toolbar.modules}
													formats={toolbar.formats}
													value={post.content}
													onChange={(html) => this.setHTML(html, 'content')}
												/>
												{/*<input type="text" name="content" id="content" value={post.content} maxLength="7000" className="form-control" onChange={(event) => this.setValue(event)} />*/}
											</div>
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="img_gambar">Gambar:</label>
												<div className="col-sm-9">
													<UploadFile
														name="img_gambar"
														accept="image"
														saveTo="pages/image"
														url={url_upload}
														changeState={this.setValue}
														value={post.img_gambar} 
														size="1" />
													{/*<ImageInput name="img_gambar" id="img_gambar" value={post.img_gambar} content={imgContent.img_gambar} onChange={(e)=>this.uploadImage(e,'img_gambar')} />*/}
												</div>
											</div>

										</div>
										<div className="col-sm-6">
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="tanggal">Tanggal:</label>
												<div className="col-sm-9"><input type="datetime-local" name="tanggal" id="tanggal" value={post.tanggal} className="form-control" onChange={(event) => this.setValue(event)} /></div>
											</div>
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="penulis">Penulis:</label>
												<div className="col-sm-9"><input type="text" name="penulis" id="penulis" value={post.penulis} maxLength="255" className="form-control" onChange={(event) => this.setValue(event)} /></div>
											</div>
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="judul_en">Judul (EN):</label>
												<div className="col-sm-9"><input type="text" name="judul_en" id="judul_en" value={post.judul_en} maxLength="255" className="form-control" onChange={(event) => this.setValue(event)} /></div>
											</div>


											<div className="mb-3">
												<label className="form-label" htmlFor="summary_en">Summary (EN):</label>

												<ReactQuill
													theme="snow"
													modules={toolbar.modules}
													formats={toolbar.formats}
													value={post.summary_en}
													onChange={(html) => this.setHTML(html, 'summary_en')}
												/>
												{/*<input type="text" name="summary_en" id="summary_en" value={post.summary_en} maxLength="200" className="form-control" onChange={(event) => this.setValue(event)} />*/}

											</div>

											<div className="mb-3">
												<label className="form-label" htmlFor="content_en">Content (EN):</label>
												<ReactQuill
													theme="snow"
													modules={toolbar.modules}
													formats={toolbar.formats}
													value={post.content_en}
													onChange={(html) => this.setHTML(html, 'content_en')}
												/>
												{/*<input type="text" name="content_en" id="content_en" value={post.content_en} maxLength="7000" className="form-control" onChange={(event) => this.setValue(event)} />*/}
											</div>
											{news_part_html}
										</div>
									</div>



								</div>

							</div>
						</div>
					</div>
				</div>


				{btnAction}
				<button className="btn btn-info radius-30 mt-2 mt-lg-0 ms-1" type="button" onClick={() => router.navigate(url)}>
					<i className="bx bx-chevron-left" />
					Back
				</button>
				{this.state.news_id !== '' && post.created !== undefined ? <OverlayTrigger
					key="top"
					placement="top"
					overlay={
						<Tooltip id={`tooltip-top`}>
							<table>
								<tbody>
									<tr>
										<td nowrap="nowrap">Created</td>
										<td>:</td>
										<td nowrap="nowrap">{MIB.dateTimeFormat(post.created)}</td>
									</tr>
									<tr>
										<td nowrap="nowrap">Created by</td>
										<td>:</td>
										<td nowrap="nowrap">{post.createdby}</td>
									</tr>
									<tr>
										<td nowrap="nowrap">Updated</td>
										<td>:</td>
										<td nowrap="nowrap">{MIB.dateTimeFormat(post.updated)}</td>
									</tr>
									<tr>
										<td nowrap="nowrap">Updated by</td>
										<td>:</td>
										<td nowrap="nowrap">{post.updatedby}</td>
									</tr>
								</tbody>

							</table>
						</Tooltip>
					}
				>
					<button type="button" className="btn btn-warning radius-30 mt-2 mt-lg-0 ms-1"><i className="bx bx-history" /> History</button>
				</OverlayTrigger> : ''}

				<ToastContainer
					position="top-right"
					theme="colored"
					autoClose={2000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
			</Form>
		);
	}
}

export default MIB.withRouter(InputNews);