/*-----------------------------------------------------------------
GENERATED BY MIB_PHP_GENERATOR VERSION 3.0
CREATED BY MISBA
EMAIL: m.m158a@gmail.com

DATE: 26-08-2022
TIME: 11:00:48

@copyright 2020 by MISBA
------------------------------------------------------------------*/




import React, { Component } from 'react';
import { Button, Form } from "react-bootstrap";
import { ServiceFileUpload } from "./";
import { MIB, Paging } from "../../utils";
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class ShowFileUpload extends Component {

    defaultState = {
        data: [],
        pagination: null,
        post: {
            keyword: '',
            page: 0,
            sort_by: '0',
            field_sort: 'id',
            limit: 10,
            /*judul: '',
            judul_en: '',
            keterangan: '',
            keterangan_en: '',
            date: ''*/
        },
        //switchFilter: false,
        //ckAll: false,
        //ck: []
    }

    constructor(props) {
        super(props);
        this.state = this.defaultState

		ServiceFileUpload.setLock = this.props.router.context.setLock;
        this.delete = this.delete.bind(this);
        this.setValue = this.setValue.bind(this);
        this.setPage = this.setPage.bind(this);
    }

    async delete(file_upload_id) {
        Swal.fire({
            title: 'Anda yakin?',
            text: "Hapus data ini!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya, hapus!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let data = await ServiceFileUpload.delete(file_upload_id);
                //let title = "Deleted!";
                let message = "Data berhasil dihapus";
                let icon = "success";
                if (!data.status || data === undefined) {
                    icon = "error";
                    message = data === undefined ? "Error rest api" : data.message;
                    //title = "Failed!"
                } else {
                    await this.loadData();
                }
                /*Swal.fire(
                    title,
                    message,
                    icon
                )*/
                if (icon === "error")
                    MIB.errorToken(message);
                else
                    toast.success(message);
            }
        });
    }

    /*async deleteMultiple() {
        let jml = 0;
        let del = [];
        this.state.ck.map((val, i) => {
            if (val) {
                del[jml] = this.state.data[i].file_upload_id;
                jml++;
            }
        });

        if (jml > 0) {
            Swal.fire({
                title: 'Anda yakin?',
                text: "Hapus data yang dicentang!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ya, hapus!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const dels = { data: del };
                    console.log(dels)
                    let data = await ServiceFileUpload.deleteMultiple(dels);
                    //let title = "Deleted!";
                    let message = "Data berhasil dihapus";
                    let icon = "success";
                    if (data === undefined || !data.status) {
                        icon = "error";
                        message = data === undefined ? "Error rest api" : data.message;
                        //title = "Failed!";
                    } else {
                        await this.loadData();
                    }
                    if (icon === "error")
                        toast.error(message);
                    else
                        toast.success(message);
                }
            })
        } else {
            toast.error("Ceklist minimal 1 data");
        }

    }*/

    async loadData() {
        const queryString = new URLSearchParams(this.state.post).toString();
        const resp = await ServiceFileUpload.get(queryString);
        if (resp !== undefined) {
            if (resp.data.status) {
                const data = resp.data.datas.content;
                const pagination = {
                    num_pages: resp.data.datas.totalPages,
                    page: resp.data.datas.pageable.pageNumber,
                    limit: resp.data.datas.pageable.pageSize,
                    num_rows: resp.data.datas.totalElements
                };
                /*const data = resp.data.data;
                const pagination = resp.data.pagination;

                let ck = [];
                for (let i = 0; i < data.length; i++) {
                    ck[i] = false;
                }*/

                this.setState((prevState) => ({
                    ...prevState,
                    data: data,
                    pagination: pagination,
                    //ck: ck
                }));
            } else {
                MIB.errorToken(resp.data.message);
            }
        } else {
            console.log(resp.data.message);
        }
    }

    async componentDidMount() {
        const { router } = this.props;
        if (router.location.state !== null && router.location.state.toast !== undefined) {
            if (router.location.state.toast.type === 'error')
                toast.error(router.location.state.toast.message, { onClose: () => router.location.state = null, toastId: 'toastId' });
            else
                toast.success(router.location.state.toast.message, { onClose: () => router.location.state = null, toastId: 'toastId' });
        }
        await this.loadData();
    }

    setValue(e) {
        this.setState((prevState) => ({
            ...prevState,
            post: {
                ...prevState.post,
                [e.target.name]: e.target.value
            }
        }))
    }

    /*setValueCk(e) {
        let no = parseInt(e.target.name.replace('ck_', ''));
        no = no === undefined ? 0 : no;
        let ck = this.state.ck;
        ck[no] = ck[no] ? false : true;
        this.setState((prevState) => ({
            ...prevState,
            ck: ck
        }));
    }

    checkAll(e) {
        this.setState((prevState) => ({
            ...prevState,
            ckAll: !prevState.ckAll
        }));

        let ck = [];
        for (let i = 0; i < this.state.ck.length; i++) {
            ck[i] = !this.state.ckAll;
        }

        this.setState((prevState) => ({
            ...prevState,
            ck: ck
        }));
    }

    switchFilterToggle() {
        this.setState((prevState) => ({
            ...prevState,
            switchFilter: !prevState.switchFilter
        }));
    }*/

    async refresh() {
        await this.setState(this.defaultState);
        await this.loadData();
    }

    async setPage(e, page) {
        e.preventDefault();
        await this.setState((prevState) => ({
            ...prevState,
            post: {
                ...prevState.post,
                page: page
            }
        }));
        await this.loadData();
    }

    async sortBy(field_sort) {
        let sort_by = '0';
        if (field_sort === this.state.post.field_sort) {
            if (this.state.post.sort_by === '1')
                sort_by = '0';
            else
                sort_by = '1';
        }
        await this.setState((prevState) => ({
            ...prevState,
            post: {
                ...prevState.post,
                sort_by: sort_by,
                field_sort: field_sort
            }
        }));
        await this.loadData();
    }

    async goOnEnter(e) {
        //console.log(e.key)
        if (e.key === 'Enter') {
            await this.loadData();
        }
    }


    render() {
        const { router } = this.props;
        const url = router.context.url;
        //const { data, pagination, post, switchFilter, ck } = this.state;
        const { data, pagination, post } = this.state;
        //console.log(data);

        return (
            <Form onSubmit={(e) => e.preventDefault()}>
                <input type="hidden" name="page" id="page" value={post.page} onChange={(event) => this.setValue(event)} />
                <input type="hidden" name="sort_by" id="sort_by" value={post.sort_by} onChange={(event) => this.setValue(event)} />
                <input type="hidden" name="field_sort" id="field_sort" value={post.field_sort} onChange={(event) => this.setValue(event)} />

                <div className="row">
                    <div className="col-sm-12">
                        <div className="card m-b-30">
                            <div className="card-body">

                                <div className="d-lg-flex align-items-center mb-4 gap-3">
                                    <div className="position-relative">
                                        <input
                                            type="text"
                                            id="keyword"
                                            name="keyword"
                                            className="form-control ps-5 radius-30"
                                            placeholder="Type keyword, then enter"
                                            value={post.keyword}
                                            onChange={(event) => this.setValue(event)}
                                            onKeyUpCapture={e => this.goOnEnter(e)} />
                                        <span className="position-absolute top-50 product-show translate-middle-y"><i className="bx bx-search"></i></span>
                                    </div>
                                    <div className="ms-auto">
                                        <div className="d-flex">
                                            {/*<Button variant="success" size="sm" type="button" className="radius-30 mt-2 mt-lg-0" onClick={() => this.switchFilterToggle()} ><i className="bx bx-search" />Filter</Button>
                                            <Button variant="danger" size="sm" type="button" className="radius-30 mt-2 mt-lg-0 ms-2" onClick={() => this.deleteMultiple()}><i className="bx bx-trash" />Delete</Button>*/}
                                            <Button variant="info" size="sm" type="button" className="radius-30 mt-2 mt-lg-0 ms-2" onClick={() => this.refresh()}><i className="bx bx-refresh" />Refresh</Button>
                                            <Button variant="primary" size="sm" type="button" className="radius-30 mt-2 mt-lg-0 ms-2" onClick={() => router.navigate(url + "/new")}><i className="bx bx-plus" />Add New Data</Button>

                                        </div>
                                    </div>
                                </div>

                                <div className="table-responsive">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                {/*<th><input className="form-check-input me-3" type="checkbox" aria-label="..." onClick={(e) => this.checkAll(e)} /></th>*/}
                                                <th>Code <a onClick={() => this.sortBy('file_upload.code_file')} className={`radius-30 mt-2 mt-lg-0 ms-2` + (post.field_sort === 'file_upload.code_file' ? ' btn-danger' : ' btn-primary')}> <i className={`bx ` + (post.field_sort === 'file_upload.code_file' && post.sort_by === '1' ? 'bx-down-arrow' : 'bx-up-arrow')} /></a></th>
                                                <th>Name <a onClick={() => this.sortBy('jenis_file.name')} className={`radius-30 mt-2 mt-lg-0 ms-2` + (post.field_sort === 'jenis_file.name' ? ' btn-danger' : ' btn-primary')}> <i className={`bx ` + (post.field_sort === 'jenis_file.name' && post.sort_by === '1' ? 'bx-down-arrow' : 'bx-up-arrow')} /></a></th>
                                                <th>Judul (ID)<a onClick={() => this.sortBy('file_upload.judul')} className={`radius-30 mt-2 mt-lg-0 ms-2` + (post.field_sort === 'file_upload.judul' ? ' btn-danger' : ' btn-primary')}> <i className={`bx ` + (post.field_sort === 'file_upload.judul' && post.sort_by === '1' ? 'bx-down-arrow' : 'bx-up-arrow')} /></a></th>
                                                <th>Judul  (EN) <a onClick={() => this.sortBy('file_upload.judul_en')} className={`radius-30 mt-2 mt-lg-0 ms-2` + (post.field_sort === 'file_upload.judul_en' ? ' btn-danger' : ' btn-primary')}> <i className={`bx ` + (post.field_sort === 'file_upload.judul_en' && post.sort_by === '1' ? 'bx-down-arrow' : 'bx-up-arrow')} /></a></th>
                                                {/*<th>Photo <a onClick={() => this.sortBy('file_upload.img_photo')} className={`radius-30 mt-2 mt-lg-0 ms-2` + (post.field_sort === 'file_upload.img_photo' ? ' btn-danger' : ' btn-primary')}> <i className={`bx ` + (post.field_sort === 'file_upload.img_photo' && post.sort_by === '1' ? 'bx-down-arrow' : 'bx-up-arrow')} /></a></th>
                                                <th>File Name <a onClick={() => this.sortBy('file_upload.file_name')} className={`radius-30 mt-2 mt-lg-0 ms-2` + (post.field_sort === 'file_upload.file_name' ? ' btn-danger' : ' btn-primary')}> <i className={`bx ` + (post.field_sort === 'file_upload.file_name' && post.sort_by === '1' ? 'bx-down-arrow' : 'bx-up-arrow')} /></a></th>*/}
                                                {/*<th>Keterangan (ID)<a onClick={() => this.sortBy('file_upload.keterangan')} className={`radius-30 mt-2 mt-lg-0 ms-2` + (post.field_sort === 'file_upload.keterangan' ? ' btn-danger' : ' btn-primary')}> <i className={`bx ` + (post.field_sort === 'file_upload.keterangan' && post.sort_by === '1' ? 'bx-down-arrow' : 'bx-up-arrow')} /></a></th>
                                                <th>Keterangan (EN) <a onClick={() => this.sortBy('file_upload.keterangan_en')} className={`radius-30 mt-2 mt-lg-0 ms-2` + (post.field_sort === 'file_upload.keterangan_en' ? ' btn-danger' : ' btn-primary')}> <i className={`bx ` + (post.field_sort === 'file_upload.keterangan_en' && post.sort_by === '1' ? 'bx-down-arrow' : 'bx-up-arrow')} /></a></th>
                                                <th>Date <a onClick={() => this.sortBy('file_upload.date')} className={`radius-30 mt-2 mt-lg-0 ms-2` + (post.field_sort === 'file_upload.date' ? ' btn-danger' : ' btn-primary')}> <i className={`bx ` + (post.field_sort === 'file_upload.date' && post.sort_by === '1' ? 'bx-down-arrow' : 'bx-up-arrow')} /></a></th>*/}
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((rs, no) => {
                                                return (
                                                    <tr key={rs.file_upload_id}>
                                                        {/*<td><input className="form-check-input me-3" type="checkbox" name={`ck_${no}`} id={`ck_${no}`} checked={ck[no] ? true : false} onChange={(event) => this.setValueCk(event)} value={rs.file_upload_id} /></td>*/}
                                                        <td>{rs.code_file}</td>
                                                        <td>{rs.name}</td>
                                                        <td>{rs.judul}</td>
                                                        <td>{rs.judul_en}</td>
                                                        {/*<td>
                                                            <TdImg src={rs.img_photo} width={50} /></td>
                                                        <td>
                                                            <TdFile href={rs.file_name} />
                                                        </td>*/}
                                                        {/*<td>{rs.keterangan}</td>
                                                        <td>{rs.keterangan_en}</td>
                                                    <td>{MIB.dateFormat(rs.date)}</td>*/}
                                                        <td>
                                                            <Link to={`${url}/edit/${rs.file_upload_id}`} className="btn-primary btn-sm radius-30 mt-2 mt-lg-0"><i className="bx bx-edit" /></Link>
                                                            <a className="btn-danger btn-sm radius-30 mt-2 mt-lg-0" onClick={() => this.delete(rs.file_upload_id)}><i className="bx bx-trash" /></a>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>

                                </div>

                                <Paging pagination={pagination} setPage={this.setPage} />







                            </div>
                        </div>
                    </div>
                </div>

                {/*
                <div className={'switcher-wrapper' + (switchFilter ? ' switcher-toggled' : '')}>
                    <div className="switcher-header">
                        <div className="d-flex align-items-center">
                            <h5 className="mb-0 text-uppercase"><i className="bx bx-search"></i> Filters</h5>
                            <button type="button" className="btn-close ms-auto close-switcher" aria-label="Close" onClick={() => this.switchFilterToggle()} />
                        </div>
                    </div>
                    <div className="switcher-body">

                        <div className="mb-3">
                            <label className="form-label" htmlFor="judul">Judul (ID):</label>
                            <input type="text" name="judul" id="judul" value={post.judul} maxLength="255" className="form-control" onChange={(event) => this.setValue(event)} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="judul_en">Judul (EN):</label>
                            <input type="text" name="judul_en" id="judul_en" value={post.judul_en} maxLength="255" className="form-control" onChange={(event) => this.setValue(event)} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="keterangan">Keterangan (ID):</label>
                            <input type="text" name="keterangan" id="keterangan" value={post.keterangan} maxLength="500" className="form-control" onChange={(event) => this.setValue(event)} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="keterangan_en">Keterangan (EN):</label>
                            <input type="text" name="keterangan_en" id="keterangan_en" value={post.keterangan_en} maxLength="500" className="form-control" onChange={(event) => this.setValue(event)} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="date">Date:</label>
                            <input type="date" name="date" id="date" value={post.date} className="form-control" onChange={(event) => this.setValue(event)} />
                        </div>


                    </div>
                    <div className="switcher-footer">
                        <Button variant="danger" size="sm" type="button" className="radius-30 mt-2 mt-lg-0" onClick={() => this.loadData()} ><i className="bx bx-search" />Apply</Button>
                    </div>
                </div>
*/}

                <ToastContainer
                    position="top-right"
                    theme="colored"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />

            </Form>
        );
    }
}


export default MIB.withRouter(ShowFileUpload);


