/*-----------------------------------------------------------------
GENERATED BY MIB_PHP_GENERATOR VERSION 3.0
CREATED BY MISBA
EMAIL: m.m158a@gmail.com

DATE: 20-10-2022
TIME: 10:01:04

@copyright 2020 by MISBA
------------------------------------------------------------------*/




import React, { Component } from 'react';
import { Button, Form } from "react-bootstrap";
import { ServiceAchievementsData } from "./";
import { MIB, Paging } from "../../utils";
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ServiceAchievements } from "../achievements";

class ShowAchievementsData extends Component {

    newListData = {
        id_achievement: '',
        tanggal: '',
        total: '',
        unit: '',
        created: '',
        createdby: '',
        updated: '',
        updatedby: ''
    }

    defaultState = {
        data: [],
        pagination: null,
        post: {
            keyword: '',
            page: 0,
            sort_by: '1',
            field_sort: 'id',
            limit: 10,
            //id_achievement: '',
            //tanggal: '',
            //total: ''
        },
        dt_achievements: [],
        newData: false,
        listData: {
            id_achievement: '',
            unit: '',
            //achievement: '',
            data: []
        }
        //switchFilter: false,
        //ckAll: false,
        //ck: []
    }

    constructor(props) {
        super(props);
        this.state = this.defaultState

        this.delete = this.delete.bind(this);
        this.setValue = this.setValue.bind(this);
        this.setPage = this.setPage.bind(this);
		ServiceAchievements.setLock = this.props.router.context.setLock;
		ServiceAchievementsData.setLock = this.props.router.context.setLock;
    }

    newData() {
        //console.log(this.newListData);
        let rs = this.state.dt_achievements[0];
        //console.log(rs);
        let data = this.state.listData.data;
        data.push(this.newListData);
        this.setState((prevState) => ({
            ...prevState,
            newData: true,
            listData: {
                id_achievement: rs.id,
                unit: rs.satuan,
                //achievement: rs.aachievement,
                data
            }
        }));
    }

    addNewData() {
        let data = this.state.listData.data;
        //console.log(data);
        data.push(this.newListData);
        this.setState((prevState) => ({
            ...prevState,
            listData: {
                ...prevState.listData,
                data
            }
        }));
    }

    cancelAddNew() {
        this.setState((prevState) => ({
            ...prevState,
            newData: false,
            listData: {
                id_achievement: '',
                unit: '',
                //achievement: '',
                data: []
            }
        }));
    }

    async delete(id) {
        Swal.fire({
            title: 'Anda yakin?',
            text: "Hapus data ini!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya, hapus!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let data = await ServiceAchievementsData.delete(id);
                let title = "Deleted!";
                let message = "Data berhasil dihapus";
                let icon = "success";
                if (!data.status || data === undefined) {
                    icon = "error";
                    message = data === undefined ? "Error rest api" : data.message;
                    title = "Failed!"
                } else {
                    await this.loadData();
                }
                /*Swal.fire(
                    title,
                    message,
                    icon
                )*/
                if (icon === "error")
                    MIB.errorToken(message);
                else
                    toast.success(message);
            }
        });
    }

    /*async deleteMultiple() {
        let jml = 0;
        let del = [];
        this.state.ck.map((val, i) => {
            if (val) {
                del[jml] = this.state.data[i].id;
                jml++;
            }
        });

        if (jml > 0) {
            Swal.fire({
                title: 'Anda yakin?',
                text: "Hapus data yang dicentang!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ya, hapus!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const dels = { data: del };
                    console.log(dels)
                    let data = await ServiceAchievementsData.deleteMultiple(dels);
                    let title = "Deleted!";
                    let message = "Data berhasil dihapus";
                    let icon = "success";
                    if (data === undefined || !data.status) {
                        icon = "error";
                        message = data === undefined ? "Error rest api" : data.message;
                        title = "Failed!";
                    } else {
                        await this.loadData();
                    }
                    if(icon === "error")
                        toast.error(message);
                    else
                        toast.success(message);
                }
            })
        } else {
            toast.error("Ceklist minimal 1 data");
        }

    }*/

    async loadData() {
        const queryString = new URLSearchParams(this.state.post).toString();
        const resp = await ServiceAchievementsData.get(queryString);
        if (resp !== undefined) {
            if (resp.data.status) {
                const data = resp.data.datas.content;
                const pagination = {
                    num_pages: resp.data.datas.totalPages,
                    page: resp.data.datas.pageable.pageNumber,
                    limit: resp.data.datas.pageable.pageSize,
                    num_rows: resp.data.datas.totalElements
                };
                /*const data = resp.data.data;
                const pagination = resp.data.pagination;

                let ck = [];
                for (let i = 0; i < data.length; i++) {
                    ck[i] = false;
                }*/

                this.setState((prevState) => ({
                    ...prevState,
                    data: data,
                    pagination: pagination,
                    //ck: ck
                }));
            }else{
                MIB.errorToken(resp.data.message);
            }
        } else {
            console.log(resp.data.message);
        }
    }

    async getAchievements() {
        const qstring = "page=0&limit=10000&field_sort=id&sort_by=0&keyword=";
        const respAchievements = await ServiceAchievements.get(qstring);
        //console.log(respAchievements);
        if (respAchievements.data.status) {
            this.setState((prevState) => ({
                ...prevState,
                dt_achievements: respAchievements.data.datas.content
            }));
        }else{
            MIB.errorToken(respAchievements.data.message);
        }
    }

    async componentDidMount() {
        const { router } = this.props;
        if (router.location.state !== null && router.location.state.toast !== undefined) {
            if (router.location.state.toast.type === 'error')
                toast.error(router.location.state.toast.message, { onClose: () => router.location.state = null, toastId: 'toastId' });
            else
                toast.success(router.location.state.toast.message, { onClose: () => router.location.state = null, toastId: 'toastId' });
        }
        await this.loadData();
        await this.getAchievements();
    }

    setValue(e) {
        let arr = e.target.name.split('__');
        let name = arr[0];
        let no = parseInt(arr[1]);
        let val = e.target.value;
        let data = this.state.listData.data.map((rs, i) => {
            if (no === i) {
                return { ...rs, [name]: val }
            }
            return rs;
        });
        //data[no][name] = e.target.value;
        //console.log(data);
        this.setState((prevState) => ({
            ...prevState,
            listData: {
                ...prevState.listData,
                data
            }
        }));
    }

    deleteData(no) {
        let data = this.state.listData.data.map((rs, i) => {
            if (no !== i) {
                return rs;
            }
        });
        this.setState((prevState) => ({
            ...prevState,
            listData: {
                ...prevState.listData,
                data
            }
        }));
    }

    setAchievementsValue(e) {
        let id_achievement = e.target.value;
        let unit = document.querySelector("#id_achievement option[value='" + id_achievement + "']").getAttribute("unit");
        //console.log(unit);
        this.setState((prevState) => ({
            ...prevState,
            newData: true,
            listData: {
                ...prevState.listData,
                id_achievement,
                unit
            }
        }));
    }

    /*setValueCk(e) {
        let no = parseInt(e.target.name.replace('ck_', ''));
        no = no === undefined ? 0 : no;
        let ck = this.state.ck;
        ck[no] = ck[no] ? false : true;
        this.setState((prevState) => ({
            ...prevState,
            ck: ck
        }));
    }

    checkAll(e) {
        this.setState((prevState) => ({
            ...prevState,
            ckAll: !prevState.ckAll
        }));

        let ck = [];
        for (let i = 0; i < this.state.ck.length; i++) {
            ck[i] = !this.state.ckAll;
        }

        this.setState((prevState) => ({
            ...prevState,
            ck: ck
        }));
    }

    switchFilterToggle() {
        this.setState((prevState) => ({
            ...prevState,
            switchFilter: !prevState.switchFilter
        }));
    }*/

    async refresh() {
        this.cancelAddNew();
        await this.setState(this.defaultState);
        await this.loadData();
        await this.getAchievements();
    }

    async setPage(e, page) {
        e.preventDefault();
        await this.setState((prevState) => ({
            ...prevState,
            post: {
                ...prevState.post,
                page: page
            }
        }));
        await this.loadData();
    }

    async sortBy(field_sort) {
        let sort_by = '0';
        if (field_sort === this.state.post.field_sort) {
            if (this.state.post.sort_by === '1')
                sort_by = '0';
            else
                sort_by = '1';
        }
        await this.setState((prevState) => ({
            ...prevState,
            post: {
                ...prevState.post,
                sort_by: sort_by,
                field_sort: field_sort
            }
        }));
        await this.loadData();
    }

    async goOnEnter(e) {
        //console.log(e.key)
        if (e.key === 'Enter') {
            await this.loadData();
        }
    }

    submitHandler = async (e) => {
        e.preventDefault();
        //const { router, url } = this.props;
        let data;
        let err_msg = '';
        let error = true;
        let msg = '';
        let post = this.state.listData.data;
        let jwt = MIB.extractToken();
        let user_name = jwt.json.user_name;

        let err_list = false;
        let msg_list = '';
        data = post.map((js, i) => {
            if (js !== undefined) {
                let rs = {...js};
                //console.log(rs.tanggal);
                if (rs.tanggal === '' || rs.total === '') {
                    err_list = true;
                    msg_list = 'Tanggal dan total harus diisi';
                }
                rs.tanggal = rs.tanggal+ ' 00:00:00';
                rs.id_achievement = this.state.listData.id_achievement;
                rs.createdby = user_name;
                rs.updatedby = user_name;
                return rs;
            }

        });

        if (err_list) err_msg = msg_list;
        else error = false;

        if (error) {
            toast.error(err_msg);
            return;
        }

        data = await ServiceAchievementsData.newMultiple(data);
        let js = data.data;
        if (js.status) {
            toast.success("Input multiple data berhasil");
            this.refresh();
        } else {
            //Swal.fire('Error', rs.message, 'error');
            //toast.error(js.message);
            MIB.errorToken(js.message);
        }
    }

    formNewData() {
        const { dt_achievements, listData, newData } = this.state;
        const data = listData.data;
        //console.log(listData);
        return (
            <Form onSubmit={this.submitHandler}>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="p-4">
                                    <div className="card-title d-flex align-items-center">
                                        <div><i className="bx bxs-info-square me-1 font-22 text-info"></i></div>
                                        <h5 className="mb-0 text-info">New Data</h5>
                                    </div>
                                    <hr />

                                    <div className="row">
                                        <div className="col-sm-8">
                                            <div className="row mb-3">
                                                <label className="col-sm-3 col-form-label" htmlFor="id_achievement">Category:</label>
                                                <div className="col-sm-9"><select name="id_achievement" id="id_achievement" className="form-select" onChange={(event) => this.setAchievementsValue(event)} value={listData.id_achievement}>
                                                    {dt_achievements.map(rs => (
                                                        <option key={rs.id} value={rs.id} unit={rs.satuan}>{rs.name}</option>
                                                    ))}
                                                </select></div>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="ms-auto">
                                                <div className="d-flex">
                                                    <Button variant="primary" size="sm" type="button" className="radius-30 mt-2 mt-lg-0 ms-2" onClick={() => this.addNewData()}><i className="bx bx-plus" />Add New Data</Button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th>Tanggal</th>
                                                <th className="text-right">Total</th>
                                                <th>Satuan</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((rs, no) => {
                                                if (rs !== undefined)
                                                    return (
                                                        <tr key={no}>
                                                            <td><input type="date" name={`tanggal__${no}`} id={`tanggal__${no}`} value={rs.tanggal.substring(0, 16)} className="form-control" onChange={(event) => this.setValue(event)} /></td>
                                                            <td className="text-right"><input type="number" name={`total__${no}`} id={`total__${no}`} value={rs.total} className="form-control text-right" onChange={(event) => this.setValue(event)} /></td>
                                                            <td>{listData.unit}</td>
                                                            <td>
                                                                <a className="btn-danger btn-sm radius-30 mt-2 mt-lg-0" onClick={() => this.deleteData(no)}><i className="bx bx-trash" /></a>
                                                            </td>
                                                        </tr>
                                                    )
                                            })}
                                        </tbody>
                                    </table>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <button type="submit" className="btn btn-success radius-30 mt-2 mt-lg-0" name="btnSave" id="btnSave">
                    <i className="bx bx-save" />
                    Save
                </button>
                <button className="btn btn-info radius-30 mt-2 mt-lg-0 ms-1" type="button" onClick={() => this.cancelAddNew()}>
                    <i className="bx bx-chevron-left" />
                    Back
                </button>
            </Form>
        )
    }


    render() {
        const { router } = this.props;
        const url = router.context.url;
        //const { data, pagination, post, dt_achievements, switchFilter, ck } = this.state;
        const { data, pagination, post, dt_achievements, newData } = this.state;
        //console.log(dt_achievements);

        let htmlNew = newData ? this.formNewData() : "";

        return (
            <>
                <Form onSubmit={(e) => e.preventDefault()}>
                    <input type="hidden" name="page" id="page" value={post.page} onChange={(event) => this.setValue(event)} />
                    <input type="hidden" name="sort_by" id="sort_by" value={post.sort_by} onChange={(event) => this.setValue(event)} />
                    <input type="hidden" name="field_sort" id="field_sort" value={post.field_sort} onChange={(event) => this.setValue(event)} />

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card m-b-30">
                                <div className="card-body">

                                    <div className="d-lg-flex align-items-center mb-4 gap-3">
                                        <div className="position-relative">
                                            <input
                                                type="text"
                                                id="keyword"
                                                name="keyword"
                                                className="form-control ps-5 radius-30"
                                                placeholder="Type keyword, then enter"
                                                value={post.keyword}
                                                onChange={(event) => this.setValue(event)}
                                                onKeyUpCapture={e => this.goOnEnter(e)} />
                                            <span className="position-absolute top-50 product-show translate-middle-y"><i className="bx bx-search"></i></span>
                                        </div>
                                        <div className="ms-auto">
                                            <div className="d-flex">
                                                {/*<Button variant="success" size="sm" type="button" className="radius-30 mt-2 mt-lg-0" onClick={() => this.switchFilterToggle()} ><i className="bx bx-search" />Filter</Button>
									<Button variant="danger" size="sm" type="button" className="radius-30 mt-2 mt-lg-0 ms-2" onClick={() => this.deleteMultiple()}><i className="bx bx-trash" />Delete</Button>*/}
                                                <Button variant="info" size="sm" type="button" className="radius-30 mt-2 mt-lg-0 ms-2" onClick={() => this.refresh()}><i className="bx bx-refresh" />Refresh</Button>
                                                {/*<Button variant="primary" size="sm" type="button" className="radius-30 mt-2 mt-lg-0 ms-2" onClick={() => router.navigate(url + "/new")}><i className="bx bx-plus" />Add New Data</Button>*/}
                                                <Button variant="primary" size="sm" type="button" className="radius-30 mt-2 mt-lg-0 ms-2" onClick={() => this.newData()}><i className="bx bx-plus" />Add New Data</Button>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="table-responsive">
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    {/*<th><input className="form-check-input me-3" type="checkbox" aria-label="..." onClick={(e) => this.checkAll(e)} /></th>*/}
                                                    <th>Category <a onClick={() => this.sortBy('achievements.name')} className={`radius-30 mt-2 mt-lg-0 ms-2` + (post.field_sort === 'achievements.name' ? ' btn-danger' : ' btn-primary')}> <i className={`bx ` + (post.field_sort === 'achievements.name' && post.sort_by === '1' ? 'bx-down-arrow' : 'bx-up-arrow')} /></a></th>
                                                    <th>Tanggal <a onClick={() => this.sortBy('achievements_data.tanggal')} className={`radius-30 mt-2 mt-lg-0 ms-2` + (post.field_sort === 'achievements_data.tanggal' ? ' btn-danger' : ' btn-primary')}> <i className={`bx ` + (post.field_sort === 'achievements_data.tanggal' && post.sort_by === '1' ? 'bx-down-arrow' : 'bx-up-arrow')} /></a></th>
                                                    <th className="text-right">Total <a onClick={() => this.sortBy('achievements_data.total')} className={`radius-30 mt-2 mt-lg-0 ms-2` + (post.field_sort === 'achievements_data.total' ? ' btn-danger' : ' btn-primary')}> <i className={`bx ` + (post.field_sort === 'achievements_data.total' && post.sort_by === '1' ? 'bx-down-arrow' : 'bx-up-arrow')} /></a></th>
                                                    <th>Satuan <a onClick={() => this.sortBy('achievements.satuan')} className={`radius-30 mt-2 mt-lg-0 ms-2` + (post.field_sort === 'achievements.satuan' ? ' btn-danger' : ' btn-primary')}> <i className={`bx ` + (post.field_sort === 'achievements.satuan' && post.sort_by === '1' ? 'bx-down-arrow' : 'bx-up-arrow')} /></a></th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.map((rs, no) => {
                                                    return (
                                                        <tr key={rs.id}>
                                                            {/*<td><input className="form-check-input me-3" type="checkbox" name={`ck_${no}`} id={`ck_${no}`} checked={ck[no] ? true : false} onChange={(event) => this.setValueCk(event)} value={rs.id} /></td>*/}
                                                            <td>{rs.achievement}</td>
                                                            <td>{MIB.dateFormat(rs.tanggal)}</td>
                                                            <td className="text-right">{MIB.numberFormat(rs.total, 2)}</td>
                                                            <td>{rs.unit}</td>
                                                            <td>
                                                                <Link to={`${url}/edit/${rs.id}`} className="btn-primary btn-sm radius-30 mt-2 mt-lg-0"><i className="bx bx-edit" /></Link>
                                                                <a className="btn-danger btn-sm radius-30 mt-2 mt-lg-0" onClick={() => this.delete(rs.id)}><i className="bx bx-trash" /></a>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>

                                    </div>

                                    <Paging pagination={pagination} setPage={this.setPage} />







                                </div>
                            </div>
                        </div>
                    </div>

                    {/*
			<div className={'switcher-wrapper' + (switchFilter ? ' switcher-toggled' : '')}>
				<div className="switcher-header">
					<div className="d-flex align-items-center">
						<h5 className="mb-0 text-uppercase"><i className="bx bx-search"></i> Filters</h5>
						<button type="button" className="btn-close ms-auto close-switcher" aria-label="Close" onClick={() => this.switchFilterToggle()} />
					</div>
				</div>
				<div className="switcher-body">

	<div className="mb-3">
		<label className="form-label" htmlFor="id_achievement">Name:</label>
		<select name="id_achievement" id="id_achievement" className="form-select" onChange={(event) => this.setValue(event)} value={post.id_achievement}>
		<option value=""></option>
		{dt_achievements.map(rs=>(
			<option key={rs.id} value={rs.id}>{rs.name}</option>
		))}
		</select>
	</div>
	<div className="mb-3">
		<label className="form-label" htmlFor="tanggal">Tanggal:</label>
		<input type="datetime-local" name="tanggal" id="tanggal" value={post.tanggal.substring(0,16)}  className="form-control" onChange={(event) => this.setValue(event)} />
	</div>
	<div className="mb-3">
		<label className="form-label" htmlFor="total">Total:</label>
		<input type="number" name="total" id="total" value={post.total} className="form-control text-right" onChange={(event) => this.setValue(event)} />
	</div>
    		

				</div>
				<div className="switcher-footer">
					<Button variant="danger" size="sm" type="button" className="radius-30 mt-2 mt-lg-0" onClick={() => this.loadData()} ><i className="bx bx-search" />Apply</Button>
				</div>
			</div>		
*/}

                    <ToastContainer
                        position="top-right"
                        theme="colored"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />

                </Form>
                {htmlNew}
            </>

        );
    }
}


export default MIB.withRouter(ShowAchievementsData);


