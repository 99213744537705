/*-----------------------------------------------------------------
GENERATED BY MIB_PHP_GENERATOR VERSION 3.0
CREATED BY MISBA
EMAIL: m.m158a@gmail.com

DATE: 01-09-2022
TIME: 09:23:41

@copyright 2020 by MISBA
------------------------------------------------------------------*/




import React, { Component } from 'react';
import { Form, Tooltip, OverlayTrigger } from "react-bootstrap";
import { ServiceMenus } from "./";
import { MIB, VARS } from "../../utils";
//import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ReactSearchAutocomplete } from "react-search-autocomplete";

class InputMenus extends Component {
	constructor(props) {
		super(props);
		this.state = {
			menu_id: '',
			post: {
				type: 1,
				name: '',
				name_en: '',
				url: '',
				icon: '',
				parent_id: '',
				level: '',
				sequence: '',
				is_show: true,
				created: '',
				createdby: '',
				updated: '',
				updatedby: ''
			},
			dt_tparent: [],
			items: []

		}

		this.setValue = this.setValue.bind(this);
		ServiceMenus.setLock = this.props.router.context.setLock;
	}

	async setValue(e) {
		let value = e.target.value;
		if (e.target.name === 'is_show')
			value = !this.state.post.is_show;

		await this.setState((prevState) => ({
			...prevState,
			post: {
				...prevState.post,
				[e.target.name]: value
			}
		}));
		//await this.getTparent();

		if (e.target.name === 'type') {
			this.getTparent();
		}
	}

	submitHandler = async (e) => {
		e.preventDefault();
		const { router, url } = this.props;
		let data;
		let msg = '';
		let post = this.state.post;
		let jwt = MIB.extractToken();
		let user_name = jwt.json.user_name;
		post.createdby = user_name;
		post.updatedby = user_name;
		post.parent_id = parseInt(post.parent_id);
		post.created = undefined;
		post.updated = undefined;
		if (router.params.menu_id) {
			data = await ServiceMenus.update(post, router.params.menu_id);
			msg = 'Data berhasil diupdate';
		}
		else {
			data = await ServiceMenus.new(post);
			msg = 'Data berhasil ditambah';
		}
		let rs = data.data;
		if (rs.status) {
			//Swal.fire('Success', rs.message, 'success');
			//toast.success(rs.message);
			let state = { toast: { type: 'success', message: msg } }
			router.navigate(url, { state });
		} else {
			//Swal.fire('Error', rs.message, 'error');
			//toast.error(rs.message);
			MIB.errorToken(rs.message);
		}
	}

	async getTparent() {
		let type = this.state.post.type;
		const respTparent = await ServiceMenus.getParent(type);
		//console.log(respTparent);
		if (respTparent.data.status) {
			this.setState((prevState) => ({
				...prevState,
				dt_tparent: respTparent.data.datas
			}));
		}else{
			MIB.errorToken(respTparent.data.message);
		}
	}
	onHandleSelect(item){
		//console.log(item);
		let e = {
			target: {
				name: 'url',
				value: item.url
			}
		};
		this.setValue(e);
	}

	handleOnSearch(string, results){
		//console.log(string, results);
		let e = {
			target: {
				name: 'url',
				value: string
			}
		};
		this.setValue(e);
		this.getItems(string);
	}

	formatResult(item){
		return (
		  <>
			<span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
			<span style={{ display: 'block', textAlign: 'left' }}>{item.url}</span>
		  </>
		)
	}

	async getItems(keyword){
		const resp = await ServiceMenus.getUrl(keyword);
		if (resp !== undefined) {
			if (resp.data.status && resp.data.datas !== undefined) {
				//console.log(resp.data.datas);
				let data = resp.data.datas.content.map((rs,i)=>{
					//console.log(i)
					//console.log(rs)
					return {
						id: i,
						url: '/'+rs.type+'/'+rs.code,
						name: rs.title
					}
				});
				//console.log(data);
				this.setState((prevState) => ({
					...prevState,
					items: data
				}));
			}else{
				MIB.errorToken(resp.data.message);
			}
		}
	}

	async componentDidMount() {
		const { params, navigate } = this.props.router;
		await this.getItems('');
		await this.getTparent();
		if (params.menu_id) {
			const resp = await ServiceMenus.detail(params.menu_id);
			if (resp !== undefined) {
				if (resp.data.status && resp.data.datas !== undefined) {
					const data = resp.data.datas;//{DATE_TIME_CONVERT}
					data.icon = data.icon === null ? '' : data.icon;
					data.parent_id = data.parent_id === null ? '' : data.parent_id;
					data.level = data.level === undefined ? '' : data.level;
					data.is_show = data.is_show === undefined ? false : data.is_show;
					this.setState((prevState) => ({
						...prevState,
						menu_id: data.menu_id,
						post: data
					}));
				} else {
					MIB.errorToken(resp.data.message);
					navigate(this.props.url);
				}
			} else {
				console.log(resp.data.message);
				console.log(this.props.url);
			}
		} else {
			let parent_id = '';
			if (this.state.dt_tparent.length > 0) parent_id = this.state.dt_tparent[0].parent_id;
			this.setState((prevState) => ({
				...prevState,
				post: {
					...prevState.post,
					parent_id: parent_id
				}
			}));
		}
		//console.log("state:",this.state);
	}

	render() {
		const { router, url } = this.props;
		const { post, dt_tparent,items } = this.state;
		post.parent_id = post.parent_id === null ? '' : post.parent_id;
		//dt_tparent = dt_tparent===undefined?[]:dt_tparent;
		//console.log('items:',items);
		//let items = ['test a','test b','test c','test d']
		
		return (
			<Form onSubmit={this.submitHandler}>
				<div className="row">
					<div className="col-sm-12">
						<div className="card">
							<div className="card-body">
								<div className="p-4">
									<div className="card-title d-flex align-items-center">
										<div><i className="bx bxs-info-square me-1 font-22 text-info"></i></div>
										<h5 className="mb-0 text-info">{router.context.title}</h5>
									</div>
									<hr />


									<div className="row">
										<div className="col-sm-6">
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="type">Type:</label>
												<div className="col-sm-9"><select name="type" id="type" className="form-select" onChange={(event) => this.setValue(event)} value={post.type}>
													{VARS.menus_type.map(rs => (
														<option key={rs.key} value={rs.key}>{rs.label}</option>
													))}
												</select></div>
											</div>
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="name">Name (ID):</label>
												<div className="col-sm-9"><input type="text" name="name" id="name" value={post.name} maxLength="255" className="form-control" onChange={(event) => this.setValue(event)} /></div>
											</div>
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="icon">Icon:</label>
												<div className="col-sm-9"><input type="text" name="icon" id="icon" value={post.icon} maxLength="255" className="form-control" onChange={(event) => this.setValue(event)} /></div>
											</div>
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="sequence">Sort:</label>
												<div className="col-sm-9"><input type="text" name="sequence" id="sequence" value={post.sequence} maxLength="32" className="form-control text-right" onChange={(event) => this.setValue(event)} /></div>
											</div>
											{/*<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="level">Level:</label>
												<div className="col-sm-9"><input type="text" name="level" id="level" value={post.level} maxLength="32" className="form-control text-right" onChange={(event) => this.setValue(event)} /></div>
													</div>*/}

										</div>
										<div className="col-sm-6">
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="url">Url:</label>
												<div className="col-sm-9">
													<ReactSearchAutocomplete 
														items={items}
														onSearch={(string, results)=>this.handleOnSearch(string, results)}
														onSelect={(item)=>this.onHandleSelect(item)}
														formatResult={(item)=>this.formatResult(item)}
														inputSearchString={post.url}
													/>
													{/*<input type="text" name="url" id="url" value={post.url} maxLength="255" className="form-control" onChange={(event) => this.setValue(event)} />*/}
												</div>
											</div>
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="name_en">Name (EN):</label>
												<div className="col-sm-9"><input type="text" name="name_en" id="name_en" value={post.name_en} maxLength="255" className="form-control" onChange={(event) => this.setValue(event)} /></div>
											</div>
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="parent_id">Parent Name:</label>
												<div className="col-sm-9"><select name="parent_id" id="parent_id" className="form-select" onChange={(event) => this.setValue(event)} value={post.parent_id}>
													<option value="">No Parent</option>
													{dt_tparent.map(rs => (
														<option key={rs.parent_id} value={rs.parent_id} dangerouslySetInnerHTML={{ __html: '&nbsp;'.repeat((rs.level - 1) * 3) + rs.parent_name }}></option>
													))}
												</select></div>
											</div>
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="is_show">Status:</label>
												<div className="col-sm-9"><input type="checkbox" name="is_show" id="is_show" onChange={(event) => this.setValue(event)} checked={post.is_show} /> Show</div>
											</div>


										</div>
									</div>

								</div>

							</div>
						</div>
					</div>
				</div>


				<button type="submit" className="btn btn-success radius-30 mt-2 mt-lg-0" name="btnSave" id="btnSave">
					<i className="bx bx-save" />
					Save
				</button>
				<button className="btn btn-info radius-30 mt-2 mt-lg-0 ms-1" type="button" onClick={() => router.navigate(url)}>
					<i className="bx bx-chevron-left" />
					Back
				</button>
				{this.state.menu_id !== '' && post.created !== undefined ? <OverlayTrigger
					key="top"
					placement="top"
					overlay={
						<Tooltip id={`tooltip-top`}>
							<table>
								<tbody>
									<tr>
										<td nowrap="nowrap">Created</td>
										<td>:</td>
										<td nowrap="nowrap">{MIB.dateTimeFormat(post.created)}</td>
									</tr>
									<tr>
										<td nowrap="nowrap">Created by</td>
										<td>:</td>
										<td nowrap="nowrap">{post.createdby}</td>
									</tr>
									<tr>
										<td nowrap="nowrap">Updated</td>
										<td>:</td>
										<td nowrap="nowrap">{MIB.dateTimeFormat(post.updated)}</td>
									</tr>
									<tr>
										<td nowrap="nowrap">Updated by</td>
										<td>:</td>
										<td nowrap="nowrap">{post.updatedby}</td>
									</tr>
								</tbody>

							</table>
						</Tooltip>
					}
				>
					<button type="button" className="btn btn-warning radius-30 mt-2 mt-lg-0 ms-1"><i className="bx bx-history" /> History</button>
				</OverlayTrigger> : ''}

				<ToastContainer
					position="top-right"
					theme="colored"
					autoClose={2000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
			</Form>
		);
	}
}

export default MIB.withRouter(InputMenus);