/*-----------------------------------------------------------------
GENERATED BY MIB_PHP_GENERATOR VERSION 3.0
CREATED BY MISBA
EMAIL: m.m158a@gmail.com

DATE: 24-10-2022
TIME: 09:37:19

@copyright 2020 by MISBA
------------------------------------------------------------------*/




import React, { Component } from 'react';
import { Form, Tooltip, OverlayTrigger } from "react-bootstrap";
import { ServiceOffices } from "./";
import { MIB } from "../../utils";
//import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class InputOffices extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: '',
			post: {
				name: '',
				address: '',
				telp: '',
				email: '',
				created: '',
				createdby: '',
				updated: '',
				updatedby: ''
			}

		}

		this.setValue = this.setValue.bind(this);
		ServiceOffices.setLock = this.props.router.context.setLock;
	}

	setValue(e) {
		this.setState((prevState) => ({
			...prevState,
			post: {
				...prevState.post,
				[e.target.name]: e.target.value
			}
		}))
	}

	submitHandler = async (e) => {
		e.preventDefault();
		const { router, url } = this.props;
		let data;
		let msg = '';
		let post = this.state.post;
		let jwt = MIB.extractToken();
		let user_name = jwt.json.user_name;
		post.createdby = user_name;
		post.updatedby = user_name;
		post.created = undefined;
		post.updated = undefined;

		let error = true;
		let err_msg = '';
		if (post.name === '') err_msg = 'Name harus diisi';
		else if (post.address === '') err_msg = 'Address harus diisi';
		else error = false;

		if (error) {
			toast.error(err_msg);
			return;
		}
		if (router.params.id) {
			data = await ServiceOffices.update(post, router.params.id);
			msg = 'Data berhasil diupdate';
		}
		else {
			data = await ServiceOffices.new(post);
			msg = 'Data berhasil ditambah';
		}
		let rs = data.data;
		if (rs.status) {
			//Swal.fire('Success', rs.message, 'success');
			//toast.success(rs.message);
			let state = { toast: { type: 'success', message: msg } }
			router.navigate(url, { state });
		} else {
			//Swal.fire('Error', rs.message, 'error');
			//toast.error(rs.message);
			MIB.errorToken(rs.message);
		}
	}

	async componentDidMount() {
		const { params, navigate } = this.props.router;
		if (params.id) {
			const resp = await ServiceOffices.detail(params.id);
			if (resp !== undefined) {
				if (resp.data.status && resp.data.datas !== undefined) {
					const data = resp.data.datas;//{DATE_TIME_CONVERT}
					this.setState((prevState) => ({
						...prevState,
						id: data.id,
						post: data
					}));
				} else {
					MIB.errorToken(resp.data.message);
					navigate(this.props.url);
				}
			} else {
				console.log(resp.data.message);
				console.log(this.props.url);
			}
		}
	}

	render() {
		const { router, url } = this.props;
		const { post } = this.state;
		return (
			<Form onSubmit={this.submitHandler}>
				<div className="row">
					<div className="col-sm-12">
						<div className="card">
							<div className="card-body">
								<div className="p-4">
									<div className="card-title d-flex align-items-center">
										<div><i className="bx bxs-info-square me-1 font-22 text-info"></i></div>
										<h5 className="mb-0 text-info">{router.context.title}</h5>
									</div>
									<hr />
									<div className="row">
										<div className="col-sm-6">
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="name">Name:</label>
												<div className="col-sm-9"><input type="text" name="name" id="name" value={post.name} maxLength="255" className="form-control" onChange={(event) => this.setValue(event)} /></div>
											</div>
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="address">Address:</label>
												<div className="col-sm-9"><textarea type="text" name="address" id="address" value={post.address} maxLength="255" rows={7} className="form-control" onChange={(event) => this.setValue(event)}></textarea></div>
											</div>
										</div>
										<div className="col-sm-6">
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="telp">No. Telepon:</label>
												<div className="col-sm-9"><input type="text" name="telp" id="telp" value={post.telp} maxLength="255" className="form-control" onChange={(event) => this.setValue(event)} /></div>
											</div>
											<div className="row mb-3">
												<label className="col-sm-3 col-form-label" htmlFor="email">Email:</label>
												<div className="col-sm-9"><input type="email" name="email" id="email" value={post.email} maxLength="255" rows={7} className="form-control" onChange={(event) => this.setValue(event)} /></div>
											</div>
										</div>
									</div>



								</div>

							</div>
						</div>
					</div>
				</div>


				<button type="submit" className="btn btn-success radius-30 mt-2 mt-lg-0" name="btnSave" id="btnSave">
					<i className="bx bx-save" />
					Save
				</button>
				<button className="btn btn-info radius-30 mt-2 mt-lg-0 ms-1" type="button" onClick={() => router.navigate(url)}>
					<i className="bx bx-chevron-left" />
					Back
				</button>
				{this.state.id !== '' && post.created !== undefined ? <OverlayTrigger
					key="top"
					placement="top"
					overlay={
						<Tooltip id={`tooltip-top`}>
							<table>
								<tbody>
									<tr>
										<td nowrap="nowrap">Created</td>
										<td>:</td>
										<td nowrap="nowrap">{MIB.dateTimeFormat(post.created)}</td>
									</tr>
									<tr>
										<td nowrap="nowrap">Created by</td>
										<td>:</td>
										<td nowrap="nowrap">{post.createdby}</td>
									</tr>
									<tr>
										<td nowrap="nowrap">Updated</td>
										<td>:</td>
										<td nowrap="nowrap">{MIB.dateTimeFormat(post.updated)}</td>
									</tr>
									<tr>
										<td nowrap="nowrap">Updated by</td>
										<td>:</td>
										<td nowrap="nowrap">{post.updatedby}</td>
									</tr>
								</tbody>

							</table>
						</Tooltip>
					}
				>
					<button type="button" className="btn btn-warning radius-30 mt-2 mt-lg-0 ms-1"><i className="bx bx-history" /> History</button>
				</OverlayTrigger> : ''}

				<ToastContainer
					position="top-right"
					theme="colored"
					autoClose={2000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
			</Form>
		);
	}
}

export default MIB.withRouter(InputOffices);