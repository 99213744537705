import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

class Quill extends Component {
    constructor(props) {
        super(props);

        this.state = {
            text: '<p>OK</p>'
        }

        this.quillRef = null;
        this.reactQuillRef = null;

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        //this.attachQuillRefs();
    }
    componentDidUpdate() {
        //this.attachQuillRefs();
    }

    attachQuillRefs = () => {
        if (typeof this.reactQuillRef.getEditor !== 'function') return;
        this.quillRef = this.reactQuillRef.getEditor();
    };

    insertText = () => {
        var range = this.quillRef.getSelection();
        let position = range ? range.index : 0;
        this.quillRef.insertText(position, 'Hello, World! ');
    }
    handleChange (html) {
        this.setState({ text: html });
    }

    render() {
        console.log(this.state);
        return (
            <div>
                <ReactQuill
                    ref={(el) => { this.reactQuillRef = el }}
                    theme="snow"
                    value={this.state.text}
                    onChange={this.handleChange}
                />
                <button onClick={this.insertText}>Insert text</button>
            </div>
        );
    }
}

export default Quill;